import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { MarathonItemModel } from '~/model/Cards/MarathonItemModel'
import { IProgramOrCourses } from '~/model/Misc/RegionModel'
import { Translation } from '~/model/Transliation'

@Module({ namespaced: true, stateFactory: true, name: 'marathon' })
export class MarathonModule extends VuexModule {
  private marathonsList: Array<MarathonItemModel> = []
  private programList: IProgramOrCourses[] = []
  private courseList: IProgramOrCourses[] = []
  private specialitiesList: IProgramOrCourses[] = []
  private marathonItem: MarathonItemModel = new MarathonItemModel()
  private translation: Translation = new Translation()

  @Mutation
  private mutationSetMarathonsList(marathons: MarathonItemModel[]): void {
    this.marathonsList = marathons
  }

  @Action
  public setMarathonsList(marathonsList: MarathonItemModel[]): void {
    this.context.commit('mutationSetMarathonsList', marathonsList)
  }

  @Mutation
  private mutationSetTranslation(traslation: Translation): void {
    this.translation = traslation
  }

  @Action
  public setTranslation(translation: Translation): void {
    this.context.commit('mutationSetTranslation', translation)
  }

  @Mutation
  private mutationSetMarathonItem(marathonItem: MarathonItemModel): void {
    this.marathonItem = marathonItem
  }

  @Action
  public setMarathonItem(marathonItem: MarathonItemModel): void {
    this.context.commit('mutationSetMarathonItem', marathonItem)
  }

  @Mutation
  private mutationSeProgramList(programList: IProgramOrCourses[]): void {
    this.programList = programList
  }

  @Action
  public setProgramList(programList: IProgramOrCourses[]): void {
    this.context.commit('mutationSeProgramList', programList)
  }

  @Mutation
  private mutationSetCourseList(courseList: IProgramOrCourses[]): void {
    this.courseList = courseList
  }

  @Action
  public setCourseList(courseList: IProgramOrCourses[]): void {
    this.context.commit('mutationSetCourseList', courseList)
  }

  @Mutation
  private mutationSetSpecialitiesList(specialitiesList: IProgramOrCourses[]): void {
    this.specialitiesList = specialitiesList
  }

  @Action
  public setSpecialitiesList(specialitiesList: IProgramOrCourses[]): void {
    this.context.commit('mutationSetSpecialitiesList', specialitiesList)
  }

  get MarathonItem(): MarathonItemModel {
    return this.marathonItem
  }

  get MarathonsList(): MarathonItemModel[] {
    return this.marathonsList
  }

  get SpecialitiesList(): IProgramOrCourses[] {
    return this.specialitiesList
  }

  get Translation(): Translation {
    return this.translation
  }

  get ProgramList(): IProgramOrCourses[] {
    return this.programList
  }

  get CourseList(): IProgramOrCourses[] {
    return this.courseList
  }
}
