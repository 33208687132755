/* eslint-disable camelcase */
export interface Pivot {
  translation_id: number
  translation_tag_id: number
}

export interface ITranslationTag {
  id: number
  name: string
  link: string
  pivot: Pivot
}

export interface ITranslationSchedule {
  id: number
  translation_id: number
  date: string
  event: string
  role: string
  speaker: string
  speaker_post: string
  speaker_photo: string
  created_at: string
  updated_at: string
  deleted_at?: any
}

export class Translation {
  id: number = 0
  name: string = ''
  slug: string = ''
  description: string = ''
  views_count: number = 0
  start_date: string = ''
  end_date: string = ''
  video_link: string = ''
  is_published: number = 0
  is_finished: number = 0
  unique_person: number = 0
  video_source: string = ''
  video_title: string = ''
  video_description: string = ''
  video_image_before_start: string = ''
  telegram_title: string = ''
  telegram_description: string = ''
  schedule_date?: any
  schedule_event?: any
  schedule_role?: any
  schedule_speaker?: any
  schedule_speaker_post?: any
  schedule_speaker_photo?: any
  translationtags?: ITranslationTag[]
  translation_schedules?: ITranslationSchedule[]
}
