import { RequestService } from '~/services/RequestService'

export class ProfessionsService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    professions: '/api/v1/yavagro/professions'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async getProfessionsList<T>(ids?: string[]): Promise<T> {
    const url = ids ? this.urls.professions + '?ids=' + ids.join(',') : this.urls.professions
    const res: any = await this.requestService.request<T>({
      url,
      auth: this.auth
    })

    return res.data.data
  }
  // public getProfessionsList (): Promise<ProfessionsCardModel[]> {
  //   // return await this.requestService.request({
  //   //   url: `${this.urls.professions}`,
  //   // });
  //   return new Promise((resolve) => {
  //     resolve([
  //       {
  //         id: 'prof1',
  //         name: 'Архитектор Big Data',
  //         image: '/images/professionsExample/prof1.jpg'
  //       },
  //       {
  //         id: 'prof2',
  //         name: 'Сити-фермер',
  //         image: '/images/professionsExample/prof2.jpg'
  //       },
  //       {
  //         id: 'prof3',
  //         name: 'Специалист Data Science',
  //         image: '/images/professionsExample/prof3.jpg'
  //       },
  //       {
  //         id: 'prof4',
  //         name: 'Биотехнолог',
  //         image: '/images/professionsExample/prof4.jpg'
  //       },
  //       {
  //         id: 'prof5',
  //         name: 'Цифровой маркетолог',
  //         image: '/images/professionsExample/prof5.jpg'
  //       },
  //       {
  //         id: 'prof6',
  //         name: 'Специалист по уменьшению экологического следа',
  //         image: '/images/professionsExample/prof6.jpg'
  //       },
  //       {
  //         id: 'prof7',
  //         name: 'Оператор Дронов',
  //         image: '/images/professionsExample/prof7.jpg'
  //       }
  //     ]);
  //   });
  // }
}
