import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { WorkCardModel } from '~/model/'

@Module({ namespaced: true, stateFactory: true, name: 'workCards' })
export class WorkCardsModule extends VuexModule {
  private internalWorkCardsList: Array<WorkCardModel> = []
  private internalWorkCard: WorkCardModel = new WorkCardModel()

  @Mutation
  private mutationSetWorkCardsList(workCards: WorkCardModel[]): void {
    this.internalWorkCardsList = workCards
  }

  @Action
  public setWorkCardsList(workCardsList: WorkCardModel[]): void {
    this.context.commit('mutationSetWorkCardsList', workCardsList)
  }

  get workCardsList(): WorkCardModel[] {
    return this.internalWorkCardsList
  }

  @Mutation
  private mutationSetWorkCard(workCard: WorkCardModel): void {
    this.internalWorkCard = workCard
  }

  @Action
  public setWorkCard(workCard: WorkCardModel): void {
    this.context.commit('mutationSetWorkCard', workCard)
  }

  get workCard(): WorkCardModel {
    return this.internalWorkCard
  }
}
