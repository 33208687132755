import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { ProfessionalEducationItemModel } from '~/model/Cards/ProfessionalEducationItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'professionalEducation' })
export class ProfessionalEducationModule extends VuexModule {
  private professionalEducationList: Array<ProfessionalEducationItemModel> = []
  private professionalEducationItem: ProfessionalEducationItemModel = new ProfessionalEducationItemModel()
  private professionalEducationCount: number = 0

  @Mutation
  private mutationSetProfessionalEducationList(professionalEducationList: ProfessionalEducationItemModel[]): void {
    this.professionalEducationList = professionalEducationList
  }

  @Mutation
  private mutationSetCountProfessionalEducation(professionalEducationCount: number): void {
    this.professionalEducationCount = professionalEducationCount
  }

  @Mutation
  private mutationSetProfessionalEducationItem(professionalEducationItem: ProfessionalEducationItemModel): void {
    this.professionalEducationItem = professionalEducationItem
  }

  @Action
  public setProfessionalEducationList(professionalEducationList: ProfessionalEducationItemModel[]): void {
    this.context.commit('mutationSetProfessionalEducationList', professionalEducationList)
  }

  @Action
  public setProfessionalEducationItem(professionalEducationItem: ProfessionalEducationItemModel): void {
    this.context.commit('mutationSetProfessionalEducationItem', professionalEducationItem)
  }

  @Action
  public setCountProfessionalEducation(professionalEducationCount: number): void {
    this.context.commit('mutationSetCountProfessionalEducation', professionalEducationCount)
  }

  get ProfessionalEducationList(): ProfessionalEducationItemModel[] {
    return this.professionalEducationList
  }

  get ProfessionalEducationCount(): number {
    return this.professionalEducationCount
  }

  get ProfessionalEducationItem(): ProfessionalEducationItemModel {
    return this.professionalEducationItem
  }
}
