import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { IRegionLocation, RegionLocationItem } from '~/model/RegionLocation'

@Module({ namespaced: true, stateFactory: true, name: 'regionLocation' })
export class RegionLocationModule extends VuexModule {
  private regionList: Array<IRegionLocation> = []
  private regionItem: RegionLocationItem = new RegionLocationItem()
  private isShowModalRegionChange: boolean = false
  private isShowModalRegionConfirm: boolean = false
  private isShowModalRegionConfirmMobile: boolean = false

  @Mutation
  private mutationSetRegionLocationList(regionLocationList: IRegionLocation[]): void {
    this.regionList = regionLocationList
  }

  @Action
  public setRegionLocationList(regionLocationList: IRegionLocation[]): void {
    this.context.commit('mutationSetRegionLocationList', regionLocationList)
  }

  @Mutation
  private mutationSetRegionLocationItem(regionLocationItem: IRegionLocation): void {
    this.regionItem = regionLocationItem
  }

  @Action
  public setRegionLocationItem(item: IRegionLocation): void {
    this.context.commit('mutationSetRegionLocationItem', item)
  }

  @Mutation
  private mutationSetIsShowModalRegionChange(isShowModal: boolean): void {
    this.isShowModalRegionChange = isShowModal
  }

  @Action
  public setIsShowModalRegionChange(isShowModal: boolean): void {
    this.context.commit('mutationSetIsShowModalRegionChange', isShowModal)
  }

  @Mutation
  private mutationSetIsShowModalRegionConfirm(isShowModaConfirm: boolean): void {
    this.isShowModalRegionConfirm = isShowModaConfirm
  }

  @Action
  public setIsShowModalRegionConfirm(isShowModaConfirm: boolean): void {
    this.context.commit('mutationSetIsShowModalRegionConfirm', isShowModaConfirm)
  }

  @Mutation
  private mutationSetIsShowModalRegionConfirmMobile(isShowModalConfirm: boolean): void {
    this.isShowModalRegionConfirmMobile = isShowModalConfirm
  }

  @Action
  public setIsShowModalRegionConfirmMobile(isShowModalConfirm: boolean): void {
    this.context.commit('mutationSetIsShowModalRegionConfirmMobile', isShowModalConfirm)
  }

  get RegionLocationItem(): IRegionLocation {
    return this.regionItem
  }

  get RegionLocationList(): IRegionLocation[] {
    return this.regionList
  }

  get ShowModalRegionChange(): boolean {
    return this.isShowModalRegionChange
  }

  get ShowModalRegionConfirm(): boolean {
    return this.isShowModalRegionConfirm
  }

  get ShowModalRegionConfirmMobile(): boolean {
    return this.isShowModalRegionConfirmMobile
  }
}
