/* eslint-disable camelcase */
interface ProfessionalEducation {
  id: string
  name?: string
  logo?: string
  short_name?: string
  link?: string
}

export class ProfessionalEducationDirectionItemModel {
  id?: string
  is_published?: number
  name?: string
  period?: string
  passing_score?: number
  created_at?: string
  updated_at?: string
  image?: string
  description?: string
  price_non_rf?: string
  price_rf?: string
  study_form?: string
  stream_id?: number
  univer_id?: string
  budget_places_number?: string
  commerce_places_number?: string
  university?: ProfessionalEducation[] | ProfessionalEducation
}
