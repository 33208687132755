
import { defineComponent, ref, useContext, onMounted, watch } from '@nuxtjs/composition-api'
import { getMobileSidebarMenu } from '~/shared/const'
import { isUnknownObject } from '~/shared/utils/guards'
import type { MenuItem } from '~/interfaces/Header'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'
import RoundButton from '~/components/UI/RoundButton.vue'

export default defineComponent({
  name: 'MobileSidebarMenu',
  components: { AppLazyImage, RoundButton },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  setup(_, { emit }) {
    const context = useContext()

    const menuItems = ref<Array<MenuItem>>([])

    const checkHttps = (str: string) => {
      return str.includes('https')
    }

    const getSidebarMenu = () => {
      const region = context.$services.geolocation.getCookieRegion()
      menuItems.value = getMobileSidebarMenu(
        isUnknownObject(region) && typeof region.slug === 'string' ? region.slug : ''
      )
    }

    onMounted(() => getSidebarMenu())

    watch(() => context.$tstore.regionLocation.RegionLocationItem, getSidebarMenu, { immediate: true, deep: true })

    const closeSidebar = () => {
      emit('input', false)
    }

    return {
      menuItems,
      closeSidebar,
      checkHttps
    }
  }
})
