import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { UniversityItemModel } from '~/model/Cards/UniversityItemModel'

@Module({
  namespaced: true,
  stateFactory: true,
  name: 'university'
})
export class UniversityModule extends VuexModule {
  private universityList: Array<UniversityItemModel> = []
  private universityItem: UniversityItemModel = new UniversityItemModel()
  private universityCount: number = 0

  @Mutation
  private mutationSetUniversityList(universityList: UniversityItemModel[]): void {
    this.universityList = universityList
  }

  @Mutation
  private mutationSetCountUniversity(universityCount: number): void {
    this.universityCount = universityCount
  }

  @Mutation
  private mutationSetUniversityItem(universityItem: UniversityItemModel): void {
    this.universityItem = universityItem
  }

  @Action
  public setUniversityList(universityList: UniversityItemModel[]): void {
    this.context.commit('mutationSetUniversityList', universityList)
  }

  @Action
  public setUniversityItem(universityItem: UniversityItemModel): void {
    this.context.commit('mutationSetUniversityItem', universityItem)
  }

  @Action
  public setCountUniversity(countUniversity: number): void {
    this.context.commit('mutationSetCountUniversity', countUniversity)
  }

  get UniversityCount(): number {
    return this.universityCount
  }

  get UniversityList(): UniversityItemModel[] {
    return this.universityList
  }

  get UniversityItem(): UniversityItemModel {
    return this.universityItem
  }
}
