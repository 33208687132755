/* eslint-disable camelcase */

export class ListInDigitalProfile {
  id?: number = 0
  name?: string = ''
  sort_index?: number = 0
}

export class Region {
  id?: number = 0
  name?: string = ''
  district?: string = ''
  slug?: string = ''
}

export class Specialization {
  id?: number = 0
  title?: string = ''
  name?: string = ''
  code?: string = ''
  sciences_branch?: string = ''
  specialization_group?: string = ''
}

export class Citizenship {
  id?: number = 0
  title?: string = ''
  name?: string = ''
  code?: string = ''
  sciences_branch?: string = ''
  specialization_group?: string = ''
}

export class University {
  id?: number = 0
  name?: string = ''
  short_name?: string = ''
  region_id?: number | null = null
  city?: string = ''
  is_hostel?: boolean = false
  is_military_department?: boolean = false
  is_citizenship_rf?: boolean = false
  is_scholarship_program?: boolean = false
  budget_avg_score?: string = ''
  budget_places_number?: string = ''
  logo?: string = ''
  type?: string = ''
  slug?: string = ''
  is_published?: boolean = false
}

export class DigitalProfile {
  id?: number = 0
  user_id?: number | string = 0
  first_name?: string = ''
  last_name?: string = ''
  middle_name?: string = ''
  birth_date?: string = ''
  phone?: string = ''
  photo?: string = ''
  email?: string = ''
  sex?: string = ''
  snils?: string = ''
  inn?: string = ''
  other_university?: string = ''
  citizenship?: Citizenship
  citizenship_id?: number | null = null
  region_id?: number | null = null
  is_spo?: boolean
  region?: Region
  city: string = ''
  university_id?: number | null = null
  university?: University
  course_id?: number | null = null
  course?: ListInDigitalProfile
  studying_start_year: number | string = 0
  studying_end_year: number | string = 0
  faculty?: string = ''
  department?: string = ''
  specialization_id?: number | null = null
  specialization?: Specialization
  educationlevel_id?: number | null = null
  educationlevel?: ListInDigitalProfile
  studyingform_id?: number | null = null
  studyingform?: ListInDigitalProfile
  financingtype_id?: number | null = null
  financingtype?: ListInDigitalProfile
  is_studying_in_target_direction?: boolean
  is_ambassadors_member?: boolean
  projects: Array<{ name: string; description: string }> = []
  is_agrolider: boolean = false
  presentation: number | null = null
  video_link: string = ''
  portfolio_link?: string = ''
  project_name: string = ''
  nominations: Array<{ name: string }> = []
  is_latest_nda_signed?: boolean = true
}

export class StudentFormErrorsText {
  faculty: string[] = []
  department: string[] = []
}

export class INdaFile {
  document_name: string = ''
  document_code: string = ''
  document_version: string = ''
  url: string = ''
}
