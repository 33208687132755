import { RequestService } from '~/services/RequestService'

export class DigitalProfilesFiltersService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    digitalProfilesFilterFields: '/api/v1/yavagro/digitalprofiles/filters'
  }

  public async getDigitalProfilesFilterFields<T>(query: object): Promise<T> {
    const q = Object.keys(query)
      .map(key => `${key}=${encodeURI(query[key])}`)
      .join('&')

    const res: any = await this.requestService.request<T>({
      url: `${this.urls.digitalProfilesFilterFields}?${q}`
    })

    return res.data.data
  }
}
