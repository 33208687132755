/* eslint-disable no-var */
// helper function
function debounce(func, wait) {
  // we need to save these in the closure
  let timeout, args, context, timestamp

  // this is where the magic happens
  var later = function () {
    // how long ago was the last call
    const last = Date.now() - timestamp

    // if the latest call was less that the wait period ago
    // then we reset the timeout to wait for the difference
    if (last < wait) {
      timeout = setTimeout(later, wait - last)

      // or if not we can null out the timer and run the latest
    } else {
      timeout = null
      func.apply(context, args)
    }
  }

  return function () {
    // save details of latest call
    context = this
    args = arguments
    timestamp = Date.now()

    // we only need to set the timer now if one isn't already running
    if (!timeout) {
      timeout = setTimeout(later, wait)
    }
  }
}

const onScroll = debounce(function () {
  // do all your reads...
  // lastScrollY = window.scrollY;
  // do all your writes inside rAF
  // requestAnimationFrame(updatePage);
})

window.addEventListener('scroll', onScroll)
