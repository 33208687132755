
import { defineComponent, computed, ref, useRouter, useContext, watch } from '@nuxtjs/composition-api'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'
import type { SearchItem } from '~/interfaces/GlobalSearch'

export default defineComponent({
  name: 'GlobalSearch',
  components: { AppLazyImage },
  setup() {
    const $router = useRouter()
    const context = useContext()

    const isActive = ref(false)
    const items = computed(() => context.$tstore.globalSearch.items)
    const hasItems = computed(() => Object.keys(items).length > 0)

    const translate = (value: string): string => {
      let res = 'Часто ищут'
      switch (value) {
        case 'news':
          res = 'Новости'
          break
        case 'courses':
          res = 'Курсы'
          break
        case 'books':
          res = 'Книги'
          break
        case 'universities':
          res = 'Университеты'
          break
        case 'employers':
          res = 'Компании'
          break
        case 'colleges':
          res = 'Колледжи'
          break
        case 'professional-education':
          res = 'Дополнительное образование'
          break
        case 'vacancies':
          res = 'Вакансии'
          break
        default:
          break
      }
      return res
    }

    const closeSearch = () => {
      isActive.value = false
      context.$tstore.globalSearch.setQuery('')
    }

    const firstElements = (elements: SearchItem[], count: number = 3) => {
      return elements.slice(0, count)
    }

    const getLink = (key: string, item: SearchItem) => {
      let link = `/${key}/${item.slug}`

      if (key === 'employers') {
        link = `/employer/${item.slug}`
      } else if (key === 'vacancies') {
        link = `/${key}/${item.id}`
      }

      return link
    }

    const goToLink = (link: string) => {
      $router.push(link)
      closeSearch()
    }

    const showMatch = value => {
      const query = context.$tstore.globalSearch.searchQuery
      return value.split(query).join(`<strong>${query}</strong>`)
    }

    const searchItems = () => {
      const query = context.$tstore.globalSearch.searchQuery

      if (query.length > 2) {
        const vacanciesPromise = context.store
          .dispatch(`rshb-vue-kit/recruitment/vacancy/getVacancies`, {
            apiHost: context.$config.hosts.authHost,
            search: query,
            size: 5
          })
          .then(response => {
            return response.data.result.vacancies
          })

        const globalSearchPromise = context.$services.search.getSearch(query)

        Promise.allSettled([vacanciesPromise, globalSearchPromise]).then(([resVacancies, resGlobal]) => {
          const result: typeof items.value = {}

          if (
            resVacancies.status === 'fulfilled' &&
            Array.isArray(resVacancies.value) &&
            resVacancies.value.length > 0
          ) {
            result.vacancies = resVacancies.value.slice(0, 3).map(i => {
              i.link = getLink('vacancies', i)
              return i
            })
          }

          if (resGlobal.status === 'fulfilled') {
            Object.keys(resGlobal.value).forEach(key => {
              if (Array.isArray(resGlobal.value[key])) {
                result[key] = resGlobal.value[key].map(i => {
                  i.link = getLink(key, i)
                  return i
                })
              }
            })
          }

          isActive.value = true
          context.$tstore.globalSearch.setItems(result)
        })

        return
      }

      isActive.value = false
      context.$tstore.globalSearch.setItems({})
    }

    watch(() => context.$tstore.globalSearch.searchQuery, searchItems)

    return {
      items,
      hasItems,
      isActive,
      translate,
      closeSearch,
      firstElements,
      goToLink,
      showMatch,
      searchItems
    }
  }
})
