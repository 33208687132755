import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { BookModel } from '~/model'

@Module({ namespaced: true, stateFactory: true, name: 'book' })
export class BookModule extends VuexModule {
  private bookList: BookModel[] = []
  private bookItem: BookModel = new BookModel()

  @Mutation
  private mutationSetBookList(bookList: BookModel[]): void {
    this.bookList = bookList
  }

  @Mutation
  private mutationSetBookItem(bookItem: BookModel): void {
    this.bookItem = bookItem
  }

  @Action
  public setBookList(bookList: BookModel[]): void {
    this.context.commit('mutationSetBookList', bookList)
  }

  @Action
  public setBookItem(bookItem: BookModel): void {
    this.context.commit('mutationSetBookItem', bookItem)
  }

  @Action
  public setCountBook(countBook: number): void {
    this.context.commit('mutationSetCountBook', countBook)
  }

  get BookList(): BookModel[] {
    return this.bookList
  }

  get BookItem(): BookModel {
    return this.bookItem
  }
}
