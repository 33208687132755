import { Form } from '~/shared/api'

export default class AddGroupsForm extends Form {
  public ids: Array<number>

  constructor(ids: Array<number>) {
    super()
    this.ids = ids
  }
}
