import { ApiService } from '~/shared/api'
import BaseRecommendationCardModel from '~/model/Cards/BaseRecommendationCardModel'

class NewsRecommendationsService extends ApiService {
  protected url: string = '/api/v1/yavagro/news/recommendations'

  async getNewsRecommendations() {
    const url = `${this.url}`
    const res: any = await this.requestService.request({
      url
    })
    return Array.isArray(res.data.data) ? res.data.data.map(i => new BaseRecommendationCardModel(i)) : []
  }
}

export default NewsRecommendationsService
