
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class ButtonYavagro extends Vue {
  @Prop({
    default: false
  })
  readonly disabled!: boolean

  @Prop({
    default: 'primary'
  })
  readonly theme!: string

  @Prop({
    default: false
  })
  readonly fullWidth!: boolean

  @Prop({
    default: false
  })
  readonly marginTop!: boolean

  get classes() {
    return {
      [`theme-${this.theme}`]: true,
      full: this.fullWidth,
      marginTop: this.marginTop,
      disabled: this.disabled
    }
  }
}
