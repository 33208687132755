import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, stateFactory: true, name: 'layout' })
export class LayoutModule extends VuexModule {
  private isHideFooter: boolean = false
  private isHideHeader: boolean = false

  @Mutation
  private mutationHideFooter(param: boolean): void {
    this.isHideFooter = param
  }

  @Mutation
  private mutationHideHeader(param: boolean): void {
    this.isHideHeader = param
  }

  @Action
  public setHideFooter(param: boolean): void {
    this.context.commit('mutationHideFooter', param)
  }

  @Action
  public setHideHeader(param: boolean): void {
    this.context.commit('mutationHideHeader', param)
  }

  get hideFooter(): boolean {
    return this.isHideFooter
  }

  get hideHeader(): boolean {
    return this.isHideHeader
  }
}
