import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { CollegeDirectionItemModel } from '~/model/Cards/CollegeDirectionItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'collegeDirection' })
export class CollegeDirectionModule extends VuexModule {
  private collegeDirectionList: Array<CollegeDirectionItemModel> = []
  private collegeDirectionItem: CollegeDirectionItemModel = new CollegeDirectionItemModel()

  @Mutation
  private mutationSetCollegeDirectionList(collegeDirectionList: CollegeDirectionItemModel[]): void {
    this.collegeDirectionList = collegeDirectionList
  }

  @Mutation
  private mutationSetCollegeDirectionItem(collegeDirectionItem: CollegeDirectionItemModel): void {
    this.collegeDirectionItem = collegeDirectionItem
  }

  @Action
  public setCollegeDirectionList(collegeDirectionList: CollegeDirectionItemModel[]): void {
    this.context.commit('mutationSetDirectionList', collegeDirectionList)
  }

  @Action
  public setCollegeDirectionItem(collegeDirectionItem: CollegeDirectionItemModel): void {
    this.context.commit('mutationSetCollegeDirectionItem', collegeDirectionItem)
  }

  @Action
  public setCountCollegeDirection(countCollegeDirection: number): void {
    this.context.commit('mutationSetCountCollegeDirection', countCollegeDirection)
  }

  get CollegeDirectionList(): CollegeDirectionItemModel[] {
    return this.collegeDirectionList
  }

  get CollegeDirectionItem(): CollegeDirectionItemModel {
    return this.collegeDirectionItem
  }
}
