import { RequestError } from './network'

export class Exception extends Error {
  constructor(public message: string, public internalError?: any) {
    super(message)
  }
}
export class EmptyParamException extends Exception {}

export class CriticalException extends Exception {}

export class UnauthorizedException extends Exception {}

export class RequestException extends Exception {
  constructor(public internalError: RequestError) {
    super('Сервер вернул ошибку', internalError)
  }
}

export class ValidationException extends Exception {
  constructor(public validationErrors: { [k: string]: any }, message: string = '') {
    super(message)
  }
}
