import { ApiService } from '~/shared/api'
import { getList } from '~/shared/utils/decorators'
import { ServiceHasList } from '~/shared/interfaces'

// eslint-disable-next-line
interface EmployersTaskListService extends ServiceHasList {}

// @ts-ignore
@getList

// eslint-disable-next-line no-redeclare
class EmployersTaskListService extends ApiService {
  protected route: string = 'assignment'
}

export default EmployersTaskListService
