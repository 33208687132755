import { ApiService } from '~/shared/api'
import type { ServiceRestCreate, ServiceCanSendForm, ServiceRestGetList } from '~/shared/interfaces'
import { sendForm, restCreate, restGetList } from '~/shared/utils/decorators/api-services'
import { ResultModel } from '~/model/Testing'
import { isUnknownObject } from '~/shared/utils/guards'

interface TestingResultsService extends ServiceCanSendForm, ServiceRestCreate, ServiceRestGetList {}

@sendForm
@restCreate
@restGetList
// eslint-disable-next-line no-redeclare
class TestingResultsService extends ApiService {
  // eslint-disable-next-line no-template-curly-in-string
  protected route = 'testings/${slug}/results'

  getItemByHash(hash: string): Promise<ResultModel | null> {
    return this.requestService
      .request({
        url: `${this.prefix}/testings/results/${hash}`
      })
      .then(({ data }) => {
        return isUnknownObject(data) && isUnknownObject(data.data) ? new ResultModel(data.data) : null
      })
      .catch(() => null)
  }
}

export default TestingResultsService
