import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { UiversityProgramModel } from '~/model'

@Module({ namespaced: true, stateFactory: true, name: 'universityPrograms' })
export class UniversityProgramsModule extends VuexModule {
  private internalUniversityPrograms: Array<UiversityProgramModel> = []

  @Mutation
  private mutationSetUniversityPrograms(universityPrograms: UiversityProgramModel[]): void {
    this.internalUniversityPrograms = universityPrograms
  }

  @Action
  public setUniversityPrograms(universityPrograms: UiversityProgramModel[]): void {
    this.context.commit('mutationSetUniversityPrograms', universityPrograms)
  }

  get universityPrograms(): UiversityProgramModel[] {
    return this.internalUniversityPrograms
  }
}
