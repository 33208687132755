import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { IRegion } from '~/model/Misc/RegionModel'

@Module({ namespaced: true, stateFactory: true, name: 'region' })
export class RegionModule extends VuexModule {
  private regionList: Array<IRegion> = []

  @Mutation
  private mutationSetRegionList(regionList: IRegion[]): void {
    this.regionList = regionList
  }

  @Action
  public setRegionList(regionList: IRegion[]): void {
    this.context.commit('mutationSetRegionList', regionList)
  }

  get RegionList(): IRegion[] {
    return this.regionList
  }
}
