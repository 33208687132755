import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import type { SearchItems } from '~/interfaces/GlobalSearch'

@Module({ namespaced: true, stateFactory: true, name: 'globalSearch' })
export default class extends VuexModule {
  private query: string = ''
  private searchItems: SearchItems = {}

  @Mutation
  private mutationSetQuery(value: string): void {
    this.query = value
  }

  @Mutation
  private mutationSetItems(items: SearchItems): void {
    this.searchItems = items
  }

  @Action
  public setQuery(value: string): void {
    this.context.commit('mutationSetQuery', value)
  }

  @Action
  public setItems(items: SearchItems): void {
    this.context.commit('mutationSetItems', items)
  }

  get searchQuery() {
    return this.query
  }

  get items() {
    return this.searchItems
  }
}
