import { AxiosResponse } from 'axios'
import { ApiService, Form } from '~/shared/api'
import { UnknownObject, ServiceCanSendForm } from '~/shared/interfaces'
import { ResponseListModel } from '~/model/Response'
import { replaceParams } from '~/shared/utils/helpers'

export function getList<T extends { new (...args: any[]): ApiService }>(target: T) {
  return class extends target {
    getList(params?: UnknownObject): Promise<ResponseListModel> {
      return this.requestService
        .request({
          url: `${this.prefix}/${this.route}/query`,
          params
        })
        .then(res => new ResponseListModel(res.data))
        .catch(err => {
          console.warn(err) // eslint-disable-line no-console
          return new ResponseListModel(null)
        })
    }
  }
}

export function sendForm<T extends { new (...args: any[]): ApiService }>(target: T) {
  return class extends target {
    sendForm(form: Form, url: string): Promise<AxiosResponse> {
      const data = form.serialize()

      let headers = {}
      if (data instanceof FormData) {
        headers = {
          'Content-Type': 'multipart/form-data'
        }
      }

      return this.requestService.request({ data, method: form.getMethod(), headers, url })
    }
  }
}

export function restGetList<T extends { new (...args: any[]): ApiService }>(target: T) {
  return class extends target {
    getList(params: UnknownObject = {}, routeParams: UnknownObject = {}): Promise<ResponseListModel> {
      return this.requestService
        .request({
          url: `${this.prefix}/${replaceParams(this.route, routeParams)}`,
          params
        })
        .then(({ data }) => new ResponseListModel(data))
        .catch(err => {
          console.warn(err) // eslint-disable-line no-console
          return new ResponseListModel(null)
        })
    }
  }
}

export function restGetItem<T extends { new (...args: any[]): ApiService }>(target: T) {
  return class extends target {
    getItem(id: number | string, routeParams: UnknownObject = {}): Promise<AxiosResponse> {
      return this.requestService.request({
        url: `${this.prefix}/${replaceParams(this.route, routeParams)}/${id}`
      })
    }
  }
}

export function restCreate<T extends { new (...args: any[]): ServiceCanSendForm & ApiService }>(target: T) {
  return class extends target {
    create(form: Form, routeParams: UnknownObject = {}): Promise<AxiosResponse> {
      return this.sendForm(form, `${this.prefix}/${replaceParams(this.route, routeParams)}`)
    }
  }
}

export function restUpdate<T extends { new (...args: any[]): ServiceCanSendForm & ApiService }>(target: T) {
  return class extends target {
    update(id: number | string, form: Form, routeParams: UnknownObject = {}): Promise<AxiosResponse> {
      return this.sendForm(form, `${this.prefix}/${replaceParams(this.route, routeParams)}/${id}`)
    }
  }
}

export function restDelete<T extends { new (...args: any[]): ApiService }>(target: T) {
  return class extends target {
    delete(id: number | string, params: UnknownObject = {}, routeParams: UnknownObject = {}): Promise<AxiosResponse> {
      return this.requestService.request({
        method: 'DELETE',
        url: `${this.prefix}/${replaceParams(this.route, routeParams)}/${id}`,
        params
      })
    }
  }
}
