import { RequestService } from '~/services/RequestService'
import { IRegionLocation } from '~/model/RegionLocation'
import { COOKIE_GEOLOCATION_MAX_AGE_SECONDS, COOKIE_GEOLOCATION_TOKEN_KEY, COOKIE_PATH } from '~/shared/const'

export class GeolocationService {
  constructor(private requestService: RequestService, private cookies: any) {}

  readonly urls = {
    regions: '/api/v1/yavagro/vacancies/regions',
    userRegion: 'api/v1/yavagro/region/get-from-ip'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  async getUserRegion<T>(): Promise<T> {
    const url = `${this.urls.userRegion}`
    const res: any = await this.requestService.request<T>({
      url
    })
    return res.data.data
  }

  async getRegions<T = IRegionLocation[]>(): Promise<T> {
    const url = `${this.urls.regions}`
    const res: any = await this.requestService.request<T>({
      url
    })
    return res.data.data
  }

  public async getSearchRegions<T = IRegionLocation[]>(value: string): Promise<T> {
    const url = `${this.urls.regions}?q=${value}`
    const res: any = await this.requestService.request<T>({
      url
    })
    return res.data.data
  }

  public setCookieRegion(region: any) {
    this.cookies.set(COOKIE_GEOLOCATION_TOKEN_KEY, region, {
      path: COOKIE_PATH,
      maxAge: COOKIE_GEOLOCATION_MAX_AGE_SECONDS
    })
  }

  public getCookieRegion() {
    return this.cookies.get(COOKIE_GEOLOCATION_TOKEN_KEY)
  }
}
