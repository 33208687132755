
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { RegionLocationItem } from '~/model/RegionLocation'
import ButtonYavagro from '~/components/UI/ButtonYavagro.vue'

@Component({
  components: { ButtonYavagro }
})
export default class ChangeRegionLocation extends Vue {
  @Prop({
    default: ''
  })
  current!: RegionLocationItem

  onSuccessAnswer(): void {
    this.$services.geolocation.setCookieRegion(this.current)
    this.$emit('on-success-answer', this.current)
  }

  onNegativeAnswer(): void {
    this.$emit('on-negative-answer')
  }
}
