import { Form } from '~/shared/api'
import type { ValidationErrors } from '~/shared/interfaces'
import type { InputParams as SubscribeInputParams } from '~/interfaces/Subscribe'

export default class SubscribeVacanciesForm extends Form {
  public email: string = ''
  public userId: string = ''
  public 'subscription[type]': string = 'vacancies'
  public 'subscription[params][sp]': string = ''
  public 'subscription[params][requestData][search]': string = ''
  public 'subscription[params][requestData][region]': string = ''
  public 'subscription[params][requestData][tf:employment_value_kw]': string = ''

  beforeSerialize() {
    Object.getOwnPropertyNames(this).forEach(prop => {
      if (this[prop] === '') {
        this.ignoreKeys.push(prop)
      }
    })
  }

  validate(): true | ValidationErrors {
    if (this.email && this.email.match(/.+@.+\..+/)) {
      return true
    }

    return { email: 'Некорректный email адрес' }
  }

  fillParams(params: SubscribeInputParams): void {
    const props = Object.getOwnPropertyNames(this)
    Object.keys(params).forEach(key => {
      if (props.includes(`subscription[params][requestData][${key}]`)) {
        this[`subscription[params][requestData][${key}]`] = params[key]
      }

      if (props.includes(`subscription[params][${key}]`)) {
        this[`subscription[params][${key}]`] = params[key]
      }

      if (props.includes(`subscription[${key}]`)) {
        this[`subscription[${key}]`] = params[key]
      }
    })
  }
}
