import type { Role, RoleId } from '~/interfaces/User'

const roleStudent: Role = {
  allowedRoutes: [/^\/account\/?$/, /^\/account\/candidate.*/, /^\/digital-profile.*/],
  defaultRoute: '/account/candidate/resumes'
}

const roleUniversity: Role = {
  allowedRoutes: [/^\/account\/?$/, /^\/account\/publish.*/],
  defaultRoute: '/account/publish/settings'
}

const roleCompany: Role = {
  allowedRoutes: [/^\/account\/?$/, /^\/account\/vacancies.*/, /^\/account\/executive-search\/?$/],
  defaultRoute: '/account/vacancies'
}

const roleDigitalProfiles: Role = {
  allowedRoutes: [/^\/account\/?$/, /^\/account\/university-statistics.*/],
  defaultRoute: '/account/university-statistics'
}

const roles: { [key in RoleId]: Role } = {
  student: { ...roleStudent },
  university: { ...roleUniversity },
  company: { ...roleCompany },
  digitalprofiles: { ...roleDigitalProfiles }
}

export default roles
