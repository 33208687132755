import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class ProfessionModel extends BaseModel {
  public name: string = ''
  public url: string = ''
  public logo: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
