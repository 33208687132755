import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class LandingVideoBlockModel extends BaseModel {
  name: 'video_block' = 'video_block'
  title: string = ''
  file: string = ''
  url: string = ''
  'video_type': string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }

    if (this.file) {
      this.file = `/api/v1/yavagro/videos/get?url=${this.file}`
    }
  }
}
