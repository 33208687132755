/* eslint-disable camelcase */
export class PartnerModel {
  id: number = 0
  name: string = ''
  logo: string = ''
  description: string = ''
  site: string = ''
  cooperation_terms: string = ''
  slug: string = ''
  is_display_in_main: number = 0
}
