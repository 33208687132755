import type { WithContext, Thing } from 'schema-dts'
import { JsonObj } from 'vue-meta/types/vue-meta'
import { uuid } from '~/shared/utils/helpers'

export function makeJsonLdHeader<T extends Thing>(data: WithContext<T>) {
  return {
    script: [
      {
        hid: `jsonld-${uuid()}`,
        type: 'application/ld+json',
        json: data as unknown as JsonObj
      }
    ]
  }
}

export function getEmploymentType(type: string): string {
  const value = type.toLowerCase()
  if (/полная/.test(value)) {
    return 'FULL_TIME'
  } else if (/частичная/.test(value)) {
    return 'PART_TIME'
  } else if (/стажировка/.test(value)) {
    return 'INTERN'
  } else if (/сезонная/.test(value)) {
    return 'TEMPORARY'
  } else {
    return 'OTHER'
  }
}
