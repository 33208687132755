import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class LandingTextImageBlockModel extends BaseModel {
  name: 'text_and_image_block' = 'text_and_image_block'
  title: string = ''
  text: string = ''
  image: string = ''
  'image_align': string = ''
  'background_image': string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (this.image) {
        this.image = new ImageService().cropImage(this.image, {})
      }

      if (this.background_image) {
        this.background_image = new ImageService().cropImage(this.background_image, {})
      }
    }
  }
}
