import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { NamedItemModel } from '~/model/NamedItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'bookSorting' })
export class BookSortingModule extends VuexModule {
  private bookSortingList: Array<NamedItemModel> = []

  @Mutation
  private mutationSetBookSortingList(bookSortingList: NamedItemModel[]): void {
    this.bookSortingList = bookSortingList
  }

  @Action
  public setBookSortingList(bookSortingList: NamedItemModel[]): void {
    this.context.commit('mutationSetBookSortingList', bookSortingList)
  }

  get getBookSortingList(): NamedItemModel[] {
    return this.bookSortingList
  }
}
