
import { Component, Prop, Vue } from 'nuxt-property-decorator'

import AppLazyImage from '~/components/UI/AppLazyImage.vue'
import ModalClose from '~/components/Modal/ModalClose.vue'

@Component({
  components: {
    AppLazyImage,
    ModalClose
  }
})
export default class ModalHeaderLogo extends Vue {
  @Prop({
    default: () => {}
  })
  readonly typeModal!: boolean

  onClose() {
    this.$emit('on-close')
  }
}
