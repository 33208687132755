// Эндпоинты CMO для каждого стенда проекта
// eslint-disable-next-line @typescript-eslint/naming-convention
export const CMO_ENDPOINTS = {
  dev: 'https://dev-rp.rshb.ru:443/api/personalizations',
  test: 'https://dev-rp.rshb.ru:8443/api/personalizations',
  preprod: 'https://dev-rp.rshb.ru:8443/api/personalizations',
  prod: 'https://rp.rshb.ru:8443/api/personalizations'
}

// Стенды CMO для каждого стенда проекта
// eslint-disable-next-line @typescript-eslint/naming-convention
export const CMO_STANDS = {
  dev: 'dev-stand',
  test: 'test-stand',
  preprod: 'preprod-stand',
  prod: 'prod-stand'
}

// Путь к основному скрипту SnowPlow
// eslint-disable-next-line @typescript-eslint/naming-convention
export const CMO_SCRIPT_URL = '/shared/cmo-digital-personalization.js'
