import { Context, Middleware } from '@nuxt/types'
import { isPrivateRoute } from '~/shared/utils/helpers'
import { isNonEmptyString } from '~/shared/utils/guards'

/**
 * Редирект на URL указанный в параметрах запроса
 */
const redirectTo: Middleware = ({ $tstore, redirect, route }: Context) => {
  const redirectTo = route.query.redirect_to

  if (isNonEmptyString(redirectTo) && /^\//.test(redirectTo)) {
    if (isPrivateRoute(redirectTo)) {
      const user = $tstore.auth.authorizedUser
      if (user) {
        redirect(redirectTo)
        return undefined
      }

      $tstore.auth.setShowModalAuth({ show: true, backUrl: redirectTo })
    } else {
      redirect(redirectTo)
    }
  }
}

export default redirectTo
