import { ApiService } from '~/shared/api'
import { restGetList } from '~/shared/utils/decorators'
import type { ServiceRestGetList } from '~/shared/interfaces'
import { BreadcrumbModel } from '~/model/BreadCrumbs'

interface BreadCrumbsService extends ServiceRestGetList {}

@restGetList
// eslint-disable-next-line no-redeclare
class BreadCrumbsService extends ApiService {
  protected route: string = 'breadcrumbs'

  getListModel(url: string): Promise<Array<BreadcrumbModel>> {
    return this.getList({ url }).then(({ data }) => {
      if (Array.isArray(data)) {
        return data.map(i => new BreadcrumbModel(i))
      }

      return []
    })
  }
}

export default BreadCrumbsService
