import { AxiosResponse } from 'axios'
import { RequestService } from '~/services/RequestService'

export class CourseService {
  readonly apiPrefix: string = '/api/v1/yavagro'

  constructor(private requestService: RequestService) {}

  public async sentCoupon(email: string, id: string): Promise<number> {
    const { status } = await this.requestService.request<AxiosResponse<number>>({
      data: {
        email
      },
      method: 'POST',
      url: `${this.apiPrefix}/courses/${id}/coupon`
    })

    return status
  }
}
