import * as process from 'process'
import { Middleware, Context } from '@nuxt/types'
import { DigitalProfile } from '~/model/DigitalProfile'

const isDigitalProfile: Middleware = async ({ $services, route, $tstore, redirect }: Context) => {
  try {
    const name = route.name || ''
    const routes = ['account', 'account-candidate']
    const routePrefix = 'account-vacancies-'

    if (process.server || routes.includes(name) || name.startsWith(routePrefix)) {
      return
    }

    if ($tstore.auth.isLogged) {
      const profile = await $services.digitalProfile.getProfile<DigitalProfile>().catch(error => {
        if (error) {
          $tstore.notification.removeNotifications()
        }
      })
      if (Array.isArray(profile) && profile.length >= 1) {
        $tstore.digitalProfile.setIsDigitalProfile(true)
        if (route.name === 'digital-profile-create') {
          redirect('/digital-profile')
        }
      } else {
        $tstore.digitalProfile.setIsDigitalProfile(false)
        if (route.name === 'digital-profile' || route.name === 'digital-profile-edit') {
          redirect('/digital-profile/create')
        }
      }
    }
  } catch (e) {
    console.log(e); // eslint-disable-line
  }
}
export default isDigitalProfile
