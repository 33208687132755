import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class List<T = unknown> extends BaseModel {
  public data: T[] = []
  public lastPage: number = 1
  public perPage: number = 1
  public total: number = 0

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (isUnknownObject(data.meta)) {
        this.lastPage = Number(data.meta.last_page ?? 1)
        this.perPage = Number(data.meta.per_page ?? 1)
        this.total = Number(data.meta.total ?? 1)
      }
    }
  }
}
