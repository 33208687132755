import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class Offer extends BaseModel {
  public icon: string = ''
  public text: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
