import { ApiService } from '~/shared/api'
import type {
  ServiceRestGetList,
  ServiceCanSendForm,
  ServiceRestCreate,
  ServiceRestUpdate,
  ServiceRestDelete
} from '~/shared/interfaces'
import { restGetList, restCreate, restDelete, restUpdate, sendForm } from '~/shared/utils/decorators/api-services'

interface SocialGroupsService
  extends ServiceRestGetList,
    ServiceCanSendForm,
    ServiceRestCreate,
    ServiceRestUpdate,
    ServiceRestDelete {}

@sendForm
@restGetList
@restCreate
@restUpdate
@restDelete
// eslint-disable-next-line no-redeclare
class SocialGroupsService extends ApiService {
  protected route = 'users-management/socials/groups'
}

export default SocialGroupsService
