import { render, staticRenderFns } from "./ModalAuth.vue?vue&type=template&id=0452a478&scoped=true&"
import script from "./ModalAuth.vue?vue&type=script&lang=ts&"
export * from "./ModalAuth.vue?vue&type=script&lang=ts&"
import style0 from "./ModalAuth.vue?vue&type=style&index=0&id=0452a478&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0452a478",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/usr/src/app/components/Modal/Modal.vue').default})
