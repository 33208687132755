/* eslint-disable camelcase */
interface Industry {
  id: string
  name?: string
  description?: string
  image?: string
  background?: string
  icon?: string
  is_published: number
  slug?: string
  pivot?: {}
}

export class EmployerItemModel {
  id: string = ''
  slug: string = ''
  name?: string = ''
  description?: string = ''
  logo?: string = ''
  image?: string = ''
  slider_image?: string = ''
  text_tasks?: string = ''
  text_numbers?: string = ''
  text_geo?: string = ''
  text_warranty?: string = ''
  text_offer?: string = ''
  text_requirements?: string = ''
  vacancies_count?: number = 0
  internships_count?: number = 0
  blocks?: string = ''
  link?: string = ''
  is_published?: number = 0
  is_deleted?: number = 0
  industry?: Industry[] | Industry
  video?: Array<{ link?: string }>
  employer_quotes?: Array<{ author?: string; image?: string; text?: string }>
  news?: Array<{ [key: string]: unknown }>
}

export class EmployermModel {
  id: number | string = 0
  slug: string = ''
  name?: string = ''
  description?: string = ''
  logo?: string = ''
  image?: string = ''
  slider_image?: string = ''
  text_tasks?: string = ''
  text_numbers?: string = ''
  text_geo?: string = ''
  text_warranty?: string = ''
  text_offer?: string = ''
  text_requirements?: string = ''
  blocks?: string = ''
  link?: string = ''
  is_published: number = 0
  is_deleted: number = 0
  industry?: Industry[] | Industry
}
