import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import StudentActivitiesFiltersModel from '~/model/StudentActivities/StudentActivitiesFiltersModel'

@Module({
  namespaced: true,
  stateFactory: true,
  name: 'digitalProfilesFilters'
})
export class DigitalProfilesFiltersModule extends VuexModule {
  private filterFields: Array<StudentActivitiesFiltersModel> = []

  @Mutation
  private mutationSetFilterFields(filterFields: StudentActivitiesFiltersModel[]): void {
    this.filterFields = filterFields
  }

  @Action
  public setFilterFields(filterBlocks: StudentActivitiesFiltersModel[]): void {
    this.context.commit('mutationSetFilterFields', filterBlocks)
  }

  get getFilterFields(): StudentActivitiesFiltersModel[] {
    return this.filterFields
  }
}
