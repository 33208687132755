/* eslint-disable camelcase */
export class ProfessionalEducationItemModel {
  id: string = ''
  slug: string = ''
  is_published: number = 0
  name?: string = ''
  logo?: string = ''
  image?: string = ''
  description?: string = ''
  phone?: string = ''
  link?: string = ''
  budget_avg_score?: string = ''
  commerce_avg_score?: string = ''
  budget_places_number?: number = 0
  budget_distant_places_number?: number = 0
  foundation_year?: string = ''
  region?: string = ''
  city?: string = ''
  email?: string = ''
  status?: string = ''
  short_name?: string = ''
  address?: string = ''
  nobel_laureats_number?: number = 0
  teachers_number?: number = 0
  students_number?: number = 0
  is_branch?: number = 0
  bakalavr_programs_number?: number = 0
  magistr_programs_number?: number = 0
  aspirant_programs_number?: number = 0
  slider_image?: string = ''
  training_courses?: any[] = []
  streams?: any[] = []
  created_at: string = ''
  updated_at: string = ''
}
