import { render, staticRenderFns } from "./lightbox.vue?vue&type=template&id=748a7341&scoped=true&"
import script from "./lightbox.vue?vue&type=script&lang=js&"
export * from "./lightbox.vue?vue&type=script&lang=js&"
import style0 from "./lightbox.vue?vue&type=style&index=0&id=748a7341&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "748a7341",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalAuth: require('/usr/src/app/components/Modal/ModalAuth.vue').default})
