import { UI_KIT_STORE_PREFIX } from '@rshb/rshb-vue-kit/src/const'

export default context => {
  context.app.store.subscribeAction(action => {
    if (action.type === `${UI_KIT_STORE_PREFIX}/auth/responseException`) {
      const axiosError = action.payload.toJSON()
      if (axiosError.status === 401) {
        const backUrl = this.config.hosts.externalHost + context.route.path
        context.$services.user.removeToken()
        return context.redirect(`${context.$config.hosts.authHostLoginUrl}&backurl=${backUrl}`)
      }
    }
  })
}
