import { AxiosResponse } from 'axios'
import { RequestService } from '~/services/RequestService'

export class WorkCardsService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    workCards: '/api/v1/yavagro/index-page-counts'
  }

  public async getWorkCardsList<T>(): Promise<T> {
    const res = await this.requestService.request<AxiosResponse<T>>({
      url: `${this.urls.workCards}`
    })
    return res.data.data
  }
}
