import { Context, Middleware } from '@nuxt/types'
import { roles } from '~/shared/const'

/**
 * Редирект на дефолтную страницу на основании текущих ролей пользователя
 */
const redirectToDefault: Middleware = ({ $tstore, redirect }: Context) => {
  const user = $tstore.auth.authorizedUser
  if (user !== null && user.roles.size > 0) {
    const firstRole = [...user.roles][0]
    return redirect(roles[firstRole].defaultRoute)
  }
  return redirect('/')
}

export default redirectToDefault
