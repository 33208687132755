import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { CollegeItemModel } from '~/model/Cards/CollegeItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'college' })
export class CollegeModule extends VuexModule {
  private collegeList: Array<CollegeItemModel> = []
  private collegeItem: CollegeItemModel = new CollegeItemModel()
  private countCollege: number = 0

  @Mutation
  private mutationSetCollegeList(collegeList: CollegeItemModel[]): void {
    this.collegeList = collegeList
  }

  @Mutation
  private mutationSetCollegeItem(collegeItem: CollegeItemModel): void {
    this.collegeItem = collegeItem
  }

  @Mutation
  private mutationSetCountCollege(countCollege: number): void {
    this.countCollege = countCollege
  }

  @Action
  public setCollegeList(collegeList: CollegeItemModel[]): void {
    this.context.commit('mutationSetCollegeList', collegeList)
  }

  @Action
  public setCollegeItem(collegeItem: CollegeItemModel): void {
    this.context.commit('mutationSetCollegeItem', collegeItem)
  }

  @Action
  public setCountCollege(countCollege: number): void {
    this.context.commit('mutationSetCountCollege', countCollege)
  }

  get CollegeList(): CollegeItemModel[] {
    return this.collegeList
  }

  get CollegeCount(): number {
    return this.countCollege
  }

  get CollegeItem(): CollegeItemModel {
    return this.collegeItem
  }
}
