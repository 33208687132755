import { Form } from '~/shared/api'
import { onlyCyrillicRule, emailRule, phoneRule } from '~/shared/utils/validation'

export default class EmployersTaskForm extends Form {
  name: string = ''
  'contact_name': string = ''
  email: string = ''
  phone: string = ''
  assignment: string = ''
  prize: string = ''
  date: string = ''
  _invalid: boolean = true

  clear() {
    this.name = ''
    this.contact_name = ''
    this.email = ''
    this.phone = ''
    this.assignment = ''
    this.prize = ''
    this.date = ''
    this._invalid = true
  }

  static rules() {
    return {
      contact_name: onlyCyrillicRule,
      email: emailRule,
      phone: phoneRule
    }
  }
}
