import Vue from 'vue'
import { Plugin, Context } from '@nuxt/types'
import { initializeServices } from '~/services'

const servicesPlugin: Plugin = (context: Context) => {
  const services = initializeServices(context)

  Vue.prototype.$services = services
  context.$services = services
}

export default servicesPlugin
