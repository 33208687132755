import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { CompetitionsItemModel } from '~/model/Cards/CompetitionsItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'competitions' })
export class CompetitionsModule extends VuexModule {
  private competitionsList: Array<CompetitionsItemModel> = []
  private competitionsItem: CompetitionsItemModel = new CompetitionsItemModel()

  @Mutation
  private mutationSetCompetitionsList(competitionsList: CompetitionsItemModel[]): void {
    this.competitionsList = competitionsList
  }

  @Mutation
  private mutationSetCompetitionsItem(competitionsItem: CompetitionsItemModel): void {
    this.competitionsItem = competitionsItem
  }

  @Action
  public setCompetitionsList(competitionsList: CompetitionsItemModel[]): void {
    this.context.commit('mutationSetCompetitionsList', competitionsList)
  }

  @Action
  public setCompetitionsItem(competitionsItem: CompetitionsItemModel): void {
    this.context.commit('mutationSetCompetitionsItem', competitionsItem)
  }

  get CompetitionsList(): CompetitionsItemModel[] {
    return this.competitionsList
  }

  get CompetitionsItem(): CompetitionsItemModel {
    return this.competitionsItem
  }
}
