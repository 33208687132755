import { WarrantyModel, TextNumberModel, OfferModel, QuoteModel, TextTaskModel } from '.'
import { RegionModel } from '~/model/Region'
import { VideoModel } from '~/model/Video'
import { GeoPositionModel } from '~/model/Geo'
import { NewsModel } from '~/model/News'
import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class Employer extends BaseModel {
  public id: number = 0
  public name: string = ''
  public slug: string = ''
  public description: string = ''
  public link: string = ''
  public logo: string = ''
  public image: string = ''
  public imageGeo: string = ''
  public vacanciesCount: number = 0
  public internshipsCount: number = 0
  public tasks: Array<TextTaskModel> = []
  public regions: Array<RegionModel> = []
  public video: Array<VideoModel> = []
  public geoPositions: Array<GeoPositionModel> = []
  public news: Array<NewsModel> = []
  public sliderImages: Array<string> = []
  public warranties: Array<WarrantyModel> = []
  public numbers: Array<TextNumberModel> = []
  public offers: Array<OfferModel> = []
  public requirements: Array<string> = []
  public quotes: Array<QuoteModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
      if (Array.isArray(data.regions)) {
        this.regions = data.regions.map(i => new RegionModel(i.region))
      }
      if (Array.isArray(data.video)) {
        this.video = data.video.map(i => new VideoModel(i))
      }
      if (Array.isArray(data.text_geo)) {
        this.geoPositions = data.text_geo.map(i => new GeoPositionModel(i))
      }
      if (Array.isArray(data.news)) {
        this.news = data.news.map(i => new NewsModel(i))
      }
      if (Array.isArray(data.text_numbers)) {
        this.numbers = data.text_numbers.map(i => new TextNumberModel(i))
      }
      if (Array.isArray(data.text_requirements)) {
        data.text_requirements.forEach(i => {
          if (isUnknownObject(i) && typeof i.text === 'string') {
            this.requirements.push(i.text)
          }
        })
      }
      if (Array.isArray(data.employer_quotes)) {
        this.quotes = data.employer_quotes.map(i => new QuoteModel(i))
      }

      if (typeof data.slider_image === 'string') {
        try {
          const parsed = JSON.parse(data.slider_image)
          if (Array.isArray(parsed)) {
            this.sliderImages = parsed.reduce((initial, item) => {
              if (typeof item.img === 'string' && item.img) {
                initial.push(item.img)
              }
              return initial
            }, [])
          }
        } catch (e) {
          this.sliderImages = []
        }
      }

      if (typeof data.text_warranty === 'string') {
        try {
          const parsed = JSON.parse(data.text_warranty)
          if (Array.isArray(parsed)) {
            parsed.forEach(i => this.warranties.push(new WarrantyModel(i)))
          }
        } catch (e) {}
      }

      if (typeof data.text_tasks === 'string') {
        try {
          const parsed = JSON.parse(data.text_tasks)
          if (Array.isArray(parsed)) {
            parsed.forEach(i => this.tasks.push(new TextTaskModel(i)))
          }
        } catch (e) {}
      }

      if (typeof data.text_offer === 'string') {
        try {
          const parsed = JSON.parse(data.text_offer)
          if (Array.isArray(parsed)) {
            parsed.forEach(i => this.offers.push(new OfferModel(i)))
          }
        } catch (e) {}
      }
    }
  }
}
