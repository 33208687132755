import { AxiosResponse } from 'axios'
import { ApiService } from '~/shared/api'
import type { ServiceCanSendForm, ServiceRestCreate } from '~/shared/interfaces'
import { sendForm, restCreate } from '~/shared/utils/decorators/api-services'

interface SubscribeService extends ServiceCanSendForm, ServiceRestCreate {}

@sendForm
@restCreate
// eslint-disable-next-line no-redeclare
class SubscribeService extends ApiService {
  protected route = 'subscriptions/subscribe'

  public async subscribe<T>(email: string): Promise<AxiosResponse<T>> {
    return await this.requestService.request<T>({
      url: 'https://svoefermerstvo-test.sz.rshbcloud.ru/api/farmers-community/partnership/apply',
      method: 'POST',
      data: {
        partnership: {
          category: 'ЯвАгро - главная',
          request_type: 'Присоединение',
          subcategory: 'Школа фермеров',
          additional_data: [
            {
              email
            }
          ]
        }
      }
    })
  }
}

export default SubscribeService
