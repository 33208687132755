import { ApiService } from '~/shared/api'
import { restGetItem } from '~/shared/utils/decorators'
import type { ServiceRestGetItem } from '~/shared/interfaces'
import { isUnknownObject } from '~/shared/utils/guards'
import LandingModel from '~/model/Landings/Landing'

interface LandingService extends ServiceRestGetItem {}

@restGetItem
// eslint-disable-next-line no-redeclare
class LandingService extends ApiService {
  protected route: string = 'landings'

  getItemModel(slug: string): Promise<LandingModel> {
    return this.getItem(slug)
      .then(({ data }) => {
        const landing = isUnknownObject(data) && isUnknownObject(data.data) ? data.data : null
        return new LandingModel(landing)
      })
      .catch(() => new LandingModel(null))
  }
}

export default LandingService
