
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

@Component({
  components: {
    AppLazyImage
  }
})
export default class NotFound extends Vue {
  profSearchBannerImage = '/images/error/404.svg'
  @Prop({
    default: () => {}
  })
  error!: any

  goToMainPage() {
    this.$router.push('/')
  }

  head() {
    return {
      title: 'Страница не найдена - Я в агро'
    }
  }
}
