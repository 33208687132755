import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { NamedItemModel } from '~/model/NamedItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'grantsSorting' })
export class GrantsSortingModule extends VuexModule {
  private grantsSortingList: Array<NamedItemModel> = []

  @Mutation
  private mutationSetGrantsSortingList(grantsSortingList: NamedItemModel[]): void {
    this.grantsSortingList = grantsSortingList
  }

  @Action
  public setGrantsSortingList(grantsSortingList: NamedItemModel[]): void {
    this.context.commit('mutationSetGrantsSortingList', grantsSortingList)
  }

  get getGrantsSortingList(): NamedItemModel[] {
    return this.grantsSortingList
  }
}
