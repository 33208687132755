
import { Component, Vue, Watch } from 'nuxt-property-decorator'

@Component
export default class Cookie extends Vue {
  public isShow = false
  public isAccepted = false

  private readonly COOKIE_RULES = 'https://www.rshb.ru/pd-policy/cookie/'
  private readonly ACCEPTED_COOKIE_NAME = 'accept_cookies'

  get isAuth() {
    return this.$tstore.auth.isLogged
  }

  public mounted(): void {
    const cookie = this.getCookie()
    if (cookie || this.isAuth) {
      this.isAccepted = true
    }
  }

  public onClose(): void {
    this.hideAlert()
    this.setAcceptCookie()
  }

  private setAcceptCookie(): void {
    this.$cookies.set(this.ACCEPTED_COOKIE_NAME, 1)
  }

  private hideAlert(): void {
    this.isShow = false
    this.isAccepted = true
  }

  private getCookie(): string {
    const matches = document.cookie.match(`(?:^|; )${this.ACCEPTED_COOKIE_NAME}=([^;]*)`)
    return matches ? decodeURIComponent(matches[1]) : ''
  }

  @Watch('isAuth')
  private onChangeAuth() {
    this.isAccepted = !!this.getCookie() || this.isAuth
  }
}
