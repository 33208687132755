
import { Component, Vue, VModel, Watch, Prop } from 'nuxt-property-decorator'

import ModalClose from '~/components/Modal/ModalClose.vue'

@Component({
  components: {
    ModalClose
  }
})
export default class Modal extends Vue {
  @VModel({ type: Boolean })
  isShow!: boolean

  @Prop({
    default: ''
  })
  type!: string

  @Prop({
    default: 'left'
  })
  position!: String

  @Prop({
    default: false
  })
  headerLogo!: boolean

  onClickBackdrop() {
    this.onClose()
  }

  onClose() {
    this.$emit('input', false)
  }

  get classes() {
    return {
      show: this.isShow,
      [`type-${this.typeModal}`]: true,
      [`position-${this.position}`]: true
    }
  }

  get typeModal(): string {
    if (this.type) {
      return this.type
    }
    if (process.browser) {
      return window.innerWidth <= 1439 ? 'curtain' : 'modal'
    }

    return 'modal'
  }

  get isModal() {
    return this.typeModal === 'modal'
  }

  @Watch('isShow', { immediate: true })
  onToggleShow(value: boolean) {
    this.typeModal === 'fullscreen'
      ? this.$nuxt.$emit('on-toggle-fullscreen-modal', value)
      : this.$nuxt.$emit('on-toggle-modal', value)
  }
}
