import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { NewsItemModel } from '~/model/Cards/NewsItemModel'
import { NewsModel } from '~/model/News'
import { COOKIE_MAX_AGE_SECONDS, COOKIE_PATH, COOKIE_NEWS_DISLIKE_KEY, COOKIE_NEWS_LIKE_KEY } from '~/shared/const'
import BaseRecommendationCardModel from '~/model/Cards/BaseRecommendationCardModel'

@Module({ namespaced: true, stateFactory: true, name: 'news' })
export default class extends VuexModule {
  private internalNewsList: Array<NewsItemModel> = []
  private internalNewsItem: NewsItemModel = new NewsItemModel()
  private store: any
  private listNews: Array<NewsModel> = []
  private listRecommendationsNews: Array<BaseRecommendationCardModel> = []

  @Mutation
  private mutationSetNewsRecommendationsList(recommendation: BaseRecommendationCardModel[]): void {
    this.listRecommendationsNews = recommendation
  }

  @Action
  public setNewsRecommendationsList(list: BaseRecommendationCardModel[]): void {
    this.context.commit('mutationSetNewsRecommendationsList', list)
  }

  @Mutation
  private mutationSetNewsList(news: NewsItemModel[]): void {
    this.internalNewsList = news
  }

  @Action
  public setNewsList(newsList: NewsItemModel[]): void {
    this.context.commit('mutationSetNewsList', newsList)
  }

  @Mutation
  private mutationSetNewsItem(newsItem: NewsItemModel): void {
    this.internalNewsItem = newsItem
  }

  @Mutation
  private mutationNewsList(data: Array<NewsModel>): void {
    this.listNews = data
  }

  @Action
  public setNewsItem(newsItem: NewsItemModel): void {
    this.context.commit('mutationSetNewsItem', newsItem)
  }

  @Action
  public setLike(slug: string): void {
    const like = this.store.$cookies.get(COOKIE_NEWS_LIKE_KEY) ?? []
    if (!like.includes(slug)) {
      like.push(slug)
      this.store.$cookies.set(COOKIE_NEWS_LIKE_KEY, like, {
        path: COOKIE_PATH,
        maxAge: COOKIE_MAX_AGE_SECONDS * 1000
      })
      this.internalNewsItem.like = like.includes(slug)
      this.context.commit('mutationSetNewsItem', this.internalNewsItem)
    }
  }

  @Action
  public setDislike(slug: string): void {
    const dislike = this.store.$cookies.get(COOKIE_NEWS_DISLIKE_KEY) ?? []
    if (!dislike.includes(slug)) {
      dislike.push(slug)
      this.store.$cookies.set(COOKIE_NEWS_DISLIKE_KEY, dislike, {
        path: COOKIE_PATH,
        maxAge: COOKIE_MAX_AGE_SECONDS * 1000
      })
      this.internalNewsItem.dislike = dislike.includes(slug)
      this.context.commit('mutationSetNewsItem', this.internalNewsItem)
    }
  }

  @Action
  public getDislike(slug: string) {
    const dislike = this.store.$cookies.get(COOKIE_NEWS_DISLIKE_KEY) ?? []
    if (this.internalNewsItem) {
      this.internalNewsItem.dislike = dislike.includes(slug)
      this.context.commit('mutationSetNewsItem', this.internalNewsItem)
    }
  }

  @Action
  public getLike(slug: string) {
    const like = this.store.$cookies.get(COOKIE_NEWS_LIKE_KEY) ?? []
    if (this.internalNewsItem) {
      this.internalNewsItem.like = like.includes(slug)
      this.context.commit('mutationSetNewsItem', this.internalNewsItem)
    }
  }

  @Action
  public setListNews(data: Array<NewsModel>) {
    this.context.commit('mutationNewsList', data)
  }

  get newsItem(): NewsItemModel {
    return this.internalNewsItem
  }

  get newsList(): NewsItemModel[] {
    return this.internalNewsList
  }

  get news() {
    return this.listNews
  }

  get newsRecommendationsList() {
    return this.listRecommendationsNews
  }
}
