import type { Route } from 'vue-router'
import { UnknownObject } from '~/shared/interfaces'
import { isString } from '~/shared/utils/guards'

/**
 * Склонение слов числительных
 * declOfNum(5, ['год', 'года', 'лет'])
 */
export function declOfNum(n: number, titles: [string, string, string]): string {
  return titles[
    n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
  ]
}

export function camelize(str: string): string {
  return str.replace(/^([A-Z])|[\s-_]+(\w)/g, function (_a, p1, p2) {
    if (p2) {
      return p2.toUpperCase()
    }
    return p1.toLowerCase()
  })
}

export function toSnakeCase(str: string): string {
  return str
    .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
    .replace(/([a-z0-9])([A-Z])/, '$1_$2')
    .toLowerCase()
}

export function fillOwnProperties(obj: object, data: UnknownObject, ignoreProps: Array<string> = []): void {
  const properties = Object.getOwnPropertyNames(obj).filter(p => !ignoreProps.includes(p))
  Object.keys(data).forEach(key => {
    if (properties.includes(key)) {
      obj[key] = data[key]
    } else if (properties.includes(camelize(key))) {
      obj[camelize(key)] = data[key]
    }
  })
}

export function isPrivateRoute(route: Route | string): boolean {
  const value = isString(route) ? route : route.path
  return !!value.length && /^\/(account|digital-profile)/.test(value)
}

export function replaceParams(str: string, params: UnknownObject): string {
  return str.replace(/\${(.+?)}/g, (_, expression) => {
    const value = params[expression]
    return typeof value === 'string' || typeof value === 'number' ? String(value) : ''
  })
}

/** Возвращает цену с пробелами */
export function formatSalary(salary: string) {
  return salary.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
