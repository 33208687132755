import { SocialNetworkGroupModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class SocialNetworkModel extends BaseModel {
  public id: number = 0
  public name: string = ''
  public type: string = ''
  public activateUrl: string = ''
  public isActivated: boolean = false
  public tokenStoreUrl: string = ''
  public groups: Array<SocialNetworkGroupModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.users_socials_groups)) {
        this.groups = data.users_socials_groups.map(i => new SocialNetworkGroupModel(i))
      }
    }
  }
}
