import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { MenuModel } from '~/model/Menus/MenuModel'

@Module({ namespaced: true, stateFactory: true, name: 'schoolMenu' })
export class SchoolMenuModule extends VuexModule {
  private internalMenu: MenuModel = new MenuModel()

  @Mutation
  private mutationSetSchoolMenu(schoolMenu: MenuModel): void {
    this.internalMenu = schoolMenu
  }

  @Action
  public setSchoolMenu(schoolMenu: MenuModel): void {
    this.context.commit('mutationSetSchoolMenu', schoolMenu)
  }

  get schoolMenu(): MenuModel {
    return this.internalMenu
  }
}
