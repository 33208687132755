import { RequestService } from '~/services/RequestService'

export class SubscribeBlockService {
  constructor(private requestService: RequestService) {}

  // readonly urls = {
  //   tagcloud: '/api/tagcloud'
  // };

  public getSubscribeBlock<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.tagcloud}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve({
        name: 'Получайте новости сельского хозяйства раз в неделю на свою почту',
        buttonLabel: 'Подписаться',
        backgroundColor: '#D5E5F7'
      })
    })
  }
}
