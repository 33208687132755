
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

@Component({
  components: {
    AppLazyImage
  }
})
export default class RoundButton extends Vue {
  @Prop({
    default: ''
  })
  readonly backgroundColor!: string

  @Prop({
    default: '270'
  })
  readonly rotate!: string | number

  @Prop({
    default: '/icons/ui/dropdown.svg'
  })
  readonly icon!: string

  onClickButton() {
    this.$emit('add-button-click')
  }

  get classes() {
    return {
      [`round-button-${this.rotate}`]: true
    }
  }
}
