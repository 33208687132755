import { ApiService } from '~/shared/api'
import { toKeyModel } from '~/shared/api/utils'
import { AccountUniversityStatisticsModel } from '~/model/AccountUniversityStatistics'
import { messages } from '~/shared/const/messages'

// eslint-disable-next-line no-redeclare
class AccountUniversityStatisticsService extends ApiService {
  protected url: string = '/api/v1/yavagro/digitalprofiles/stat'

  public async getUniversityStatistics(): Promise<AccountUniversityStatisticsModel> {
    return await this.requestService
      .request({
        url: `${this.url}`
      })
      .then(toKeyModel(AccountUniversityStatisticsModel, messages.errors.get, 'result'))
  }
}

export default AccountUniversityStatisticsService
