
import { Component, Vue } from 'nuxt-property-decorator'
import BaseYavagroToast from '~/components/Notifications/BaseYavagroToast.vue'

@Component({
  components: {
    BaseYavagroToast
  }
})
export default class BaseYavagroToastWrapper extends Vue {
  get notifications() {
    return this.$tstore.notification.Notifications
  }

  removeNotification() {
    this.$tstore.notification.removeNotifications()
  }
}
