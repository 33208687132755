import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class LandingTextBlockModel extends BaseModel {
  name: 'text_block' = 'text_block'
  title: string = ''
  text: string = ''
  'background_image': string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }

    if (this.background_image) {
      this.background_image = new ImageService().cropImage(this.background_image, {})
    }
  }
}
