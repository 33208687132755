import { MetadataService } from '.'
import { ApiService } from '~/shared/api'
import { RequestService } from '~/services/RequestService'

// eslint-disable-next-line no-redeclare
class PagesService extends ApiService {
  protected route = 'pages'
  public readonly metaDataService: MetadataService

  constructor(requestService: RequestService) {
    super(requestService)
    this.metaDataService = new MetadataService(requestService)
  }
}

export default PagesService
