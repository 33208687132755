import { AxiosResponse } from 'axios'
import { ApiService } from '~/shared/api'

// eslint-disable-next-line no-redeclare
class LandingFormService extends ApiService {
  public async sendForm<T>(form: any, slug: string): Promise<T> {
    const { status }: any = await this.requestService.request<AxiosResponse<T>>({
      data: form,
      method: 'POST',
      url: `/api/v1/yavagro/landings/${slug}/send`
    })
    return status
  }
}

export default LandingFormService
