
import { computed, defineComponent, PropType, useContext, useMeta } from '@nuxtjs/composition-api'
import type { ItemList, WithContext } from 'schema-dts'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { BreadcrumbModel } from '~/model/BreadCrumbs'
import { makeJsonLdHeader } from '~/shared/utils/seo'

export default defineComponent({
  name: 'BreadCrumbs',
  components: {
    SvoiIcon
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<Array<BreadcrumbModel>>,
      required: false,
      default: undefined
    }
  },
  setup(props) {
    const context = useContext()

    const breadcrumbsList = computed(() => props.breadcrumbs ?? context.$tstore.pageMetadata.getBreadcrumbs)
    const count = computed(() => breadcrumbsList.value.length)
    const getFullUrl = (url: string) => `${context.$config.hosts.externalHost}${url}`

    const jsonld = (): WithContext<ItemList> => {
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbsList.value.map((i, idx) => ({
          '@type': 'ListItem',
          position: idx + 1,
          item: {
            '@id': getFullUrl(i.url),
            name: i.title
          }
        }))
      }
    }

    useMeta(makeJsonLdHeader(jsonld()))

    return { breadcrumbsList, count, getFullUrl }
  },
  head: {}
})
