import { QuestionModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class TestModel extends BaseModel {
  public id: number = 0
  public name: string = ''
  public slug: string = ''
  public questions: Map<number, QuestionModel> = new Map()

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.testing_questions)) {
        data.testing_questions.forEach(i => this.questions.set(Number(i.id), new QuestionModel(i)))
      }
    }
  }
}
