import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { PartnerModel } from '~/model'

@Module({ namespaced: true, stateFactory: true, name: 'partner' })
export class PartnerModule extends VuexModule {
  partnerList: PartnerModel[] = []
  partnerItem: PartnerModel = new PartnerModel()

  @Mutation
  private mutationSetPartnerList(partnerList: PartnerModel[]): void {
    this.partnerList = partnerList
  }

  @Action
  public setPartnerList(partnerList: PartnerModel[]): void {
    this.context.commit('mutationSetPartnerList', partnerList)
  }

  get PartnerList(): PartnerModel[] {
    return this.partnerList
  }
}
