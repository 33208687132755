import { RequestService } from '~/services/RequestService'

export class EcosystemService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    popular: '/api/v1/yavagro/menu/popular',
    platform: '/api/v1/yavagro/menu/platform'
  }

  public async getOtherServicesMenuList<T>(): Promise<T> {
    const url = `${this.urls.platform}`
    const res: any = await this.requestService.request<T>({
      url
    })
    return res.data.data
  }

  public async getPopularServicesMenuList<T>(): Promise<T> {
    const url = `${this.urls.popular}`
    const res: any = await this.requestService.request<T>({
      url
    })
    return res.data.data
  }
}
