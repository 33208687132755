import { RequestService } from '~/services/RequestService'

export class MapService {
  constructor(private requestService: RequestService) {}

  // readonly urls = {
  //   tagcloud: '/api/tagcloud'
  // };

  public getRegions<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.tagcloud}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve([
        {
          id: '1',
          name: 'Республика Карелия',
          subtitle: 'Республика Карелия',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '2',
          name: 'Кемеровская область',
          subtitle: 'Кемеровская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '3',
          name: 'Хабаровский край',
          subtitle: 'Хабаровский край',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '4',
          name: 'Республика Хакасия',
          subtitle: 'Республика Хакасия',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '5',
          name: 'Ханты-мансийский автономный округ - Югра',
          subtitle: 'Ханты-мансийский автономный округ - Югра',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '6',
          name: 'Кировская область',
          subtitle: 'Кировская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '7',
          name: 'Республика Коми',
          subtitle: 'Республика Коми',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '8',
          name: 'Костромская область',
          subtitle: 'Костромская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '9',
          name: 'Краснодарский край',
          subtitle: 'Краснодарский край',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '10',
          name: 'Республика Адыгея',
          subtitle: 'Республика Адыгея',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '11',
          name: 'Алтайский край',
          subtitle: 'Алтайский край',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '12',
          name: 'Амурская область',
          subtitle: 'Амурская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '13',
          name: 'Архангельская область',
          subtitle: 'Архангельская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '14',
          name: 'Астраханская область',
          subtitle: 'Астраханская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '15',
          name: 'Республика Башкортостан',
          subtitle: 'Республика Башкортостан',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '16',
          name: 'Белгородская область',
          subtitle: 'Белгородская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '17',
          name: 'Брянская область',
          subtitle: 'Брянская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '18',
          name: 'Республика Бурятия',
          subtitle: 'Республика Бурятия',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '19',
          name: 'Чеченская Республика',
          subtitle: 'Чеченская Республика',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '20',
          name: 'Челябинская область',
          subtitle: 'Челябинская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '21',
          name: 'Чукотский автономный округ',
          subtitle: 'Чукотский автономный округ',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '22',
          name: 'Чувашская республика',
          subtitle: 'Чувашская республика',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '23',
          name: 'г. Санкт-Петербург',
          subtitle: 'г. Санкт-Петербург',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '24',
          name: 'Республика Дагестан',
          subtitle: 'Республика Дагестан',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '25',
          name: 'Республика Алтай',
          subtitle: 'Алтайский Алтай',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '26',
          name: 'Республика Ингушетия',
          subtitle: 'Республика Ингушетия',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '27',
          name: 'Ивановская область',
          subtitle: 'Ивановская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '28',
          name: 'Кабардино-балканская республика',
          subtitle: 'Кабардино-балканская республика',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '29',
          name: 'Калининградская область',
          subtitle: 'Калининградская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '30',
          name: 'Республика Калмыкия',
          subtitle: 'Республика Калмыкия',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '31',
          name: 'Калужская область',
          subtitle: 'Калужская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '32',
          name: 'Курганская область',
          subtitle: 'Курганская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '33',
          name: 'Курская область',
          subtitle: 'Курская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '34',
          name: 'Ленинградская область',
          subtitle: 'Ленинградская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '35',
          name: 'Липецкая область',
          subtitle: 'Липецкая область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '36',
          name: 'Магаданская область',
          subtitle: 'Магаданская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '37',
          name: 'Республика Марий Эл',
          subtitle: 'Республика Марий Эл',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '38',
          name: 'Республика Мордовия',
          subtitle: 'Республика Мордовия',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '39',
          name: 'г. Москва',
          subtitle: 'г. Москва',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '40',
          name: 'Московская область',
          subtitle: 'Московская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '41',
          name: 'Мурманская область',
          subtitle: 'Мурманская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '42',
          name: 'Ненецкий автономный округ',
          subtitle: 'Ненецкий автономный округ',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '43',
          name: 'Нижегородская область',
          subtitle: 'Нижегородская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '44',
          name: 'Республика Сервеная Осетия - Алания',
          subtitle: 'Республика Сервеная Осетия - Алания',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '45',
          name: 'Новгородская область',
          subtitle: 'Новгородская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '46',
          name: 'Новосибирская область',
          subtitle: 'Новосибирская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '47',
          name: 'Омская область',
          subtitle: 'Омская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '48',
          name: 'Орловская область',
          subtitle: 'Орловская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '49',
          name: 'Оренбургская область',
          subtitle: 'Оренбургская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '50',
          name: 'Пензенская область',
          subtitle: 'Пензенская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '51',
          name: 'Приморский край',
          subtitle: 'Приморский край',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '52',
          name: 'Псковская область',
          subtitle: 'Псковская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '53',
          name: 'Ростовская область',
          subtitle: 'Ростовская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '54',
          name: 'Рязанская область',
          subtitle: 'Рязанская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '55',
          name: 'Республика Саха (Якутия)',
          subtitle: 'Республика Саха (Якутия)',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '56',
          name: 'Сахалинская область',
          subtitle: 'Сахалинская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '57',
          name: 'Самарская область',
          subtitle: 'Самарская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '58',
          name: 'Саратовская область',
          subtitle: 'Саратовская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '59',
          name: 'Смоленская область',
          subtitle: 'Смоленская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '60',
          name: 'Ставропольский край',
          subtitle: 'Ставропольский край',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '61',
          name: 'Свердловская область',
          subtitle: 'Свердловская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '62',
          name: 'Тамбовская область',
          subtitle: 'Тамбовская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '63',
          name: 'Республика Татарстан',
          subtitle: 'Республика Татарстан',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '64',
          name: 'Томская область',
          subtitle: 'Томская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '65',
          name: 'Тульская область',
          subtitle: 'Тульская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '66',
          name: 'Республика Тыва',
          subtitle: 'Республика Тыва',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '67',
          name: 'Тверская область',
          subtitle: 'Тверская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '68',
          name: 'Тюменская область',
          subtitle: 'Тюменская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '69',
          name: 'Республика Удмуртия',
          subtitle: 'Республика Удмуртия',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '70',
          name: 'Ульяновская область',
          subtitle: 'Ульяновская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '71',
          name: 'Владимирская область',
          subtitle: 'Владимирская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '72',
          name: 'Волгоградская область',
          subtitle: 'Волгоградская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '73',
          name: 'Вологодская область',
          subtitle: 'Вологодская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '74',
          name: 'Воронежская область',
          subtitle: 'Воронежская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '75',
          name: 'Ямало-ненецкий автономный округ',
          subtitle: 'Ямало-ненецкий автономный округ',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '76',
          name: 'Ярославская область',
          subtitle: 'Ярославская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '77',
          name: 'Еврейская автономная область',
          subtitle: 'Еврейская автономная область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '78',
          name: 'г. Севастополь',
          subtitle: 'г. Севастополь',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '79',
          name: 'Республика Крым',
          subtitle: 'Республика Крым',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '80',
          name: 'Красноярский край',
          subtitle: 'Красноярский край',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '81',
          name: 'Иркутская область',
          subtitle: 'Иркутская область',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '82',
          name: 'Забайкальский край',
          subtitle: 'Забайкальский край',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '83',
          name: 'Камчатский край',
          subtitle: 'Камчатский край',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '84',
          name: 'Пермский край',
          subtitle: 'Пермский край',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        },
        {
          id: '85',
          name: 'Карачаево-Черкесская Республика',
          subtitle: 'Карачаево-Черкесская Республика',
          address: 'Красноярск, ул. Дубровинского д. 1И, пом. 1',
          phone: '+7 (3912) 57-37-97',
          email: 'technopark24@bk.ru',
          url: 'https://vk.com/kvantorium24'
        }
      ])
    })
  }
}
