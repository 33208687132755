import type { MetaInfo } from 'vue-meta'

export default class Seo {
  public static getHeaderMeta(page: any): MetaInfo {
    const categories = ['news', 'books', 'universities']
    const title = page.seo_title
    const meta = [
      {
        hid: 'description',
        name: 'description',
        content: page.seo_description
      },
      {
        property: 'og:title',
        content: page.seo_title
      },
      {
        property: 'og:description',
        content: page.seo_description
      },
      {
        property: 'og:type',
        content: page.page_type === 'news' ? 'article' : 'website'
      },
      {
        property: 'og:url',
        content: page.url
      }
    ]

    if (categories.includes(page.page_type)) {
      meta.push({ property: 'og:image', content: page.image })
    }
    return {
      title,
      meta
    }
  }
}
