
import Seo from '~/utils/seo.ts'
import ModalAuth from '~/components/Modal/ModalAuth.vue'

export default {
  components: {
    ModalAuth
  },
  head() {
    return Seo.getHeaderMeta(this.$tstore.pageMetadata.getSeoMetadata)
  }
}
