/* eslint-disable camelcase */
import { Privot } from '../Misc/PrivotModel'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export class FutureProfItemModel {
  id: string = ''
  name?: string = ''
  vacancy?: string = ''
  study?: string = ''
  backgroundColor?: string = ''
  iconPath?: string = ''
  horizontalLayout?: boolean = false
  href?: string = ''
}

export class ProfessionModel {
  courses_block_name: string = ''
  created_at: string = ''
  description: string = ''
  icon: string = ''
  id: number = -1
  image: string = ''
  is_published: string = ''
  name: string = ''
  news_block_name: string = ''
  pivot: Privot | null = null
  slug: string = ''
  updated_at: string = ''
}

export class ProfessionTextBlockModel extends BaseModel {
  created_at: string = ''
  description: string = ''
  id: number = 0
  image_big: string = ''
  image_small: string = ''
  is_image_big_right: number = 0
  name: string = ''
  profession_id: number = 0
  updated_at: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
