import { RequestService } from '~/services/RequestService'

export default class StudentActivitiesQuantityService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    studentActivitiesQuantity: '/api/v1/yavagro/digitalprofiles/quantity'
  }

  public async getStudentActivitiesQuantity<T>(query: object): Promise<T> {
    const q = Object.keys(query)
      .map(key => `${key}=${encodeURI(query[key])}`)
      .join('&')

    const res: any = await this.requestService.request<T>({
      url: `${this.urls.studentActivitiesQuantity}?${q}`
    })

    return res.data.data
  }
}
