import { RecommendationModel, PersonalityCountsModel, PersonalityTypeModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class ResultModel extends BaseModel {
  public hash: string = ''
  public hashUrl: string = ''
  public scores: number = 0
  public recommendation: RecommendationModel = new RecommendationModel(null)
  public personalityCounts: PersonalityCountsModel = new PersonalityCountsModel(null)
  public personalityType: PersonalityTypeModel = new PersonalityTypeModel(null)

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (isUnknownObject(data.testing_result_recommendation)) {
        this.recommendation = new RecommendationModel(data.testing_result_recommendation)
      }

      if (isUnknownObject(data.testing_answer_type)) {
        this.personalityType = new PersonalityTypeModel(data.testing_answer_type)
      }

      if (
        isUnknownObject(data.testing_answer_types_counts) &&
        isUnknownObject(data.testing_answer_types_counts.items)
      ) {
        this.personalityCounts = new PersonalityCountsModel(data.testing_answer_types_counts)
      }
    }
  }
}
