/* eslint-disable camelcase */
import { NamedItemModel } from '~/model'

export class NewsItemModel {
  id: string = ''
  slug: string = ''
  name: string = ''
  image: string = ''
  labels: NamedItemModel[] = []
  views: number = 0
  likes: number = 0
  time: string = ''
  type: string = ''
  content: string = ''
  updated_at: string = ''
  dislike?: boolean = false
  like?: boolean = false
  author_name?: string = ''
  created_at?: string = ''
  autoposting_teaser?: string = ''
}
