
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

export default {
  name: 'Footer',
  components: {
    AppLazyImage
  },
  props: {
    contactsMenuShow: {
      type: Boolean,
      default: true
    },
    contactsEmailShow: {
      type: Boolean,
      default: true
    },
    bottomFirstLineShow: {
      type: Boolean,
      default: true
    },
    bottomSecondLineShow: {
      type: Boolean,
      default: true
    },
    contactsFeedbackShow: {
      type: Boolean,
      default: true
    },
    socialShow: {
      type: Boolean,
      default: true
    },
    topImagePath: {
      type: String,
      default: ''
    },
    contactsMenuTitle: {
      type: String,
      default: 'По всем вопросам пишите'
    },
    contactsEmail: {
      type: String,
      default: 'svoevagro-help@rshb.ru'
    },
    rshbFooterLogoPath: {
      type: String,
      default: ''
    },
    rshbFooterLogoAlt: {
      type: String,
      default: 'Россельхозбанк'
    },
    copyrightText: {
      type: String,
      default: ''
    },
    footerContactsBottomAdress: {
      type: String,
      default: ''
    },
    footerContactsBottomEmail: {
      type: String,
      default: ''
    },
    social: {
      type: Array,
      default: () => []
    },
    firstMenus: {
      type: Array,
      default: () => []
    },
    secondMenus: {
      type: Array,
      default: () => []
    },
    bottomMenu: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    topFooterBackground() {
      if (!this.topImagePath) {
        return {}
      }
      return {
        'background-image': `url(${this.topImagePath}) !important`,
        'background-repeat': 'no-repeat !important',
        'background-position': 'center bottom !important'
      }
    }
  },
  methods: {
    setSocialIcon(icon) {
      if (!icon) {
        return {}
      }
      return {
        'background-image': `url(${icon}) !important`,
        'background-repeat': 'no-repeat !important',
        'background-position': 'center center !important'
      }
    },
    onClickFeedback() {
      this.$emit('on-click-feedback')
    }
  }
}
