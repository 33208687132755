import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { QuantumCardModel } from '~/model/Cards/QuantumCardModel'

@Module({ namespaced: true, stateFactory: true, name: 'quantums' })
export class QuantumModule extends VuexModule {
  private internalQuantumList: Array<QuantumCardModel> = []
  private internalQuantumItem: QuantumCardModel = new QuantumCardModel()

  @Mutation
  private mutationSetQuantumList(quantums: QuantumCardModel[]): void {
    this.internalQuantumList = quantums
  }

  @Action
  public setQuantumList(quantumList: QuantumCardModel[]): void {
    this.context.commit('mutationSetQuantumList', quantumList)
  }

  get quantumList(): QuantumCardModel[] {
    return this.internalQuantumList
  }

  @Mutation
  private mutationSetQuantumItem(quantumItem: QuantumCardModel): void {
    this.internalQuantumItem = quantumItem
  }

  @Action
  public setQuantumItem(quantumItem: QuantumCardModel): void {
    this.context.commit('mutationSetQuantumItem', quantumItem)
  }

  get quantumItem(): QuantumCardModel {
    return this.internalQuantumItem
  }
}
