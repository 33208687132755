import { checkedRule, emailRule, requiredRule } from '~/shared/utils/validation'
import { Form } from '~/shared/api'
import type { UserModel } from '~/model/User'

export default class ExecutiveSearchForm extends Form {
  name: string = ''
  email: string = ''
  contactName: string = ''
  message: string = ''
  isAgree: boolean = false
  specializationsData: Array<{ id: string; name: string }> = []
  specializations: Array<string> = []

  constructor(user: UserModel) {
    super()

    this.ignoreKeys.push('specializationsData')

    this.fillForm(user)
  }

  protected beforeSerialize() {
    this.specializations = this.specializationsData.map(({ name }) => name)
  }

  fillForm(user: UserModel) {
    this.contactName = user.name
    this.email = user.email
  }

  clear() {
    this.name = ''
    this.email = ''
    this.contactName = ''
    this.message = ''
    this.isAgree = false
    this.specializationsData = []
  }

  static rules() {
    return {
      name: requiredRule,
      email: { ...requiredRule, ...emailRule },
      contactName: requiredRule,
      message: [],
      isAgree: checkedRule,
      specializationsData: requiredRule
    }
  }
}
