// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function (to, from, savedPosition) {
  if (to.hash === '#info_documents') {
    return false
  }

  if (to.hash === '#karernyy-marafon-2021' || to.hash === '#karernyy-marafon-2022') {
    return false
  }

  if (to.hash) {
    return { selector: to.hash }
  } else if (to.params && to.params.size) {
    return true
  } else if (savedPosition) {
    return savedPosition
  } else if (to.path === from.path && (!to.query.page || to.query.page === from.query.page)) {
    return true
  } else {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  }
}
