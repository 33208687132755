import { emailRule, requiredRule, phoneRule } from '~/shared/utils/validation'
import { Form } from '~/shared/api'
import type { UserModel } from '~/model/User'

export default class ApplicationPageCreationForm extends Form {
  name: string = ''
  fio: string = ''
  email: string = ''
  phone: string = ''
  file: Array<File> | File = []
  _invalid: boolean = true

  constructor(user: UserModel) {
    super()

    this.ignoreKeys.push('_invalid')

    this.fillForm(user)
  }

  fillForm(user: UserModel) {
    this.fio = user.name
    this.email = user.email
  }

  clear() {
    this.name = ''
    this.email = ''
    this.fio = ''
    this.phone = ''
    this.file = []
    this._invalid = true
  }

  static rules() {
    return {
      name: requiredRule,
      email: emailRule,
      fio: requiredRule,
      phone: { ...requiredRule, ...phoneRule },
      file: []
    }
  }
}
