import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class AccountUniversityStatistics extends BaseModel {
  'total_profiles': number = 0
  'profiles_last_30_days': number = 0
  'profiles_today': number = 0
  export: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
