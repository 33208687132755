import { RequestService } from '~/services/RequestService'

export class GrantsService {
  constructor(private requestService: RequestService) {}

  // readonly urls = {
  //   grants: '/api/grants'
  // };

  public getGrants<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.grants}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve([
        {
          id: 'gr67671',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 1',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        },
        {
          id: 'gr67672',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 2',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        },
        {
          id: 'gr67673',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 3',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        },
        {
          id: 'gr67674',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 4',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        },
        {
          id: 'gr67675',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 5',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        },
        {
          id: 'gr67676',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 6',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        },
        {
          id: 'gr67677',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 7',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        },
        {
          id: 'gr67678',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 8',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        },
        {
          id: 'gr67679',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 9',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        },
        {
          id: 'gr67680',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 10',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        },
        {
          id: 'gr67681',
          image: '/images/grants/grant-fsi.jpg',
          name: 'Фонд содействия инновациям 11',
          description: 'Программа Умник',
          targetLabel: 'Для кого',
          targetGroup: 'молодые ученые в возрасте 18-30 лет'
        }
      ])
    })
  }
}
