import { Middleware, Context } from '@nuxt/types'

const vacanciesRegion: Middleware = ({ route, $tstore, redirect, $services }: Context) => {
  if (route.name === 'vacancies-group') {
    const region = $tstore.regionLocation.RegionLocationList.find(item => item.slug === route.params.group)
    const cookieRegion = $services.geolocation.getCookieRegion()

    if (!route.path.includes('region-') && !region && cookieRegion && cookieRegion.slug && process.server) {
      redirect(`${route.path}/${cookieRegion.slug}`)
    }

    if (!region && !cookieRegion && process.server) {
      redirect(`${route.path}/russia`)
    }
  }
}

export default vacanciesRegion
