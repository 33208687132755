import { ProfessionModel, DirectionModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'
import { CourseModel } from '~/model/Course'
import { NewsModel } from '~/model/News'
import { VacancyModel } from '~/model/Vacancy'

export default class RecommendationModel extends BaseModel {
  public id: number = 0
  public name: string = ''
  public description: string = ''
  public testingAnswerType: string = ''
  public image: string = ''
  public professions: Array<ProfessionModel> = []
  public directions: Array<DirectionModel> = []
  public courses: Array<CourseModel> = []
  public news: Array<NewsModel> = []
  public vacancies: Array<VacancyModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (isUnknownObject(data.recommendations)) {
        if (Array.isArray(data.recommendations.professions)) {
          this.professions = data.recommendations.professions.map(i => new ProfessionModel(i))
        }
        if (Array.isArray(data.recommendations.streams)) {
          this.directions = data.recommendations.streams.map(i => new DirectionModel(i))
        }
        if (Array.isArray(data.recommendations.courses)) {
          this.courses = data.recommendations.courses.map(i => new CourseModel(i))
        }
        if (Array.isArray(data.recommendations.news)) {
          this.news = data.recommendations.news.map(i => new NewsModel(i))
        }
        if (Array.isArray(data.recommendations.vacancies)) {
          this.vacancies = data.recommendations.vacancies.map(i => new VacancyModel(i))
        }
      }
    }
  }
}
