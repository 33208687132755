import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { ConferencesItemModel } from '~/model'

@Module({ namespaced: true, stateFactory: true, name: 'conferences' })
export class ConferencesModule extends VuexModule {
  private internalConferencesList: Array<ConferencesItemModel> = []
  private internalConferencesItem: ConferencesItemModel = new ConferencesItemModel()

  @Mutation
  private mutationSetConferencesList(news: ConferencesItemModel[]): void {
    this.internalConferencesList = news
  }

  @Action
  public setConferencesList(conferencesList: ConferencesItemModel[]): void {
    this.context.commit('mutationSetConferencesList', conferencesList)
  }

  @Mutation
  private mutationSetConferencesItem(conferencesItem: ConferencesItemModel): void {
    this.internalConferencesItem = conferencesItem
  }

  @Action
  public setConferencesItem(conferencesItem: ConferencesItemModel): void {
    this.context.commit('mutationSetConferencesItem', conferencesItem)
  }

  get conferencesItem(): ConferencesItemModel {
    return this.internalConferencesItem
  }

  get conferencesList(): ConferencesItemModel[] {
    return this.internalConferencesList
  }
}
