import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { UniveristyDirection } from '~/model/AmbassadorsModel'
import { NewsItemModel } from '~/model'

@Module({ namespaced: true, stateFactory: true, name: 'ambassadors' })
export class AmbassadorsModule extends VuexModule {
  universityDirections: UniveristyDirection[] = []
  createContent: NewsItemModel[] = []

  @Mutation
  private mutationSetUniversityDirections(universityDirections: UniveristyDirection[]): void {
    this.universityDirections = universityDirections
  }

  @Mutation
  private mutationSetCreateContent(createContent: NewsItemModel[]): void {
    this.createContent = createContent
  }

  @Action
  public setUniversityDirections(universityDirections: UniveristyDirection[]): void {
    this.context.commit('mutationSetUniversityDirections', universityDirections)
  }

  @Action
  public setCreateContent(createContent: NewsItemModel[]): void {
    this.context.commit('mutationSetCreateContent', createContent)
  }

  get getUniversityDirections(): UniveristyDirection[] {
    return this.universityDirections
  }

  get getCreateContent(): NewsItemModel[] {
    return this.createContent
  }
}
