import { UniversityModel } from '~/model/University'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class DirectionModel extends BaseModel {
  public name: string = ''
  public university: UniversityModel = new UniversityModel(null)

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (isUnknownObject(data.university)) {
        this.university = new UniversityModel(data.university)
      }
    }
  }
}
