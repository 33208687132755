/* eslint-disable camelcase */
interface Industry {
  id: string
  name?: string
  description?: string
  image?: string
  background?: string
  icon?: string
  is_published?: number
  slug?: string
  pivot: any[]
}

interface Profession {
  id: string
  slug: string
  name?: string
  icon?: string
  image?: string
  description?: string
  is_published?: number
  news_block_name?: string
  courses_block_name?: string
  created_at?: string
  updated_at?: string
  pivot: any[]
}

export enum EducationForm {
  'Дистанционно',
  'Очно',
  'Смешанная'
}

export enum SeoEducationForm {
  'Online',
  'Onsite',
  'Blended'
}

export interface ICourseInfoBlock {
  icon: string
  price: boolean
  name: string
  value: string
  sale?: string | boolean
}

export interface Partner {
  id?: number
  name?: string
  logo?: string
  description?: string
  site?: string
  cooperation_terms?: string
  slug?: string
  created_at?: string
  updated_at?: string
  deleted_at?: any
  coupon_discount_type?: number
  coupon_discount?: number
  coupon_description?: string
  coupon_mail_text?: string
}

export interface PartnerCoupon {
  id?: number
  name?: string
  description?: string
  mail_text?: string
  slug?: string
  deleted_at?: any
  discount_type?: number
  discount?: number
  coupon_mail_text?: string
}

export class CourseItemModel {
  id: string = ''
  name?: string = ''
  color?: string = ''
  description?: string = ''
  slug: string = ''
  is_published: number = 0
  image?: string = ''
  issued_document?: string = ''
  background?: string = ''
  link?: string = ''
  education_form: number = 0
  price?: number = 0
  price_with_discount?: number = 0
  price_with_discount_item?: number = 0
  course_duration_num?: number = 0
  discount_in_percent?: number = 0
  discount_in_rubles?: number = 0
  duration?: number = 0
  course_duration_text: string = ''
  type?: string = ''
  email?: string = ''
  created_at?: string = ''
  updated_at?: string = ''
  tags?: any[] = []
  partner_tags?: any[] = []
  industry?: Industry[] = []
  profession?: Profession[] = []
  partner_item?: Partner = {}
  partner_coupon?: PartnerCoupon = {}
  partner_item_coupon?: PartnerCoupon = {}
  partner?: number = 0
  partner_id?: number = 0
  sale_price?: number = 0
  coupon?: string = ''
  course_duration?: string = ''
  infoBlocks?: ICourseInfoBlock[] = []
  labels?: any[] = []
  saleLabels?: any[] = []
}
