import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { ProfessionModel } from '~/model'

@Module({ namespaced: true, stateFactory: true, name: 'professions' })
export class ProfessionsModule extends VuexModule {
  private internalProfessionsList: Array<ProfessionModel> = []
  private internalProfessionsCard: ProfessionModel = new ProfessionModel()

  private initialProfessionSlider: number = 1

  @Mutation
  private mutationSetProfessionsList(professions: ProfessionModel[]): void {
    this.internalProfessionsList = professions
  }

  @Action
  public setProfessionsList(professionsList: ProfessionModel[]): void {
    this.context.commit('mutationSetProfessionsList', professionsList)
  }

  get professionsList(): ProfessionModel[] {
    return this.internalProfessionsList
  }

  @Mutation
  private mutationSetProfessionsCard(professionsCard: ProfessionModel): void {
    this.internalProfessionsCard = professionsCard
  }

  @Action
  public setProfessionsCard(professionsCard: ProfessionModel): void {
    this.context.commit('mutationSetProfessionsCard', professionsCard)
  }

  @Mutation
  private mutationSetInitialSlider(initialSlider: number): void {
    this.initialProfessionSlider = initialSlider
  }

  @Action
  public setProfessionsInitialSlider(initialSlider: number): void {
    this.context.commit('mutationSetInitialSlider', initialSlider)
  }

  get ProfessionsCard(): ProfessionModel {
    return this.internalProfessionsCard
  }

  get ProfessionalInitialSlider(): Number {
    return this.initialProfessionSlider
  }
}
