import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { CompetitionsItemModel } from './../../../model/Cards/CompetitionsItemModel'
import { IVacanciesItem } from '~/model/VacanciesModel'
import { IPosterItemModel } from '~/model/AfishaModel'
import { IDirectionInternshipsModel } from '~/model'

@Module({
  namespaced: true,
  stateFactory: true,
  name: 'students'
})
export class StudentsModule extends VuexModule {
  private posterList: Array<IPosterItemModel> = []
  private internshipList: Array<IVacanciesItem> = []
  private directionInternships: Array<IDirectionInternshipsModel> = []
  private competitions: Array<CompetitionsItemModel> = []

  @Mutation
  private mutationSetPosterList(posterList: any[]): void {
    this.posterList = posterList
  }

  @Mutation
  private mutationSetInternshipList(internshipList: IVacanciesItem[]): void {
    this.internshipList = internshipList
  }

  @Mutation
  private mutationSetDirectionInternships(directionInternships: IDirectionInternshipsModel[]): void {
    this.directionInternships = directionInternships
  }

  @Mutation
  private mutationSetCompetitions(competitions: CompetitionsItemModel[]): void {
    this.competitions = competitions
  }

  @Action
  public setPosterList(posterList: any[]): void {
    this.context.commit('mutationSetPosterList', posterList)
  }

  @Action
  public setInternshipList(internshipList: IVacanciesItem[]): void {
    this.context.commit('mutationSetInternshipList', internshipList)
  }

  @Action
  public setDirectionInternships(directionInternships: IDirectionInternshipsModel[]): void {
    this.context.commit('mutationSetDirectionInternships', directionInternships)
  }

  @Action
  public setCompetitions(competitions: CompetitionsItemModel[]): void {
    this.context.commit('mutationSetCompetitions', competitions)
  }

  get PosterList(): any[] {
    return this.posterList
  }

  get InternshipList(): any[] {
    return this.internshipList
  }

  get DirectionInternships(): IDirectionInternshipsModel[] {
    return this.directionInternships
  }

  get Competitions(): CompetitionsItemModel[] {
    return this.competitions
  }
}
