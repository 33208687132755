/* eslint-disable camelcase */
interface IPageBlocks {
  infinite_line_items_filter?: number[]
  news_filter?: number[]
  courses_filter?: number[]
  conferences_filter?: number[]
}

export class Page {
  id: number = 0
  title: string = ''
  path: string = ''
  seo_title: string = 'Учись, живи, работай в агро'
  seo_description: string =
    'Мы собрали профессии, курсы, стажировки и учебные заведения — всё, что поможет вам понять своё предназначение, найти подходящую отрасль и дело, которое вдохновляет'

  page_type: string = ''
  seo_keywords: string = ''
  seo_h1: string = ''
  blocks: IPageBlocks | null = null
  image: string = ''
  url: string = ''
}
