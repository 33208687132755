
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SearchWithButton',
  props: {
    value: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'Найти'
    },
    placeholder: {
      type: String,
      default: 'Поиск по разделу'
    }
  },
  emits: ['on-search'],
  setup(_, { emit }) {
    const onSearch = () => {
      emit('on-search')
    }

    return {
      onSearch
    }
  }
})
