import { AvailableAnswerModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class QuestionModel extends BaseModel {
  public id: number = 0
  public name: string = ''
  public slug: string = ''
  public image: string = ''
  public isMultiplyAnswers: boolean = false
  public sortIndex: number = 0
  public answers: Array<AvailableAnswerModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.testing_answers)) {
        this.answers = data.testing_answers.map(i => new AvailableAnswerModel(i))
      }
    }
  }
}
