import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, stateFactory: true, name: 'pageHistory' })
export class PageHistoryModule extends VuexModule {
  private pageHistoryNumber: number = 0
  private latestPage: string = ''

  @Mutation
  private mutationSetPageHistoryNumber(number: number): void {
    this.pageHistoryNumber = number
  }

  @Action
  public setPageHistoryNumber(number: number): void {
    this.context.commit('mutationSetPageHistoryNumber', number)
  }

  @Mutation
  private mutationSetLatestPage(page: string): void {
    this.latestPage = page
  }

  @Action
  public setLatestPage(page: string): void {
    this.context.commit('mutationSetLatestPage', page)
  }

  @Action
  public goBack(router): string {
    if (!this.latestPage) {
      return router.push('/')
    } else {
      return router.go(-1)
    }
  }

  @Action
  public goMain(router): string {
    return router.push('/')
  }

  get getPageHistoryNumber(): number {
    return this.pageHistoryNumber
  }

  get getLatestPage(): string {
    return this.latestPage
  }
}
