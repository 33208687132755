import { ApiService } from '~/shared/api'
import { restCreate, sendForm } from '~/shared/utils/decorators'
import { ServiceCanSendForm, ServiceRestCreate } from '~/shared/interfaces'

interface OlympiadService extends ServiceCanSendForm, ServiceRestCreate {}

@sendForm
@restCreate
// eslint-disable-next-line no-redeclare
class OlympiadService extends ApiService {
  protected route: string = 'olympiadentry/send'
}

export default OlympiadService
