const standsDomain = 'sz.rshbcloud.ru'
const prodDomain = 'https://svoevagro.ru/'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ENVIRONMENT_STANDS = {
  dev: `https://svoevagro-dev.${standsDomain}`,
  test: `https://svoevagro-test.${standsDomain}`,
  preprod: `https://svoevagro-preprod.${standsDomain}`,
  prod: prodDomain
}
