import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { LandingAdvantagesItemBlockModel } from '~/model/Landings/index'

export default class LandingAdvantagesBlock extends BaseModel {
  name: 'advantages_block' = 'advantages_block'
  title: string = ''
  text: string = ''
  items: Array<LandingAdvantagesItemBlockModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.items)) {
        this.items = data.items.map(i => new LandingAdvantagesItemBlockModel(i))
      }
    }
  }
}
