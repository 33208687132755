import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { UniversityDirectionItemModel } from '~/model/Cards/UniversityDirectionItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'universityDirection' })
export class UniversityDirectionModule extends VuexModule {
  private universityDirectionList: Array<UniversityDirectionItemModel> = []
  private universityDirectionItem: UniversityDirectionItemModel = new UniversityDirectionItemModel()

  @Mutation
  private mutationSetUniversityDirectionList(universityDirectionList: UniversityDirectionItemModel[]): void {
    this.universityDirectionList = universityDirectionList
  }

  @Mutation
  private mutationSetUniversityDirectionItem(universityDirectionItem: UniversityDirectionItemModel): void {
    this.universityDirectionItem = universityDirectionItem
  }

  @Action
  public setUniversityDirectionList(universityDirectionList: UniversityDirectionItemModel[]): void {
    this.context.commit('mutationSetDirectionList', universityDirectionList)
  }

  @Action
  public setUniversityDirectionItem(universityDirectionItem: UniversityDirectionItemModel): void {
    this.context.commit('mutationSetUniversityDirectionItem', universityDirectionItem)
  }

  @Action
  public setCountUniversityDirection(countUniversityDirection: number): void {
    this.context.commit('mutationSetCountUniversityDirection', countUniversityDirection)
  }

  get UniversityDirectionList(): UniversityDirectionItemModel[] {
    return this.universityDirectionList
  }

  get UniversityDirectionItem(): UniversityDirectionItemModel {
    return this.universityDirectionItem
  }
}
