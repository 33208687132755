import { UserModel } from '~/model/User'
import { emailRule, phoneRule, requiredRule } from '~/shared/utils/validation'
import { Form } from '~/shared/api'

export default class StudentActivitiesForm extends Form {
  private _user: UserModel
  name: string = ''
  phone: string = ''
  email: string = ''
  company: string = ''
  courses?: string[] = []
  regions?: number[] = []
  universities?: number[] = []
  specializations?: number[] = []
  faculties?: string[] = []
  _invalid: boolean = true

  constructor(user: UserModel) {
    super()
    this._user = user
    this.fillForm()
  }

  private fillForm() {
    this.name = this._user.name
    this.phone = this._user.phone
    this.email = this._user.email
    this._invalid = true
  }

  clear() {
    this.name = this._user.name || ''
    this.phone = this._user.phone || ''
    this.email = this._user.email || ''
    this.company = ''
    this._invalid = true
  }

  static rules() {
    return {
      name: requiredRule,
      company: requiredRule,
      phone: { ...requiredRule, ...phoneRule },
      email: emailRule
    }
  }
}
