/* eslint-disable camelcase */

export default class FilterSelectItem {
  name: string = ''
  id: string | number = ''
}

export interface Item {
  id: any
  name: string
  region_id?: number
  slug?: string
  value?: boolean | string
}

export class FilterFields {
  name?: string = ''
  type: 'checkbox' | 'multiselect' | 'selectedvalues' | 'search' | 'sort' | 'range' = 'multiselect'

  title: string = ''
  placeholder?: string = ''
  items?: Item[]
  includes?: any
  range_min?: number = 0
  range_max?: number = 100
  range_step?: number = 1
  value?: any
}
