import { RequestService } from '~/services/RequestService'

export class GrantsSortingService {
  constructor(private requestService: RequestService) {}

  // readonly urls = {
  //   grantsSorting: '/api/grants/sorting'
  // };

  public getGrantsSortingList<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.grantsSorting}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve([
        { id: '1', name: 'Сумма гранта' },
        { id: '2', name: 'Направление' },
        { id: '3', name: 'Предмет' },
        { id: '4', name: 'Дата начала' }
      ])
    })
  }
}
