import { ApiService } from '~/shared/api'
import { getList, restGetItem } from '~/shared/utils/decorators'
import type { ServiceHasList, ServiceRestGetItem } from '~/shared/interfaces'
import { EmployerModel } from '~/model/Employer'
import { isUnknownObject } from '~/shared/utils/guards'

interface EmployerService extends ServiceHasList, ServiceRestGetItem {}

@getList
@restGetItem
// eslint-disable-next-line no-redeclare
class EmployerService extends ApiService {
  protected route: string = 'employers'

  getItemModel(id: number | string): Promise<EmployerModel> {
    return this.getItem(id)
      .then(({ data }) => {
        const raw = isUnknownObject(data) && isUnknownObject(data.data) ? data.data : null
        return new EmployerModel(raw)
      })
      .catch(() => new EmployerModel(null))
  }
}

export default EmployerService
