import { RequestService } from '~/services/RequestService'

export class SchoolDirectionsService {
  constructor(private requestService: RequestService) {}

  // readonly urls = {
  //   schoolDirections: '/api/school/directions'
  // };

  public getSchoolDirectionsList<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.schoolDirections}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve([
        // {
        //   id: 'event1',
        //   name: 'Участвуй и выигрывай в конкурсах',
        //   background: '#FFCDA9',
        //   image: '/images/card-icons/statuette.png',
        //   href: '/olympiad/contest'
        // },
        // {
        //   id: 'event2',
        //   name: 'Пройди тест по профориентации',
        //   background: 'linear-gradient(130.5deg, #FDE2CF -28.36%, #FFFEDE 113.83%)',
        //   image: '/images/card-icons/backpack.png',
        //   href: '/proforientation'
        // },
        {
          id: 'event3',
          name: 'Участвуй и выигрывай в олимпиадах',
          background: 'linear-gradient(180deg, #CFFDD9 0%, #E1FFDE 100%)',
          image: '/images/card-icons/calendar.png',
          href: '/olympiad'
        },
        // {
        //   id: 'event4',
        //   name: 'Пройди тест по финансовой грамотности',
        //   background: '#FFECEC',
        //   image: '/images/card-icons/cash.png',
        //   href: '/schoolchildren/financial-literacy-test'
        // },
        {
          id: 'event5',
          name: 'Узнай свои карьерные траектории',
          background: 'linear-gradient(130.5deg, #CFCFFD -28.36%, #DEE3FF 113.83%)',
          image: '/images/card-icons/globus.png',
          href: '/career-navigator'
        },
        {
          id: 'event6',
          name: 'Учись на платформе Агроклассы',
          background: '#F2FA93',
          image: '/images/card-icons/lamp.png',
          href: 'https://agroclasses.svoevagro.ru/?utm_source=svoevagro'
        },
        {
          id: 'event7',
          name: 'Узнай, куда ты можешь поступить через калькулятор ЕГЭ',
          background: 'rgba(135,227,220,0.92)',
          image: '/images/card-icons/book.png',
          href: '/calculator-ege'
        },
        {
          id: 'event8',
          name: 'Выбери специальность в вузах России',
          background: '#F2FA93',
          image: '/images/card-icons/backpack.png',
          href: '/universities'
        },
        {
          id: 'event9',
          name: 'Выбери специальность в ссузах России',
          background: 'linear-gradient(130.5deg, #FDE2CF -28.36%, #FFFEDE 113.83%)',
          image: '/images/card-icons/column.png',
          href: '/colleges'
        },
        {
          id: 'event10',
          name: 'Найди свое призвание с помощью “Агровектора”',
          background: '#93fab0',
          image: '/images/card-icons/flask.png',
          href: '/news/agrovektor-ot-ucheby-k-uspekhu-uznayte-vse-o-rabote-v-apk-i-napryamuyu-poobshchaytes-s-predstavitelyami-biznesa-i-nauki'
        },
        {
          id: 'event11',
          name: 'Выбери лучшего работодателя',
          background: '#FFCDA9',
          image: '/images/card-icons/statuette.png',
          href: '/news/rabotayte-u-luchshikh-s-ya-v-agro-onlayn-ekskursii-oplachivaemye-stazhirovki-i-vakansii-ot-kompaniy-apk'
        }
      ])
    })
  }
}
