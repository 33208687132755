import { RequestService } from '~/services/RequestService'

export class GrantsFaqService {
  constructor(private requestService: RequestService) {}

  // readonly urls = {
  //   grantsFaq: '/api/grants/faq'
  // };
  public getGrantsFaqList<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.grantsFaq}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve([
        {
          id: 'gf234891',
          name: 'Что такое грант? Чем отличается от стипендии?',
          answer:
            'И гранты, и стипендии — виды безвозмездной финансовой помощи на оплату обучения. Получатель определяется на конкурсной основе. Разница между ними в том, что стипендию обычно выплачивают каждый месяц, а грант единоразово. Компенсируются они спонсорами-работодателями, НКО, фондами, вузами, правительствами, министерствами иностранных дел или образования, региональными властями.'
        },
        {
          id: 'gf234892',
          name: 'Какие основные этапы при получении гранта? Требования к участникам?',
          answer:
            'И гранты, и стипендии — виды безвозмездной финансовой помощи на оплату обучения. Получатель определяется на конкурсной основе. Разница между ними в том, что стипендию обычно выплачивают каждый месяц, а грант единоразово. Компенсируются они спонсорами-работодателями, НКО, фондами, вузами, правительствами, министерствами иностранных дел или образования, региональными властями.'
        },
        {
          id: 'gf234893',
          name: 'Какие документы необходимы? Что писать в мотивационном письме?',
          answer:
            'И гранты, и стипендии — виды безвозмездной финансовой помощи на оплату обучения. Получатель определяется на конкурсной основе. Разница между ними в том, что стипендию обычно выплачивают каждый месяц, а грант единоразово. Компенсируются они спонсорами-работодателями, НКО, фондами, вузами, правительствами, министерствами иностранных дел или образования, региональными властями.'
        }
      ])
    })
  }
}
