import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { NamedItemModel } from '~/model/NamedItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'direction' })
export class DirectionModule extends VuexModule {
  private directionList: Array<NamedItemModel> = []

  @Mutation
  private mutationSetDirectionList(directionList: NamedItemModel[]): void {
    this.directionList = directionList
  }

  @Action
  public setDirectionList(directionList: NamedItemModel[]): void {
    this.context.commit('mutationSetDirectionList', directionList)
  }

  get getDirectionList(): NamedItemModel[] {
    return this.directionList
  }
}
