import { Vue } from 'nuxt-property-decorator'
import { RequestService } from '~/services/RequestService'
import { GeolocationService } from '~/services/GeolocationService'
import { FilterFields } from '~/model'

export class FilterService {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private requestService: RequestService,
    private router: any,
    private cookies: any,
    private tstore: any,
    private geolocationService: GeolocationService
  ) {}

  getReadableUrl(): string {
    return `/api/v1/yavagro/get-filters-from-human-readable-url`
  }

  getFiltersUrl(): string {
    return `/api/v1/yavagro/${this.tstore.filterNews.category}/filters`
  }

  getSearchUrl(): string {
    return `/api/v1/yavagro/${this.tstore.filterNews.category}/filters`
  }

  public updateFilterFields(value: any, field: string, type: string, checked: boolean = true) {
    let query = ''
    const existingParams = this.parseQueryString(this.cookies.get('filterParams') || '')

    if (type === 'checkbox') {
      query = this.handleCheckbox(existingParams, value.id, checked)
    } else if (type === 'multiselect' || type === 'selectedvalues') {
      query = this.handleMultiselect(existingParams, field, value)
    } else if (type === 'range') {
      query = this.handleRange(existingParams, value)
    }

    const updatedQueryParams = this.parseQueryString(query)
    this.cookies.set('filterParams', this.queryParamsToString(updatedQueryParams))
    this.router.replace({
      path: this.router.currentRoute.path,
      query: updatedQueryParams
    })
  }

  private handleCheckbox(existingParams: Record<string, any>, id: string, checked: boolean): string {
    if (checked) {
      existingParams[id] = 'true'
    } else {
      delete existingParams[id]
    }
    return this.queryParamsToString(existingParams)
  }

  private handleMultiselect(existingParams: Record<string, any>, field: string, value: any): string {
    if (value.length === 0) {
      delete existingParams[field]
      return this.queryParamsToString(existingParams)
    }

    const newValues = value.map((item: { id: string; name: string }) => item.name)

    existingParams[field] = newValues.join('|')
    return this.queryParamsToString(existingParams)
  }

  private handleRange(existingParams: Record<string, any>, value: any): string {
    if (value.min !== undefined) {
      if (value.min === 0) {
        delete existingParams['price-min']
      } else {
        existingParams['price-min'] = value.min
      }
    }

    if (value.max !== undefined) {
      if (value.max === 0) {
        delete existingParams['price-max']
      } else {
        existingParams['price-max'] = value.max
      }
    }

    existingParams = this.removeEmptyParams(existingParams)
    return this.queryParamsToString(existingParams)
  }

  private removeEmptyParams<T extends Record<string, string | number>>(params: T): T {
    return Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== null && value !== '') // eslint-disable-line @typescript-eslint/naming-convention
      .reduce((acc: T, [key, value]) => {
        return {
          ...acc,
          [key]: value
        }
      }, {} as T)
  }

  public queryParamsToString(queryParams: Record<string, any>): string {
    return Object.entries(queryParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
  }

  public parseQueryString(queryString: string): Record<string, any> {
    const queryParams: Record<string, any> = {}

    queryString.split('&').forEach(param => {
      const [key, value] = param.split('=')

      if (key && value && key !== 'undefined' && value !== 'undefined') {
        queryParams[decodeURIComponent(key)] = decodeURIComponent(value)
      }
    })

    return queryParams
  }

  public resetFilters() {
    this.router.replace({
      path: this.router.currentRoute.path,
      query: {}
    })
  }

  public async searchItems(value, field): Promise<void> {
    try {
      const filterFields = this.tstore.filterNews.filterFields
      const filterField = filterFields.find(ff => ff.name === field)
      const searchParam = `${field}|search`
      const region = this.geolocationService.getCookieRegion()
      const url = this.getSearchUrl()

      if (value.length >= 3) {
        const res: any = await this.requestService.request<any>({
          method: 'POST',
          url,
          params: {
            [searchParam]: value,
            region: region ? region.slug : 'russia'
          }
        })

        const result = res.data?.data
        if (Array.isArray(result?.items)) {
          Vue.set(filterField, 'items', result.items)
        }
      } else {
        const res: any = await this.requestService.request<any>({
          method: 'POST',
          url,
          params: {
            [searchParam]: '',
            region: region ? region.slug : 'russia'
          }
        })

        const result = res.data?.data
        if (Array.isArray(result?.items)) {
          Vue.set(filterField, 'items', result.items)
        }
      }
    } catch (e) {
      console.warn(e) // eslint-disable-line no-console
    }
  }

  public stringifyQueryString(params: Record<string, any>): string {
    const keys = Object.keys(params)
    const queryParts: string[] = []

    keys.forEach(key => {
      const value = params[key]
      if (Array.isArray(value)) {
        value.forEach(v => queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(v)}`))
      } else {
        queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    })

    return queryParts.join('&')
  }

  public async getFilterFields(params?: Record<string, string>): Promise<Array<FilterFields>> {
    const res: any = await this.requestService.request({
      url: this.getFiltersUrl(),
      params
    })
    return res?.data?.data ?? []
  }

  public async getFiltersFromHumanReadableUrl(params?: Record<string, string>): Promise<any> {
    const res: any = await this.requestService.request({
      url: this.getReadableUrl(),
      params
    })
    return res?.data?.result ?? ''
  }
}
