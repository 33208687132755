import { ApiService } from '~/shared/api'
import { restGetList } from '~/shared/utils/decorators'
import type { ServiceRestGetList } from '~/shared/interfaces'
import { VacancyModel } from '~/model/Vacancy'

interface SimilarService extends ServiceRestGetList {}

@restGetList
// eslint-disable-next-line no-redeclare
class SimilarService extends ApiService {
  protected route: string = 'vacancies/similar'

  getListModel(id: number | string): Promise<Array<VacancyModel>> {
    return this.getList({ vacancy: id }).then(({ data }) => {
      if (Array.isArray(data)) {
        return data.map(i => new VacancyModel(i))
      }

      return []
    })
  }
}

export default SimilarService
