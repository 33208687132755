import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { EmployersTasksItemModel } from '~/model/Cards/EmployersTasksItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'employersTasks' })
export default class extends VuexModule {
  private employersTasksList: Array<EmployersTasksItemModel> = []
  private employersTasksItem: EmployersTasksItemModel = new EmployersTasksItemModel()

  @Mutation
  private mutationSetEmployersTasksList(employersTasksList: EmployersTasksItemModel[]): void {
    this.employersTasksList = employersTasksList
  }

  @Mutation
  private mutationSetEmployersTasksItem(employersTasksItem: EmployersTasksItemModel): void {
    this.employersTasksItem = employersTasksItem
  }

  @Action
  public setEmployersTasksList(list: EmployersTasksItemModel[]): void {
    this.context.commit('mutationSetEmployersTasksList', list)
  }

  @Action
  public setEmployersTasksItem(item: EmployersTasksItemModel): void {
    this.context.commit('mutationSetEmployersTasksItem', item)
  }

  get EmployersTasksList(): EmployersTasksItemModel[] {
    return this.employersTasksList
  }

  get EmployersTasksItem(): EmployersTasksItemModel {
    return this.employersTasksItem
  }
}
