import { AxiosResponse } from 'axios'
import { RequestService } from '~/services/RequestService'

export class MarathonService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    marathons: '/api/v1/yavagro/translations',
    translations: '/api/v1/yavagro/translations/query',
    programs: '/api/v1/yavagro/scholarshipapplications/programs',
    courses: '/api/v1/yavagro/scholarshipapplications/courses',
    specialities: '/api/v1/yavagro/scholarshipapplications/specialities',
    universities: 'api/v1/yavagro/universities/scholarships',
    regions: '/api/v1/yavagro/regions',
    register: '/api/v1/yavagro/users-management/users/sf/authorize'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async addCountViews<T = number>(slug): Promise<T> {
    const url = `${this.urls.marathons}/${slug}/count-views`
    const res: any = await this.requestService.request<T>({
      method: 'POST',
      url,
      auth: this.auth
    })
    return res.data.data.views
  }

  public async addUniquePerson<T = number>(slug): Promise<T> {
    const url = `${this.urls.marathons}/${slug}/unique-person`
    const { status } = await this.requestService.request<AxiosResponse<T>>({
      method: 'POST',
      url,
      auth: this.auth
    })
    return status as unknown as T
  }

  public async getScholarshipUniversities<T>(): Promise<T> {
    const url = `${this.urls.universities}`
    const res: any = await this.requestService.request<T>({
      url,
      auth: this.auth
    })
    return res.data.data.data
  }

  public async getSearchUniversities<T>(value: string): Promise<T> {
    const url = `${this.urls.universities}?q=${value}`
    const res: any = await this.requestService.request<T>({
      url,
      auth: this.auth
    })
    return res.data
  }

  public async getRegions<T>(): Promise<T> {
    const url = `${this.urls.regions}`
    const res: any = await this.requestService.request<T>({
      url
    })
    return res.data.data
  }

  public async getProgramsScholarship<T>(): Promise<T> {
    const url = `${this.urls.programs}`
    const res: any = await this.requestService.request<T>({
      url,
      auth: this.auth
    })
    return res.data.data
  }

  public async getCoursesScholarship<T>(): Promise<T> {
    const url = `${this.urls.courses}`
    const res: any = await this.requestService.request<T>({
      url,
      auth: this.auth
    })
    return res.data.data
  }

  public async getSpecialitiesScholarship<T>(): Promise<T> {
    const url = `${this.urls.specialities}`
    const res: any = await this.requestService.request<T>({
      url,
      auth: this.auth
    })
    return res.data.data
  }

  public async getTranslation(slug): Promise<any> {
    const url = `${this.urls.translations}`
    const res: any = await this.requestService.request({
      url,
      params: {
        'filter[slug]': slug,
        include: 'publishedRelatedTranslations,translation_schedules'
      }
    })
    return res.data.data
  }

  public async sendForm<T>(form: any): Promise<T> {
    const formData = new FormData()

    for (const key of Object.keys(form)) {
      if (Array.isArray(form[key])) {
        for (let i = 0; i < form[key].length; i++) {
          formData.append(`${key}[]`, JSON.stringify(form[key][i]))
        }
      } else {
        formData.append(key, form[key])
      }
    }

    const url = 'api/v1/yavagro/scholarshipapplications/store'
    const res: any = await this.requestService.request({
      data: formData,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url,
      auth: this.auth
    })
    return res.data
  }

  public async registationCareerMarathon(): Promise<number> {
    const formData = new FormData()
    formData.append('entry_point', 'work_marathon_2023')
    const { status } = await this.requestService.request({
      data: formData,
      method: 'POST',
      url: this.urls.register
    })
    return status
  }
}
