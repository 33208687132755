
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class AppLazyImage extends Vue {
  @Prop({
    default: ''
  })
  readonly alt!: string

  @Prop({
    default: ''
  })
  readonly src!: string

  mounted() {
    this.$lozad.observe()
  }
}
