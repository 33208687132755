/* eslint-disable camelcase */
export class SchoolItemModel {
  id: number = -1
  name: string = ''
  description: string = ''
  image: string = ''
  date: string = ''
  background: string = ''
  is_published: number = 1
  start_date: string = ''
  end_date: string = ''
  address: string = ''
  theme: string = ''
  orginizer: string = ''
  link: string = ''
  max_age: number = 0
  min_age: number = 0
  type: string = ''
  price: number = 0
  slug: string = ''
  industry: number[] = []
}
