import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ResultModel } from '~/model/Testing'

@Module({ namespaced: true, stateFactory: true, name: 'testing' })
export default class TestingModule extends VuexModule {
  private proforientationResults: ResultModel | null = null

  @Mutation
  private mutationSetProforientationResult(result: ResultModel): void {
    this.proforientationResults = result
  }

  @Action
  public setProforientationResult(result: ResultModel): void {
    this.context.commit('mutationSetProforientationResult', result)
  }

  get proforientationResult(): ResultModel | null {
    return this.proforientationResults
  }
}
