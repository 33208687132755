import { AxiosResponse } from 'axios'
import { RequestService } from '~/services/RequestService'

export class AmbassadorsService {
  readonly apiPrefix: string = '/api/v1/yavagro'

  constructor(private requestService: RequestService) {}

  readonly urls = {
    direction: '/api/v1/yavagro/ambassadors/universities-directions',
    send: '/api/v1/yavagro/ambassadors/questionnaires/send',
    create: '/api/v1/yavagro/news/query?filter[is_ambassadors_content]=1'
  }

  public async getUniversityDirections<T>(): Promise<T> {
    const { data } = await this.requestService.request<AxiosResponse<T>>({
      url: this.urls.direction
    })
    return data.data
  }

  public async getCreateAmbassadorsContent<T>(): Promise<T> {
    const { data } = await this.requestService.request<AxiosResponse<T>>({
      url: this.urls.create
    })
    return data.data
  }

  public async sendForm<T>(form: any): Promise<T> {
    const formData = new FormData()
    for (const key of Object.keys(form)) {
      formData.append(key, form[key])
    }

    const { status }: any = await this.requestService.request<AxiosResponse<T>>({
      data: formData,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: this.urls.send
    })
    return status
  }
}
