/* eslint-disable camelcase */

export interface IInfoBlocks {
  name: string
  icon: string
  value: string | number
}

export interface INumbersUniversity {
  name: string
  value: string | number
}
export interface IPivot {
  univer_id: number
  stream_id: number
}

export interface IStream {
  id: number
  name: string
  image: string
  government_code: string
  education_level?: string
  created_at: string
  updated_at: string
  background?: string
  is_published: number
  description?: any
  page_id: number
  pivot: IPivot
  show_limit?: number | null
}

export class UniversityItemModel {
  id: number = 0
  slug: string = ''
  is_published: number = 0
  name?: string = ''
  logo?: string = ''
  image?: string = ''
  description?: string = ''
  phone?: string = ''
  link?: string = ''
  budget_avg_score?: string = ''
  commerce_avg_score?: string = ''
  budget_places_number?: number = 0
  budget_distant_places_number?: number = 0
  foundation_year?: string = ''
  region?: string = ''
  city?: string = ''
  email?: string = ''
  status?: string = ''
  short_name?: string = ''
  address?: string = ''
  teachers_number?: number = 0
  students_number?: number = 0
  bakalavr_programs_number?: number = 0
  magistr_programs_number?: number = 0
  aspirant_programs_number?: number = 0
  slider_image?: any
  streams?: any[] = []
  created_at: string = ''
  updated_at: string = ''
  infoBlocks: IInfoBlocks[] = []
  numbers: INumbersUniversity[] = []
}

export interface UniversityInfoBlock {
  icon?: string
  name?: string
  value?: string
}
