import { RequestService } from '~/services/RequestService'

export class NewsService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    newsList: '/api/v1/yavagro/news'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async getNewsList<T>(ids?: string[]): Promise<T> {
    const url = ids ? this.urls.newsList + '?ids=' + ids.join(',') : this.urls.newsList
    const res: any = await this.requestService.request<T>({
      url,
      auth: this.auth
    })

    return res.data.data
  }

  public async addCountViews<T>(slug: string): Promise<T> {
    const url = `${this.urls.newsList}/${slug}/count-views`
    const res: any = await this.requestService.request<T>({
      method: 'POST',
      url,
      auth: this.auth
    })
    return res.data.data.views
  }

  public async addLike<T>(slug): Promise<T> {
    const url = `${this.urls.newsList}/${slug}/like`
    const res: any = await this.requestService.request<T>({
      method: 'POST',
      url,
      auth: this.auth
    })
    return res.data.data.likes
  }
}
