
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import NotFound from '~/components/Error/NotFound.vue'
import ArchiveVacancy from '~/components/Error/ArchiveVacancy.vue'

@Component({
  components: {
    NotFound,
    ArchiveVacancy
  }
})
export default class ErrorPage extends Vue {
  @Prop({
    default: () => {}
  })
  error!: any

  get currentErrorComponent() {
    return this.error && this.error.path && this.error.path.includes('/vacancies/') ? 'ArchiveVacancy' : 'NotFound'
  }
}
