
import { defineComponent, useContext, useRouter } from '@nuxtjs/composition-api'
import YavagroButton from '~/components/UI/ButtonYavagro.vue'

export default defineComponent({
  name: 'TopDigitalProfileBanner',
  components: {
    YavagroButton
  },
  setup() {
    const context = useContext()
    const $router = useRouter()

    const goToDigitalProfileCreate = () => {
      if (!context.$tstore.auth.isLogged) {
        context.$tstore.auth.setShowModalAuth({ show: true, backUrl: '/digital-profile/edit' })
        return
      }

      const routePath = context.$tstore.digitalProfile.IsDigitalProfile ? '/digital-profile' : '/digital-profile/create'
      $router.push(routePath)
    }

    return { goToDigitalProfileCreate }
  }
})
