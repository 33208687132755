import { toSnakeCase } from '~/shared/utils/helpers'
import type { ValidationErrors } from '~/shared/interfaces'
import { HttpMethod } from '~/types/network'

export default class Form {
  protected method: 'POST' | 'PATCH' = 'POST'
  protected keyModifier: (str: string) => string = toSnakeCase
  protected sendAs: 'form-data' | undefined = undefined
  protected ignoreKeys: Array<string> = ['__ob__', 'method', 'keyModifier', 'sendAs', 'ignoreKeys']

  getMethod(): HttpMethod {
    return this.method
  }

  setSendAs(as: 'form-data' | undefined): this {
    this.sendAs = as
    return this
  }

  isIgnoreKey(key: string): boolean {
    return this.ignoreKeys.includes(key)
  }

  protected beforeSerialize() {}

  serialize(): FormData | object {
    this.beforeSerialize()

    if (this.sendAs === 'form-data') {
      return this.toFormData()
    } else {
      return this.toObject()
    }
  }

  protected toFormData(): FormData {
    return Object.getOwnPropertyNames(this).reduce((formData, key) => {
      if (this.isIgnoreKey(key)) {
        return formData
      }

      const value = this[key]
      const keyForm = this.keyModifier ? this.keyModifier(key) : key

      if (Array.isArray(value)) {
        value.forEach(function (item) {
          formData.append(keyForm + '[]', item)
        })
      } else if (value === null) {
        formData.append(keyForm, '')
      } else if (typeof value === 'object' && !(value instanceof Blob)) {
        formData.append(keyForm, JSON.stringify(value))
      } else if (typeof value === 'boolean') {
        formData.append(keyForm, Number(value).toString())
      } else {
        formData.append(keyForm, value)
      }

      return formData
    }, new FormData())
  }

  protected toObject(): object {
    return Object.getOwnPropertyNames(this).reduce((result, key) => {
      if (this.isIgnoreKey(key)) {
        return result
      }

      const value = this[key]
      const keyModified = this.keyModifier ? this.keyModifier(key) : key

      result[keyModified] = value

      return result
    }, {})
  }

  protected validate(): true | ValidationErrors {
    return true
  }
}
