import { RequestService } from '~/services/RequestService'

export default abstract class ApiService {
  protected readonly requestService: RequestService
  protected readonly prefix: string = '/api/v1/yavagro'
  protected route: string = ''

  constructor(requestService: RequestService) {
    this.requestService = requestService
  }
}
