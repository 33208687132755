class ParseDateParams {
  isYear?: boolean = true
  monthType?: 'string' | 'number' = 'string'
  isDay?: boolean = true
}

export default class Utils {
  /**
   * Возвращает, равен ли проверяемый объект null, undefined или пустой строке
   * @param obj Проверяемый объект
   */

  public static isNullOrEmpty(obj: any): boolean {
    return obj === null || obj === undefined || obj === ''
  }

  public static parseMonthEnding(month: string): string {
    const letters = month.split('')
    const endL = letters[letters.length - 1]
    const res = month.split('')
    switch (endL) {
      case 'ь':
        res[res.length - 1] = 'я'
        break
      case 'т':
        res[res.length] = 'a'
        break
      case 'й':
        res[res.length - 1] = 'я'
        break
      default:
        break
    }
    return res.join('')
  }

  public static addZero(number: number): string {
    if (number < 10) {
      return '0' + number
    }
    return '' + number
  }

  public static parseTime(date: string): string {
    const d = new Date(date)
    const h = d.getHours()
    const m = d.getMinutes()
    return `${this.addZero(h)}:${this.addZero(m)}`
  }

  public static parseDate(date: string, params?: ParseDateParams) {
    const settedParams: ParseDateParams = {
      isDay: true,
      monthType: 'string',
      isYear: true
    }
    if (params) {
      Object.assign(settedParams, params)
    }
    const d = new Date(date.replace(' ', 'T'))
    const day = this.addZero(d.getDate())
    const month = d.getMonth() + 1
    const year = d.getFullYear()
    return `${settedParams.isDay ? day : ''}${
      settedParams.monthType === 'string'
        ? ' ' + this.parseMonthEnding(d.toLocaleDateString('ru-RU', { month: 'long' })) + ' '
        : '.' + this.addZero(month) + '.'
    }${settedParams.isYear ? year : ''}`
  }

  public static parseDateGap(start: string, end: string) {
    if (typeof start !== 'string' || typeof end !== 'string') {
      return ''
    }
    start = this.parseDate(start, { monthType: 'number' })
    end = this.parseDate(end, { monthType: 'number' })
    return start + ' - ' + end
  }

  /**
   * Возвращает первый параметр-значение, если Utils.isNullOrEmpty от него равен false, иначе возвращает значение по умолчанию
   * @param value Значение
   * @param defaultValue Значение по умолчанию
   */
  public static getValue<T>(value: T | undefined, defaultValue: T): T {
    return Utils.isNullOrEmpty(value) ? defaultValue : (value as T)
  }

  /**
   * Возвращает первый элемент values, для которого `Utils.isNullOrEmpty` равен false, либо первый параметр, `defaultValue`
   * @param defaultValue Значение по умолчанию
   * @param values Возможные значения для проверки
   */
  public static getAnyValue<T>(defaultValue: T, ...values: (T | undefined)[]): T {
    return values.find(val => !Utils.isNullOrEmpty(val)) || defaultValue
  }

  /**
   * Возвращает свойство объекта по его имени
   * @param obj Объект
   * @param key Искомое свойство
   */
  public static getKeyValue<T, K extends keyof T>(obj: T, key: K) {
    return obj[key] // Inferred type is T[K]
  }

  /**
   * Возвращает первый параметр-значение, если он не равен undefined или NaN и неотрицателен
   * @param value Значение
   * @param defaultValue Значение по умолчанию
   */
  public static getNonnegativeValue(value: number | undefined, defaultValue: number): number {
    if (value === undefined || isNaN(value)) {
      return defaultValue
    }

    return value >= 0 ? value : defaultValue
  }

  /**
   * Check if value type is Object;
   * @param value
   * @returns {boolean}
   */
  public static isObject(value: any): boolean {
    return value && typeof value === 'object' && value.constructor === Object
  }

  /**
   * Returns shaded colors in HEX format;
   * @param col: string
   * @returns string
   */
  public static shadeColor(col: string, amt: number) {
    col = col.replace(/^#/, '')
    if (col.length === 3) {
      col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]
    }

    let [r, g, b] = col.match(/.{2}/g) as any[]
    ;[r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt]

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
  }

  /**
   * Возвращает цену с пробелами
   * @param salary Строка
   * @returns string
   */
  public static formatSalary(salary: string) {
    return salary.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }

  public static formatDateRange(start: string, end: string): string {
    const months = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря'
    ]
    const startDate = new Date(start.replace(' ', 'T'))
    const endDate = new Date(end.replace(' ', 'T'))

    const startDay = startDate.getDate()
    const endDay = endDate.getDate()
    const startMonth = months[startDate.getMonth()]
    const endMonth = months[endDate.getMonth()]
    const year = startDate.getFullYear()

    let monthYear
    if (startMonth === endMonth) {
      monthYear = `${startMonth} ${year}`
    } else {
      monthYear = `${endMonth} ${year}`
    }

    const formattedStartDate = `${startDay < 10 ? '0' : ''}${startDay}`
    const formattedEndDate = `${endDay < 10 ? '0' : ''}${endDay}`

    return `${formattedStartDate}-${formattedEndDate} ${monthYear}`
  }

  /**
   * Возвращает строку с верным склонением
   * @param number Число
   * @param titles Массив строк
   * @returns string
   */
  public static declensionCheck(number: number, titles: string[]): string {
    const cases = [2, 0, 1, 1, 1, 2]
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  }
}
