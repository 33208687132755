import { RequestService } from '~/services/RequestService'

export class StatisticBlockService {
  constructor(private requestService: RequestService) {}

  // readonly urls = {
  //   tagcloud: '/api/tagcloud'
  // };

  public getStatisticBlock<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.tagcloud}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve({
        stats: [
          {
            id: 'stat2031',
            name: '89',
            description: 'детских технопарков <br> «Кванториум» открыто'
          },
          {
            id: 'stat2032',
            name: '62',
            description: 'региона России открыли <br> технопарки «Кванториум»'
          },
          {
            id: 'stat2033',
            name: '80 тыс.',
            description: 'детей вовлечены в деятельность технопарков «Кванториум»'
          },
          {
            id: 'stat2034',
            name: '600+ тыс.',
            description: 'детей охвачены мероприятиями федеральной сети «Кванториум»'
          }
        ]
      })
    })
  }
}
