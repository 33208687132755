import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { EventCardModel } from '~/model/Cards/EventCardModel'

@Module({ namespaced: true, stateFactory: true, name: 'event' })
export class EventModule extends VuexModule {
  private eventList: Array<EventCardModel> = []
  private eventFilteredList: Array<EventCardModel> = []
  private eventItem: EventCardModel = new EventCardModel()

  @Mutation
  private mutationSetEventList(eventList: EventCardModel[]): void {
    this.eventList = eventList
  }

  @Mutation
  private mutationSetEventFilteredList(eventFilteredList: EventCardModel[]): void {
    this.eventFilteredList = eventFilteredList
  }

  @Mutation
  private mutationSetEventItem(eventItem: EventCardModel): void {
    this.eventItem = eventItem
  }

  @Action
  public setEventList(eventList: EventCardModel[]): void {
    this.context.commit('mutationSetEventList', eventList)
  }

  @Action
  public setEventFilteredList(eventFilteredList: EventCardModel[]): void {
    this.context.commit('mutationSetEventFilteredList', eventFilteredList)
  }

  @Action
  public setEventItem(eventItem: EventCardModel): void {
    this.context.commit('mutationSetEventItem', eventItem)
  }

  @Action
  public setCountEvent(countBook: number): void {
    this.context.commit('mutationSetCountEvent', countBook)
  }

  get EventList(): EventCardModel[] {
    return this.eventList
  }

  get EventFilteredList(): EventCardModel[] {
    return this.eventFilteredList
  }

  get EventItem(): EventCardModel {
    return this.eventItem
  }
}
