import { RequestService } from '~/services/RequestService'

export class TagCloudService {
  constructor(private requestService: RequestService) {}

  // readonly urls = {
  //   tagcloud: '/api/tagcloud'
  // };

  public getTagCloud<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.tagcloud}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve({
        name: 'Популярные теги',
        tags: [
          {
            id: 'tag8370',
            name: 'Популярные новости'
          },
          {
            id: 'tag8371',
            name: 'Новости профессий'
          },
          {
            id: 'tag8372',
            name: 'Рейтинги учебных заведений'
          },
          {
            id: 'tag8373',
            name: 'Рейтинг курсов'
          },
          {
            id: 'tag8374',
            name: 'Точки зрения'
          },
          {
            id: 'tag8375',
            name: 'Новости образования'
          }
        ]
      })
    })
  }
}
