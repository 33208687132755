import { RequestService } from '~/services/RequestService'

import { ItemRequestModel } from '~/model'

export class BookService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    bookList: '/api/v1/yavagro/books'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async getBookList<T>(request?: ItemRequestModel): Promise<T> {
    const url = request?.ids ? this.urls.bookList + '?ids=' + request.ids.join(',') : this.urls.bookList
    const res: any = await this.requestService.request<T>({
      url,
      auth: this.auth
    })

    return res.data.data
  }

  public async getBookItem<T>(slug: string): Promise<T> {
    const url = `${this.urls.bookList}/${slug}`
    const res: any = await this.requestService.request<T>({
      url,
      auth: this.auth
    })

    return res.data.data
  }
}
