import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class PersonalityCountsModel extends BaseModel {
  public total: number = 0
  public items: { [key: string]: number } = {}

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
