import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { CourseItemModel } from '~/model/Cards/CourseItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'course' })
export class CourseModule extends VuexModule {
  private courseList: Array<CourseItemModel> = []
  private courseItem: CourseItemModel = new CourseItemModel()

  @Mutation
  private mutationSetCourseList(courseList: CourseItemModel[]): void {
    this.courseList = courseList
  }

  @Mutation
  private mutationSetCourseItem(courseItem: CourseItemModel): void {
    this.courseItem = courseItem
  }

  @Action
  public setCourseList(courseList: CourseItemModel[]): void {
    this.context.commit('mutationSetCourseList', courseList)
  }

  @Action
  public setCourseItem(courseItem: CourseItemModel): void {
    this.context.commit('mutationSetCourseItem', courseItem)
  }

  @Action
  public setCountCourse(countCourse: number): void {
    this.context.commit('mutationSetCountCourse', countCourse)
  }

  get CourseList(): CourseItemModel[] {
    return this.courseList
  }

  get CourseItem(): CourseItemModel {
    return this.courseItem
  }
}
