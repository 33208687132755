import { DirectionCardModel } from './Cards/DirectionCardModel'
import { ProfessionModel } from './Cards/FutureProfItemModel'

/* eslint-disable camelcase */

export class BookCategoryModel {
  created_at: string = ''
  id: number = 0
  title: string = ''
  updated_at: string = ''
}

export class BookCardModel {
  id: string = ''
  author?: string = ''
  name?: string = ''
  image?: string = ''
  slug? = ''
}

export class BookModel {
  author: string = ''
  category: BookCategoryModel | null = null
  category_id: number = 0
  created_at: string = ''
  description: string = ''
  id: number = 0
  image: string = ''
  industry: DirectionCardModel[] = []
  is_published: number = 0
  link: string = ''
  name: string = ''
  price: string = ''
  profession: ProfessionModel[] = []
  slug: string = ''
  special_price: string = ''
  updated_at: string = ''
}
