import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=6c16eb6c&scoped=true&"
import script from "./Modal.vue?vue&type=script&lang=ts&"
export * from "./Modal.vue?vue&type=script&lang=ts&"
import style0 from "./Modal.vue?vue&type=style&index=0&id=6c16eb6c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c16eb6c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalHeaderLogo: require('/usr/src/app/components/Modal/ModalHeaderLogo.vue').default,ModalClose: require('/usr/src/app/components/Modal/ModalClose.vue').default})
