import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'

export default class LandingSetting extends BaseModel {
  'is_hide_footer': boolean = false
  'is_hide_header': boolean = false

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      if (data.is_hide_footer) {
        this.is_hide_footer = Boolean(Number(data.is_hide_footer))
      }

      if (data.is_hide_header) {
        this.is_hide_header = Boolean(Number(data.is_hide_header))
      }
    }
  }
}
