import { AxiosResponse } from 'axios'
import { RequestService } from '~/services/RequestService'
import { ApplicationTemplateFileModel } from '~/model/ApplicationTemplateFileModel'

export default class ApplicationPageCreationTemplateFileService {
  constructor(private requestService: RequestService) {}

  readonly url = 'api/v1/yavagro/promo-pages-requests/get-template'

  public async getTemplateFile<T>(): Promise<ApplicationTemplateFileModel> {
    const url = this.url
    const res: any = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }
}
