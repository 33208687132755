import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { EmployerItemModel } from '~/model/Cards/EmployerItemModel'
import { EmployerModel } from '~/model/Employer'

// TODO подключено новое свойство employers с типизированной моделью, необходимо перевести функционал на него
@Module({ namespaced: true, stateFactory: true, name: 'employer' })
export default class extends VuexModule {
  private employerList: Array<EmployerItemModel> = []
  private employerItem: EmployerItemModel = new EmployerItemModel()
  private employers: Array<EmployerModel> = []

  @Mutation
  private mutationSetEmployerList(employerList: EmployerItemModel[]): void {
    this.employerList = employerList
  }

  @Mutation
  private mutationSetEmployerItem(employerItem: EmployerItemModel): void {
    this.employerItem = employerItem
  }

  @Mutation
  private mutationSetEmployers(employers: Array<EmployerModel>): void {
    this.employers = employers
  }

  @Action
  public setEmployerList(employerList: EmployerItemModel[]): void {
    this.context.commit('mutationSetEmployerList', employerList)
  }

  @Action
  public setEmployerItem(employerItem: EmployerItemModel): void {
    this.context.commit('mutationSetEmployerItem', employerItem)
  }

  @Action
  public setEmployers(employers: Array<EmployerModel>): void {
    this.context.commit('mutationSetEmployers', employers)
  }

  get EmployerList(): EmployerItemModel[] {
    return this.employerList
  }

  get EmployerItem(): EmployerItemModel {
    return this.employerItem
  }

  get employersList(): Array<EmployerModel> {
    return this.employers
  }
}
