export function debounce<F extends (...args: any) => void>(fn: F, ms: number): Function {
  let timer: ReturnType<typeof setTimeout> | null = null

  return function (this: any, ...args) {
    if (timer !== null) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      fn.call(this, ...args)
      timer = null
    }, ms)
  }
}
