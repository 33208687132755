import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class LandingImageBlockModel extends BaseModel {
  name: 'image_block' = 'image_block'
  title: string = ''
  file: string = ''
  'is_background': boolean = false

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (this.file) {
        this.file = new ImageService().cropImage(this.file, {})
      }

      if (data.is_background) {
        this.is_background = Boolean(Number(data.is_background))
      }
    }
  }
}
