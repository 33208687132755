import { Form } from '~/shared/api'

export default class AddResultsForm extends Form {
  private answers: Array<{
    testing_question_id: number // eslint-disable-line camelcase
    testing_answer_id: number // eslint-disable-line camelcase
  }> = []

  addAnswer(questionId: number, answerId: number): void {
    this.answers.push({ testing_question_id: questionId, testing_answer_id: answerId })
  }
}
