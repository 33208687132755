import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { TagCloudModel } from '~/model/Misc/TagCloudModel'

@Module({ namespaced: true, stateFactory: true, name: 'tagcloud' })
export class TagCloudModule extends VuexModule {
  private internalTagCloud: TagCloudModel = new TagCloudModel()

  @Mutation
  private mutationSetTagCloud(tagcloud: TagCloudModel): void {
    this.internalTagCloud = tagcloud
  }

  @Action
  public setTagCloud(tagcloud: TagCloudModel): void {
    this.context.commit('mutationSetTagCloud', tagcloud)
  }

  get tagCloud(): TagCloudModel {
    return this.internalTagCloud
  }
}
