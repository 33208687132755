import { Form } from '~/shared/api'
import { emailRule, onlyCyrillicRule, requiredRule } from '~/shared/utils/validation'

export default class OlympiadApplicationForm extends Form {
  fio: string = ''
  email: string = ''

  clear() {
    this.fio = ''
    this.email = ''
  }

  static rules() {
    return {
      fio: { ...requiredRule, ...onlyCyrillicRule },
      email: { ...requiredRule, ...emailRule }
    }
  }
}
