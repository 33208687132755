
import { Component, Vue } from 'nuxt-property-decorator'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'
@Component({
  components: { AppLazyImage }
})
export default class ModalClose extends Vue {
  onClose() {
    this.$emit('on-close')
  }
}
