import { RequestService } from '~/services/RequestService'

export class UniversitiesSortingService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    universitiesSorting: '/api/v1/yavagro/universities/sort-variables',
    universitiesFilterFields: '/api/v1/yavagro/universities/filter-fields'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async getUniversitiesSortingList<T>(): Promise<T> {
    const res: any = await this.requestService.request<T>({
      url: `${this.urls.universitiesSorting}`,
      auth: this.auth
    })

    return res.data.data
  }

  public async getUniversitiesFilterFields<T>(query: object): Promise<T> {
    const q = Object.keys(query)
      .map(key => `${key}=${encodeURI(query[key])}`)
      .join('&')

    const res: any = await this.requestService.request<T>({
      url: `${this.urls.universitiesFilterFields}?${q}`,
      auth: this.auth
    })

    return res.data.data
  }
}
