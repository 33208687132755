import { SNOWPLOW_ENDPOINTS, SNOWPLOW_STANDS, SNOWPLOW_SCRIPT_URL, SNOWPLOW_TRACKER_URL } from '~/shared/const/snowplow'
import { ENVIRONMENT_STANDS } from '~/shared/const/environment-stands'
import { CMO_ENDPOINTS, CMO_SCRIPT_URL } from '~/shared/const/cmo'

export default ({ app, $config }) => {
  if ($config.environmentStand) {
    const spScriptUrl = `${ENVIRONMENT_STANDS[$config.environmentStand]}${SNOWPLOW_SCRIPT_URL}`
    const spTrackerUrl = `${ENVIRONMENT_STANDS[$config.environmentStand]}${SNOWPLOW_TRACKER_URL}`
    const cmoScriptUrl = `${ENVIRONMENT_STANDS[$config.environmentStand]}${CMO_SCRIPT_URL}`

    const spEnvScript = `
    ;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[]; p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments) };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1; n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","${spScriptUrl}","snowplow"));

    snowplow('newTracker', 'events', '${SNOWPLOW_ENDPOINTS[$config.environmentStand]}', {
      appId: '${SNOWPLOW_STANDS[$config.environmentStand]}',
      discoverRootDomain: true,
      cookieSameSite: 'Lax',
      contexts: {
        webPage: true
      }
    });
    `

    const cmoEnvScript = `
        const PersPlugin = {
            PersPlugin: function() {
                return {
                    beforeTrack: (payload) => {
                        const data = window.cmo.getDataFromPayloadBuilder(payload);
                        if ("event_data" in data && "eventAction" in data.event_data && data.event_data.eventAction === 'activityPageVisit') {
                            const client = new window.cmo.CMOPersonalisationClient('${
                              CMO_ENDPOINTS[$config.environmentStand]
                            }', 'POP-UP', {
                                "duid": data.duid
                            }, {
                                context: data
                            });
                            const contactPoint = new window.cmo.PopupContactPoint('svoevagro.ru', {
                                responseEventSchema: 'iglu:rp/popup-response/jsonschema/1-0-0',
                                responseEventContext: () => ({
                                    platform: 'sa',
                                    eventCategory: 'popupResponse',
                                })
                            });
                            client.registerContactPoint(contactPoint);
                            client.fetchAndApplyContent();
                        }
                        payload.build()
                    }
                }
            }
        };
        window.snowplow('addPlugin:events', PersPlugin, 'PersPlugin')
    `

    app.head.script.push(
      { innerHTML: spEnvScript, type: 'text/javascript', charset: 'utf-8' },
      { src: cmoScriptUrl, async: true },
      { src: spTrackerUrl, async: true },
      { innerHTML: cmoEnvScript, type: 'text/javascript', charset: 'utf-8' }
    )
  }
}
