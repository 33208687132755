import { RequestService } from '~/services/RequestService'

export class CourseSortingService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    courseFilterFields: '/api/v1/yavagro/courses/filter-fields'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async getCourseFilterFields<T>(query: object): Promise<T> {
    const q = Object.keys(query)
      .map(key => `${key}=${encodeURI(query[key])}`)
      .join('&')
    const res: any = await this.requestService.request<T>({
      url: `${this.urls.courseFilterFields}?${q}`,
      auth: this.auth
    })

    return res.data.data
  }
}
