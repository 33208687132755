import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, stateFactory: true, name: 'search' })
export class SearchModule extends VuexModule {
  private internalSearch: string = ''

  @Mutation
  private mutationSearch(search: string): void {
    this.internalSearch = search
  }

  @Action
  public setSearch(search: string): void {
    this.context.commit('mutationSearch', search)
  }

  get search(): string {
    return this.internalSearch
  }
}
