import { AxiosResponse } from 'axios'
import { RequestService } from '~/services/RequestService'
import { ListInDigitalProfile } from '~/model/DigitalProfile'
import { isUnknownObject } from '~/shared/utils/guards'

export class DigitalProfileService {
  // eslint-disable-next-line no-useless-constructor
  constructor(private requestService: RequestService) {}

  readonly urls = {
    profile: '/api/v1/yavagro/digitalprofiles/query',
    regions: '/api/v1/yavagro/regions/query',
    universities: '/api/v1/yavagro/digitalprofiles/universities/query',
    course: '/api/v1/yavagro/digitalprofiles/courses/query',
    educationlevels: '/api/v1/yavagro/digitalprofiles/educationlevels/query',
    financingtypes: '/api/v1/yavagro/digitalprofiles/financingtypes/query',
    specializations: '/api/v1/yavagro/digitalprofiles/specializations/query',
    studyingforms: '/api/v1/yavagro/digitalprofiles/studyingforms/query',
    citizenships: '/api/v1/yavagro/digitalprofiles/citizenships/query',
    send: '/api/v1/yavagro/digitalprofiles/send',
    nda: '/api/v1/yavagro/users-management/nda/latest',
    signNda: '/api/v1/yavagro/users-management/nda'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async getProfile<T>(): Promise<T> {
    const url = `${this.urls.profile}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        include: 'region,university,course,specialization,educationlevel,studyingform,financingtype,citizenship'
      }
    })
    return res.data.data
  }

  public async getNDAFile<T>(): Promise<T> {
    const url = this.urls.nda
    const res: any = await this.requestService.request<T>({ url })
    return res.data.result
  }

  public async sendSignNda<T>(form: any): Promise<T> {
    const formData = new FormData()
    for (const key of Object.keys(form)) {
      formData.append(key, form[key])
    }

    const { status }: any = await this.requestService.request<AxiosResponse<T>>({
      data: formData,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: this.urls.signNda
    })
    return status
  }

  public async getDictionaryCourses<T>(): Promise<T> {
    const url = `${this.urls.course}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        sort: 'name'
      }
    })
    return res.data.data
  }

  public async getDictionarySpecializations<T>(value: string = '', spo: number = 0): Promise<T> {
    const url = `${this.urls.specializations}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        'page[size]': 99999,
        'filter[name]': value,
        'filter[is_spo]': spo,
        sort: 'name'
      }
    })
    return res.data.data
  }

  public async getDictionaryFinancingtypes<T>(): Promise<T> {
    const url = `${this.urls.financingtypes}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        sort: 'name'
      }
    })
    return res.data.data
  }

  public async getDictionaryEducationlevels<T>(): Promise<T> {
    const url = `${this.urls.educationlevels}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        sort: 'name'
      }
    })
    return res.data.data
  }

  public async getDictionaryStudyingForms<T>(): Promise<T> {
    const url = `${this.urls.studyingforms}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        sort: 'name'
      }
    })
    return res.data.data
  }

  public async getDictionaryRegions<T>(value: string = ''): Promise<T> {
    const url = `${this.urls.regions}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        'page[size]': 99999,
        'filter[name]': value,
        sort: 'name'
      }
    })
    return res.data.data
  }

  public async getDictionaryCitizenship<T>(value: string = ''): Promise<ListInDigitalProfile[]> {
    const url = `${this.urls.citizenships}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        'page[size]': 99999,
        'filter[name]': value,
        sort: 'sort_index'
      }
    })
    return res.data.data
  }

  public async getDictionaryUniversities<T>(value: string = ''): Promise<T> {
    const url = `${this.urls.universities}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        'page[size]': 99999,
        'filter[name]': value,
        sort: 'name'
      }
    })
    return res.data.data
  }

  public async sendForm<T>(form: any): Promise<T> {
    const formData = new FormData()
    for (const key of Object.keys(form)) {
      if (Array.isArray(form[key])) {
        form[key].forEach((item, idx) => {
          if (isUnknownObject(item)) {
            Object.getOwnPropertyNames(item).forEach(k => {
              formData.append(`${key}[${idx}][${k}]`, String(item[k]))
            })
          } else {
            formData.append(`${key}[${idx}]`, item)
          }
        })
      } else if (typeof form[key] === 'boolean') {
        formData.append(key, String(Number(form[key])))
      } else {
        formData.append(key, form[key])
      }
    }

    const { status }: any = await this.requestService.request<AxiosResponse<T>>({
      data: formData,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: this.urls.send
    })
    return status
  }
}
