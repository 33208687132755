/* eslint-disable camelcase */

export class MarathonItemModel {
  id: string = ''
  slug: string = ''
  name: string = ''
  description: string = ''
  views_count: number = 0
  video_link: string = ''
  start_date: string = ''
  end_date: string = ''
}

export interface MarathonEvent {
  title: string
  description: string
  start: string
  end: string
}

export interface MarathonCalendarEvents {
  id: number
  name: string
  href: any
}

interface MultyTextArea {
  value: string
}

export interface IApplicationForm {
  image?: File
  surname?: string
  lastname?: string
  birthdate?: string
  region?: string
  university?: string
  isFullTimeBudget: boolean
  course?: string
  program?: string
  three?: string
  four?: string
  five?: string
  publications?: Array<MultyTextArea>
  conferences?: Array<MultyTextArea>
  awards?: Array<MultyTextArea>
  additionals?: string
  email?: string
  phone?: string
  isConfirm: boolean
}
