export interface IClientIp {
  ip: string
}

export interface IRegionLocation {
  name: string
  slug: string
}

export class RegionLocationItem {
  name = 'Россия'
  slug = ''
}
