/* eslint-disable camelcase */

export class Images {
  desktopImage?: string = ''
  mobileImage?: string = ''
}

export class Children {
  id?: string = ''
  title?: string = ''
  slug?: string = ''
  images?: Images = new Images()
  description?: string = ''
  content?: string = ''
  created_at?: string = ''
  updated_at?: string = ''
}

export class Subject {
  id?: string = ''
  title?: string = ''
  slug: string = ''
  images: Images = new Images()
  description?: string = ''
  content?: string = ''
  children?: Children[]
  created_at?: string = ''
  updated_at?: string = ''
}
