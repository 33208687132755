import { RequestService } from '~/services/RequestService'

// import { Subject } from '~/model/Schoolchildren';

export class SchoolchildrenService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    categories: '/api/v1/yavagro/categories/query',
    categoryItem: '/api/v1/yavagro/articles/query',
    article: '/api/v1/yavagro/articles/query'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async getCategoryItems() {
    const url = `${this.urls.categories}`
    const res: any = await this.requestService.request({
      url,
      params: {
        'filter[slug]': 'skolnikam',
        include: 'children'
      }
    })
    return res.data.data
  }

  public async getCategoryItem(slug) {
    const url = `${this.urls.categories}`
    const res: any = await this.requestService.request({
      url,
      params: {
        'filter[slug]': slug,
        include: 'articles'
      }
    })
    return res.data.data
  }

  public async getArticleBySlug(slug) {
    const url = `${this.urls.article}`
    const res: any = await this.requestService.request({
      url,
      params: {
        'filter[slug]': slug
      }
    })
    return res.data.data
  }
}
