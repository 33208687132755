import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { DirectionCardModel } from '~/model'

@Module({ namespaced: true, stateFactory: true, name: 'schoolDirections' })
export class SchoolDirectionsModule extends VuexModule {
  private internalSchoolDirections: Array<DirectionCardModel> = []

  @Mutation
  private mutationSetSchoolDirections(schoolDirections: DirectionCardModel[]): void {
    this.internalSchoolDirections = schoolDirections
  }

  @Action
  public setSchoolDirections(schoolDirections: DirectionCardModel[]): void {
    this.context.commit('mutationSetSchoolDirections', schoolDirections)
  }

  get schoolDirections(): DirectionCardModel[] {
    return this.internalSchoolDirections
  }
}
