import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { SortingModel } from '~/model/Misc/SortingModel'
import { FilterModel } from '~/model'

@Module({ namespaced: true, stateFactory: true, name: 'filter' })
export class FilterModule extends VuexModule {
  private sortingList: Array<SortingModel> = []
  private filterFields: Array<FilterModel> = []

  @Mutation
  private mutationSetSortingList(sortingList: SortingModel[]): void {
    this.sortingList = sortingList
  }

  @Mutation
  private mutationSetFilterFields(filterFields: FilterModel[]): void {
    this.filterFields = filterFields
  }

  @Action
  public setSortingList(sortingList: SortingModel[]): void {
    this.context.commit('mutationSetSortingList', sortingList)
  }

  @Action
  public setFilterFields(filterBlocks: FilterModel[]): void {
    this.context.commit('mutationSetFilterFields', filterBlocks)
  }

  get getSortingList(): SortingModel[] {
    return this.sortingList
  }

  get getFilterFields(): FilterModel[] {
    return this.filterFields
  }
}
