import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { INotificationList, Notification } from '~/model/NotificationModel'
import { TOAST_DURATION, TOAST_MAX } from '~/shared/const'

@Module({
  namespaced: true,
  stateFactory: true,
  name: 'notification'
})
export class NotificationModule extends VuexModule {
  private notifications: INotificationList[] = []

  @Mutation
  private mutationSetNotification(notification: INotificationList): void {
    this.notifications.unshift(notification)
  }

  @Mutation
  private removeNotificationItem(index): void {
    this.notifications.splice(index, 1)
  }

  @Mutation
  private removeNotificationElements(): void {
    this.notifications.forEach(item => {
      clearTimeout(item.timeout)
    })
    this.notifications = []
  }

  @Action
  public setNotification(notification: Notification): void {
    const id = Math.random().toString(16).slice(2)
    const timeout = setTimeout(() => this.removeNotification(id), TOAST_DURATION)

    this.context.commit('mutationSetNotification', {
      id,
      timeout,
      notification
    })
  }

  @Action
  public removeNotification(id) {
    const index = this.notifications.findIndex(notification => notification.id === id)
    clearTimeout(this.notifications[index].timeout)

    this.context.commit('removeNotificationItem', index)
  }

  @Action
  public removeNotifications() {
    this.context.commit('removeNotificationElements')
  }

  get Notifications(): INotificationList[] {
    return this.notifications.slice(0, TOAST_MAX)
  }
}
