import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Subject } from '~/model/Schoolchildren'
import { NewsItemModel } from '~/model'

@Module({
  namespaced: true,
  stateFactory: true,
  name: 'schoolchildren'
})
export class SchoolchildrenModule extends VuexModule {
  private subjectItem: Subject = new Subject()
  private subjectList: Subject[] = []
  private newsSubjectItem: Array<NewsItemModel> = []

  @Mutation
  private mutationSetCollegeItem(newsSubjectItem: NewsItemModel[]): void {
    this.newsSubjectItem = newsSubjectItem
  }

  @Mutation
  private mutationSetSubjectItem(subject: Subject): void {
    this.subjectItem = subject
  }

  @Mutation
  private mutationSetSubjectList(subjectList: Subject[]): void {
    this.subjectList = subjectList
  }

  @Action
  public setCollegeItem(newsItems: NewsItemModel[]): void {
    this.context.commit('mutationSetNewsSubjectList', newsItems)
  }

  @Action
  public setSubjectItem(subject: Subject): void {
    this.context.commit('mutationSetSubjectItem', subject)
  }

  @Action
  public setSubjectList(subjectList: Subject[]): void {
    this.context.commit('mutationSetSubjectList', subjectList)
  }

  get NewsSubjectItem(): NewsItemModel[] {
    return this.newsSubjectItem
  }

  get SubjectItem(): Subject {
    return this.subjectItem
  }

  get SubjectList(): Subject[] {
    return this.subjectList
  }
}
