import { AxiosResponse } from 'axios'
import { SocialGroupsService } from '.'
import { ApiService } from '~/shared/api'
import { RequestService } from '~/services/RequestService'
import type { ServiceCanSendForm, ServiceRestDelete } from '~/shared/interfaces'
import { restDelete, sendForm } from '~/shared/utils/decorators/api-services'

interface SocialService extends ServiceCanSendForm, ServiceRestDelete {}

@sendForm
@restDelete
// eslint-disable-next-line no-redeclare
class SocialService extends ApiService {
  protected route = 'users-management/socials'
  public groupsService: SocialGroupsService

  constructor(requestService: RequestService) {
    super(requestService)
    this.groupsService = new SocialGroupsService(requestService)
  }

  public async sendVkLink(link: string, url: string): Promise<AxiosResponse> {
    return await this.requestService.request<AxiosResponse<any>>({
      data: {
        token: link
      },
      method: 'POST',
      url
    })
  }
}

export default SocialService
