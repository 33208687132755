import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { StatisticBlockModel } from '~/model/Misc/StatisticBlockModel'

@Module({ namespaced: true, stateFactory: true, name: 'statisticBlock' })
export class StatisticBlockModule extends VuexModule {
  private internalStatisticBlock: StatisticBlockModel = new StatisticBlockModel()

  @Mutation
  private mutationSetStatisticBlock(statisticBlock: StatisticBlockModel): void {
    this.internalStatisticBlock = statisticBlock
  }

  @Action
  public setStatisticBlock(statisticBlock: StatisticBlockModel): void {
    this.context.commit('mutationSetStatisticBlock', statisticBlock)
  }

  get statisticBlock(): StatisticBlockModel {
    return this.internalStatisticBlock
  }
}
