import { TestingResultsService } from '.'
import { ApiService } from '~/shared/api'
import type { ServiceHasList, UnknownObject } from '~/shared/interfaces'
import { RequestService } from '~/services/RequestService'
import { getList } from '~/shared/utils/decorators/api-services'
import { TestModel } from '~/model/Testing'

interface TestingService extends ServiceHasList {}

@getList
// eslint-disable-next-line no-redeclare
class TestingService extends ApiService {
  protected route = 'testings'
  protected resultsService: TestingResultsService

  constructor(requestService: RequestService) {
    super(requestService)
    this.resultsService = new TestingResultsService(requestService)
  }

  get results() {
    return this.resultsService
  }

  getListModels(params?: UnknownObject): Promise<Array<TestModel>> {
    return this.getList(params)
      .then(responseListModel => {
        if (responseListModel.data.length > 0) {
          return responseListModel.data.map(i => new TestModel(i))
        }
        return []
      })
      .catch(() => [])
  }
}

export default TestingService
