import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class Quote extends BaseModel {
  public author: string = ''
  public image: string = ''
  public text: string = ''
  public createdAt: string = ''
  public updatedAt: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
