import { Middleware } from '@nuxt/types'

const vacanciesDuplicationUrl: Middleware = ({ route, redirect }) => {
  const trimmedPath = route.path.replace(/\/vacancies\/vacancies/, '/vacancies')
  if (trimmedPath !== route.path) {
    return redirect(trimmedPath)
  }
}

export default vacanciesDuplicationUrl
