import { EmployersTaskListService } from '.'
import { ApiService } from '~/shared/api'
import { restCreate, sendForm } from '~/shared/utils/decorators'
import { ServiceCanSendForm, ServiceRestCreate } from '~/shared/interfaces'
import { RequestService } from '~/services/RequestService'

// eslint-disable-next-line
interface EmployersTaskService extends ServiceCanSendForm, ServiceRestCreate {}

// @ts-ignore
@sendForm
// @ts-ignore
@restCreate
// eslint-disable-next-line no-redeclare
class EmployersTaskService extends ApiService {
  protected route: string = 'assignment/store'
  public employersTaskListService: EmployersTaskListService

  constructor(requestService: RequestService) {
    super(requestService)
    this.employersTaskListService = new EmployersTaskListService(requestService)
  }
}

export default EmployersTaskService
