
import { Component, Vue, Prop } from 'nuxt-property-decorator'

type IconSizes = 'xs' | 's' | 'm' | 'l' | 'full'

@Component({})
export default class SvoiIcon extends Vue {
  @Prop({
    required: true
  })
  readonly icon!: string

  @Prop({
    default: 'm',
    validator(value: IconSizes) {
      return ['xs', 's', 'm', 'l', 'full'].includes(value)
    }
  })
  readonly size!: string

  onClick() {
    this.$emit('click')
  }

  ICON_PATH = require(`./svoi-icon.svg`)
}
