import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class LandingImagesItemBlock extends BaseModel {
  _index: string = ''
  img: string = ''
  'img_text': string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (this.img) {
        this.img = new ImageService().cropImage(this.img, {})
      }
    }
  }
}
