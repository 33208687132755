import { RequestService } from '~/services/RequestService'

export class QuantumService {
  constructor(private requestService: RequestService) {}

  // readonly urls = {
  //   quantums: '/api/quantums'
  // };

  public getQuantumList<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.quantums}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve([
        {
          id: 'quantum1',
          name: 'Биоквантум',
          tooltipText: 'Здесь будет текст описания Биокванториума',
          backgroundColor: '#F7FDDF',
          image: '/images/quantorium/icons/biokvantum.svg',
          tabIndexCard: 0
        },
        {
          id: 'quantum2',
          name: 'Космоквантум',
          tooltipText: 'Здесь будет текст описания Космоквантума',
          backgroundColor: '#DFFEE6',
          image: '/images/quantorium/icons/kosmokvantum.svg',
          tabIndexCard: 1
        },
        {
          id: 'quantum3',
          name: 'VR / AR',
          tooltipText: 'Здесь будет текст описания VR / AR',
          backgroundColor: '#FDF2E7',
          image: '/images/quantorium/icons/vrar.svg',
          tabIndexCard: 2
        },
        {
          id: 'quantum4',
          name: 'Наноквантум',
          tooltipText: 'Здесь будет текст описания Наноквантума',
          backgroundColor: '#F1F6FB',
          image: '/images/quantorium/icons/nanokvantum.svg',
          tabIndexCard: 3
        },
        {
          id: 'quantum5',
          name: 'Геоквантум',
          tooltipText: 'Здесь будет текст описания Геоквантума',
          backgroundColor: '#FFF5DE',
          image: '/images/quantorium/icons/geokvantum.svg',
          tabIndexCard: 4
        },
        {
          id: 'quantum6',
          name: 'Лазерквантум',
          tooltipText: 'Здесь будет текст описания Лазерквантума',
          backgroundColor: '#F6FFF3',
          image: '/images/quantorium/icons/laserkvantum.svg',
          tabIndexCard: 5
        },
        {
          id: 'quantum7',
          name: 'Робоквантум',
          tooltipText:
            'Робоквантум специализируется на робототехнике. Здесь изучают электронику, конструирование и программирование роботов. Вместо изучения скучных формул школьники применяют законы физики на практике. А результатом становится работающее изобретение.',
          backgroundColor: '#F7F0FF',
          image: '/images/quantorium/icons/robokvantum.svg',
          tabIndexCard: 6
        },
        {
          id: 'quantum8',
          name: 'Автоквантум',
          tooltipText: 'Здесь будет текст описания Автоквантума',
          backgroundColor: '#D9FEF7',
          image: '/images/quantorium/icons/avtokvantum.svg',
          tabIndexCard: 7
        },
        {
          id: 'quantum9',
          name: 'IT-квантум',
          tooltipText: 'Здесь будет текст описания IT-квантума',
          backgroundColor: '#FFECEC',
          image: '/images/quantorium/icons/itkvantum.svg',
          tabIndexCard: 8
        },
        {
          id: 'quantum10',
          name: 'Нейроквантум',
          tooltipText: 'Здесь будет текст описания Нейроквантума',
          backgroundColor: '#F5F9F6',
          image: '/images/quantorium/icons/neyrokvantum.svg',
          tabIndexCard: 9
        },
        {
          id: 'quantum11',
          name: 'Энерджиквантум',
          tooltipText: 'Здесь будет текст описания Энерджиквантума',
          backgroundColor: '#F9F5F8',
          image: '/images/quantorium/icons/enerdzhikvantum.svg',
          tabIndexCard: 10
        },
        {
          id: 'quantum12',
          name: 'Аэроквантум',
          tooltipText: 'Здесь будет текст описания Аэроквантума',
          backgroundColor: '#E0E2FF',
          image: '/images/quantorium/icons/aerokvantum.svg',
          tabIndexCard: 10
        }
      ])
    })
  }
}
