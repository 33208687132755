import { RequestService } from '~/services/RequestService'

export class StudentsService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    poster: '/api/v1/yavagro/students/events-announcements/query',
    events: '/api/v1/yavagro/events/query',
    competitions: '/api/v1/yavagro/competitions/query',
    professions: '/api/v1/yavagro/professions/query',
    internships: '/api/v1/yavagro/vacancies',
    directionInternships: '/api/v1/yavagro/stazhirovka',
    employers: '/api/v1/yavagro/employers/query'
  }

  public async getEmployersForStudents<T>(): Promise<T> {
    const url = `${this.urls.employers}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        'filter[is_display_in_students]': 1,
        'filter[internships_count]': 'gt|0|int'
      }
    })
    return res.data.data
  }

  public async getPosterItems<T>(): Promise<T> {
    const url = `${this.urls.poster}`
    const res: any = await this.requestService.request<T>({
      url
    })
    return res.data.data
  }

  public async getForInternship<T>(): Promise<T> {
    const res: any = await this.requestService.request<T>({
      url: this.urls.internships,
      params: {
        page: 1,
        'tf:employment_value_kw': 'Стажировка',
        per_page: 4
      }
    })

    return res.data.data.data
  }

  public async getDirectionInternships<T>(): Promise<T> {
    const url = `${this.urls.directionInternships}`
    const res: any = await this.requestService.request<T>({
      url
    })
    return res.data.data
  }

  public async getProfessionsSortByDate<T>(): Promise<T> {
    const url = `${this.urls.professions}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        sort: '-updated_at',
        'page[size]': 6
      }
    })
    return res.data.data
  }

  public async getEventsByDate<T>(): Promise<T> {
    const date = new Date()
    const dateFormatter = Intl.DateTimeFormat('fr-CA')
    const url = `${this.urls.events}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        'filter[from]': `gte|${dateFormatter.format(date)}`,
        'filter[event_type]': 'den-karery'
      }
    })
    return res.data.data
  }

  public async getCompetitionsByDate<T>(): Promise<T> {
    const date = new Date()
    const dateFormatter = Intl.DateTimeFormat('fr-CA')
    const url = `${this.urls.competitions}`
    const res: any = await this.requestService.request<T>({
      url,
      params: {
        'filter[start_date]': `gte|${dateFormatter.format(date)}`
      }
    })
    return res.data.data
  }
}
