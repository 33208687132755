// Эндпоинты SnowPlow для каждого стенда проекта
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SNOWPLOW_ENDPOINTS = {
  dev: 'https://dev-rp.rshb.ru:443/api/clickstreams',
  test: 'https://dev-rp.rshb.ru:8443/api/clickstreams',
  preprod: 'https://dev-rp.rshb.ru:8443/api/clickstreams',
  prod: 'https://rp.rshb.ru/api/clickstreams'
}

// Стенды SnowPlow для каждого стенда проекта
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SNOWPLOW_STANDS = {
  dev: 'dev-stand',
  test: 'test-stand',
  preprod: 'preprod-stand',
  prod: 'prod-stand'
}

// Путь к основному скрипту SnowPlow
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SNOWPLOW_SCRIPT_URL = '/shared/sp.js'

// Путь к трекер скрипту SnowPlow
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SNOWPLOW_TRACKER_URL = '/shared/rptracker.js'
