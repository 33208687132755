import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { FaqItemModel } from '~/model/FaqItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'grantsFaq' })
export class GrantsFaqModule extends VuexModule {
  private grantsFaqList: Array<FaqItemModel> = []

  @Mutation
  private mutationSetGrantsFaqList(grantsFaqList: FaqItemModel[]): void {
    this.grantsFaqList = grantsFaqList
  }

  @Action
  public setGrantsFaqList(grantsFaqList: FaqItemModel[]): void {
    this.context.commit('mutationSetGrantsFaqList', grantsFaqList)
  }

  get getGrantsFaqList(): FaqItemModel[] {
    return this.grantsFaqList
  }
}
