// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/banners/digital-profile/top.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".top[data-v-7b460b8f]{height:62px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");display:flex;align-items:center;justify-content:center}@media only screen and (max-width:1439px){.top[data-v-7b460b8f]{padding:10px}}@media only screen and (max-width:720px){.top[data-v-7b460b8f]{height:48px;padding:10px}}.top>.title[data-v-7b460b8f]{font-weight:600;font-size:22px;line-height:100%;text-transform:uppercase;color:#101e2c;padding-right:52px}@media only screen and (max-width:1439px){.top>.title[data-v-7b460b8f]{font-size:20px;padding-right:20px}}@media only screen and (max-width:720px){.top>.title[data-v-7b460b8f]{font-size:14px;line-height:16px;padding-right:unset}}.top>.button[data-v-7b460b8f]{padding:12px 46px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
