import type { ValidationErrors } from '~/shared/interfaces'

export declare type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'OPTIONS' | 'PUT' | 'PATCH' | 'HEAD' | 'LINK' | 'UNLINK'

export class RequestError {
  url: string = ''
  method: HttpMethod = 'GET'
  headers?: any
  params?: any
  data?: any
  message: string = ''
  status: number = 0
  statusText: string = ''
  responseHeaders?: any
  serverCode?: number
  validationErrors: ValidationErrors = {}
}
