import { RequestService } from '~/services/RequestService'

export class SearchService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    newsList: '/api/v1/yavagro/news'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async getSearch(value: string): Promise<any> {
    const url = '/api/v1/yavagro/search?q=' + value
    const res: any = await this.requestService.request({
      url,
      auth: this.auth
    })

    return res.data
  }
}
