import { emailRule, phoneRule, requiredRule } from '~/shared/utils/validation'
import { UserModel } from '~/model/User'

export default class VacancySimpleFeedbackForm {
  private _user: UserModel
  fio: string = ''
  phone: string = ''
  email: string = ''
  _invalid: boolean = true

  constructor(user: UserModel) {
    this._user = user
    this.fillForm()
  }

  private fillForm() {
    this.fio = this._user.name
    this.phone = this._user.phone
    this.email = this._user.email
    this._invalid = true
  }

  static rules() {
    return {
      fio: requiredRule,
      phone: { ...requiredRule, ...phoneRule },
      email: emailRule
    }
  }
}
