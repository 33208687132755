import { ApiService } from '~/shared/api'
import { getList } from '~/shared/utils/decorators'
import type { ServiceHasList } from '~/shared/interfaces'

interface PostedService extends ServiceHasList {}

@getList
// eslint-disable-next-line no-redeclare
class PostedService extends ApiService {
  protected route: string = 'news/posted/vkontakte'
}

export default PostedService
