import { RequestService } from '~/services/RequestService'

export class CareerNavigatorService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    professionsList: '/api/v1/yavagro/career-navigator/professions',
    universitiesList: '/api/v1/yavagro/career-navigator/universities',
    points: '/api/v1/yavagro/career-navigator/track-points'
  }

  public async getProfessionsList<T>(): Promise<T> {
    const res: any = await this.requestService.request<T>({
      url: `${this.urls.professionsList}`
    })

    return res.data.data
  }

  public async getUniversitiesList<T>(): Promise<T> {
    const res: any = await this.requestService.request<T>({
      url: `${this.urls.universitiesList}`
    })

    return res.data.data
  }

  public async getResult(url: string): Promise<any> {
    const { data }: any = await this.requestService.request({
      url: `${this.urls.points}/${url}`
    })
    return data.data
  }
}
