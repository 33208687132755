
import { Component, Vue } from 'nuxt-property-decorator'
import BreadCrumbs from '~/components/UI/BreadCrumbs.vue'
import ButtonYavagro from '~/components/UI/ButtonYavagro.vue'

@Component({
  components: {
    BreadCrumbs,
    ButtonYavagro
  }
})
export default class ArchiveVacancy extends Vue {
  viewAllVacancies() {
    const region = this.$services.geolocation.getCookieRegion()

    const path = region && region.slug !== '' ? `/vacancies/${region.slug}` : '/vacancies/russia'
    this.$router.push(path)
  }

  head() {
    return {
      title: 'Страница не найдена - Я в агро',
      description: '',
      h1: '',
      keywords: ''
    }
  }
}
