import { emailRule, requiredRule } from '~/shared/utils/validation'
import { Form } from '~/shared/api'
import type { UserModel } from '~/model/User'
import { LandingAdditionalFieldModel } from '~/model/Landings'
import { UnknownObject } from '~/shared/interfaces'

export default class LandingForm extends Form {
  fields: Array<LandingAdditionalFieldModel> = []
  values: UnknownObject = {}
  _invalid: boolean = true

  constructor(fields: Array<LandingAdditionalFieldModel>, user: UserModel) {
    super()
    this.fields = fields
    this.fillForm(user)

    this.ignoreKeys.push('_invalid')
  }

  fillForm(user: UserModel) {
    this.fields.forEach(field => {
      switch (field.type) {
        case 'email':
          this.values[field._index] = user.email
          break
        case 'phone':
          this.values[field._index] = user.phone
          break
        default:
          this.values[field._index] = ''
          break
      }
    })
  }

  clear() {
    this.fields.forEach(field => {
      return (this.values[field._index] = '')
    })
    this._invalid = true
  }

  static rules(fields: Array<LandingAdditionalFieldModel>) {
    const rules = {}

    fields.forEach(field => {
      let fieldRules = {}
      if (field.type === 'email') {
        fieldRules = { ...fieldRules, ...emailRule }
      }
      if (field.is_required) {
        fieldRules = { ...fieldRules, ...requiredRule }
      }

      if (Object.keys(fieldRules).length > 0) {
        rules[field._index] = fieldRules
      }
    })

    return rules
  }
}
