import { ApiService } from '~/shared/api'
import { restCreate, sendForm } from '~/shared/utils/decorators'
import { ServiceCanSendForm, ServiceRestCreate } from '~/shared/interfaces'
import { ApplicationPageCreationTemplateFileService } from '~/services/ApplicationPageCreation/index'
import { RequestService } from '~/services/RequestService'

interface ApplicationPageCreationService extends ServiceCanSendForm, ServiceRestCreate {}

@sendForm
@restCreate
// eslint-disable-next-line no-redeclare
class ApplicationPageCreationService extends ApiService {
  protected route: string = 'promo-pages-requests/add'

  public templateFileService: ApplicationPageCreationTemplateFileService

  constructor(requestService: RequestService) {
    super(requestService)
    this.templateFileService = new ApplicationPageCreationTemplateFileService(requestService)
  }
}

export default ApplicationPageCreationService
