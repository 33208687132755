import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { SlideModel } from '~/model'

@Module({ namespaced: true, stateFactory: true, name: 'grantsSlides' })
export class GrantsSlidesModule extends VuexModule {
  private internalGrantsSliedes: Array<SlideModel> = []

  @Mutation
  private mutationSetGrantsSlides(grantsSlides: SlideModel[]): void {
    this.internalGrantsSliedes = grantsSlides
  }

  @Action
  public setGrantsSlides(grantsSlides: SlideModel[]): void {
    this.context.commit('mutationSetGrantsSlides', grantsSlides)
  }

  get grantsSlides(): SlideModel[] {
    return this.internalGrantsSliedes
  }
}
