/* eslint-disable camelcase */
interface Program {
  id: string
  is_published: number
  name?: string
  period?: string
  passing_score?: number
  created_at?: string
  updated_at?: string
  image?: string
  description?: string
  price_non_rf?: string
  price_rf?: string
  study_form?: string
  stream_id?: string
  univer_id?: string
  budget_places_number?: string
  commerce_places_number?: string
}

interface University {
  id: string
  name?: string
  logo?: string
}

export class UniversityDirectionItemModel {
  id: string = ''
  is_published: number = 0
  name?: string
  image?: string
  description?: string
  government_code?: string
  education_level?: string
  link?: string
  created_at?: string
  updated_at?: string
  university_name?: string
  programs?: Program[]
  university?: University[]
}
