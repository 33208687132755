export class EmployersTasksItemModel {
  public id: number = 0
  public slug: string = ''
  public 'assignments_count': number = 0
  public 'created_at': string = ''
  public 'is_published': boolean = false
  public name: string = ''
  public requirements: string = ''
  public 'reward_image_1': string = ''
  public 'reward_image_2': string = ''
  public 'reward_image_3': string = ''
  public 'reward_name': string = ''
  public 'vacancies_count': number = 0
  public 'vk_post_link': string = ''
  public scores: number = 0
  public 'is_active': boolean = false
  public 'end_responses_datetime': string = ''
  public employer: any = {}
}
