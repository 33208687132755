import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { CareerNavigatorSelectItemModel } from '~/model/CareerNavigator'

@Module({ namespaced: true, stateFactory: true, name: 'careerNavigator' })
export class CareerNavigatorModule extends VuexModule {
  private professionsList: Array<CareerNavigatorSelectItemModel> = []
  private universitiesList: Array<CareerNavigatorSelectItemModel> = []
  private collegesList: Array<CareerNavigatorSelectItemModel> = []

  @Mutation
  private mutationSetProfessionsList(list: CareerNavigatorSelectItemModel[]): void {
    this.professionsList = list
  }

  @Mutation
  private mutationSetUniversitiesList(list: CareerNavigatorSelectItemModel[]): void {
    this.universitiesList = list
  }

  @Mutation
  private mutationSetCollegesList(list: CareerNavigatorSelectItemModel[]): void {
    this.collegesList = list
  }

  @Action
  public setProfessionsList(list: CareerNavigatorSelectItemModel[]): void {
    this.context.commit('mutationSetProfessionsList', list)
  }

  @Action
  public setUniversitiesList(list: CareerNavigatorSelectItemModel[]): void {
    this.context.commit('mutationSetUniversitiesList', list)
  }

  @Action
  public setCollegesList(list: CareerNavigatorSelectItemModel[]): void {
    this.context.commit('mutationSetCollegesList', list)
  }

  get getProfessionsList(): CareerNavigatorSelectItemModel[] {
    return this.professionsList
  }

  get getUniversitiesList(): CareerNavigatorSelectItemModel[] {
    return this.universitiesList
  }

  get getCollegesList(): CareerNavigatorSelectItemModel[] {
    return this.collegesList
  }
}
