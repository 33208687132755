import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Menu } from '.'

@Module({ namespaced: true, stateFactory: true, name: 'account' })
export default class extends VuexModule {
  private menu: Menu | null = null

  @Mutation
  public mutationInitMenu(context): void {
    this.menu = new Menu(context)
  }

  @Action
  public initMenu(context): void {
    this.context.commit('mutationInitMenu', context)
  }

  get secondaryMenuItems() {
    return this.menu?.getSecondaryItems() ?? []
  }

  get primaryMenuItems() {
    return this.menu?.getPrimaryItems() ?? []
  }
}
