import {
  LandingImagesBlockModel,
  LandingVideoBlockModel,
  LandingAdvantagesBlockModel,
  LandingTextBlockModel,
  LandingImageBlockModel,
  LandingMainBannerBlockModel,
  LandingTextImageBlockModel,
  LandingFeedbackBlockModel,
  LandingSettingModel,
  LandingRectangularBannerBlockModel
} from '.'
import { BaseModel } from '~/shared/model'

export default class LandingModel extends BaseModel {
  content: Array<
    | LandingImagesBlockModel
    | LandingVideoBlockModel
    | LandingAdvantagesBlockModel
    | LandingTextBlockModel
    | LandingImageBlockModel
    | LandingMainBannerBlockModel
    | LandingTextImageBlockModel
    | LandingFeedbackBlockModel
    | LandingRectangularBannerBlockModel
  > = []

  settings: LandingSettingModel = new LandingSettingModel({ is_hide_footer: false, is_hide_header: false })

  constructor(data: any) {
    super()

    if (data) {
      this.content = data.content.map(block => {
        if (block.name === 'advantages_block') {
          return new LandingAdvantagesBlockModel(block)
        }
        if (block.name === 'images_block') {
          return new LandingImagesBlockModel(block)
        }
        if (block.name === 'video_block') {
          return new LandingVideoBlockModel(block)
        }
        if (block.name === 'text_block') {
          return new LandingTextBlockModel(block)
        }
        if (block.name === 'image_block') {
          return new LandingImageBlockModel(block)
        }
        if (block.name === 'main_banner_block') {
          return new LandingMainBannerBlockModel(block)
        }
        if (block.name === 'text_and_image_block') {
          return new LandingTextImageBlockModel(block)
        }
        if (block.name === 'feedback_block') {
          return new LandingFeedbackBlockModel(block)
        }
        if (block.name === 'rectangular_banner_block') {
          return new LandingRectangularBannerBlockModel(block)
        }

        return block
      })

      if (!Array.isArray(data.settings)) {
        this.settings = new LandingSettingModel(data.settings)
      }
    }
  }
}
