import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { ActionBlockModel } from '~/model/Misc/ActionBlockModel'

@Module({ namespaced: true, stateFactory: true, name: 'subscribeBlock' })
export class SubscribeBlockModule extends VuexModule {
  private internalSubscribeBlock: ActionBlockModel = new ActionBlockModel()

  @Mutation
  private mutationSetSubscribeBlock(subscribeBlock: ActionBlockModel): void {
    this.internalSubscribeBlock = subscribeBlock
  }

  @Action
  public setSubscribeBlock(subscribeBlock: ActionBlockModel): void {
    this.context.commit('mutationSetSubscribeBlock', subscribeBlock)
  }

  get subscribeBlock(): ActionBlockModel {
    return this.internalSubscribeBlock
  }
}
