import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { SocialNetworkModel } from '~/model/User'

@Module({ namespaced: true, stateFactory: true, name: 'social' })
export default class extends VuexModule {
  private socialNetwork: SocialNetworkModel | {} = {}

  @Mutation
  public mutationSocialNetwork(socialNetwork: SocialNetworkModel): void {
    this.socialNetwork = socialNetwork
  }

  @Action
  public setSocialNetwork(socialNetwork: SocialNetworkModel): void {
    this.context.commit('mutationSocialNetwork', socialNetwork)
  }

  get SocialNetwork() {
    return this.socialNetwork
  }
}
