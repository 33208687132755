import Vue from 'vue'
import { Plugin } from '@nuxt/types'
import { initializeStore } from '../store/storeAccessor'

const typedStorePlugin: Plugin = context => {
  const typedStore = initializeStore(context.store)

  Vue.prototype.$tstore = typedStore
  context.$tstore = typedStore
}

export default typedStorePlugin
