import { RequestService } from '~/services/RequestService'

export class GrantsSlidesService {
  constructor(private requestService: RequestService) {}

  // readonly urls = {
  //   grantsSlides: '/api/grants-slides'
  // };

  public getGrantsSlides<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.grantsSlides}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve([
        {
          id: 'gis2481',
          avatar: '/images/grants/avatar.jpg',
          image: '/images/change-world/slide-bg.jpg',
          text: '"Думаю, я — отличный пример того, как имея лишь колоссальное желание познавать и волю к победе можно добиться результатов.".',
          author: 'Саша Тырышкин',
          caption: 'Студент Новосибирского государственного медицинского университета'
        },
        {
          id: 'gis2482',
          avatar: '/images/change-world/avatar.jpg',
          image: '/images/change-world/slide-bg.jpg',
          text: '"Я не могу остаться в стороне, когда дело касается помощи. Меня вдохновляют люди, которые так же безвозмездно готовы помогать окружающим.".',
          author: 'Мальхина Ирина',
          caption: 'Ученица МБОУ СОШ №18, Калужская область'
        }
      ])
    })
  }
}
