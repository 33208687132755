import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { SchoolItemModel } from '~/model/Cards/SchoolItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'school' })
export class SchoolModule extends VuexModule {
  private internalSchoolList: Array<SchoolItemModel> = []
  private internalSchoolItem: SchoolItemModel = new SchoolItemModel()

  @Mutation
  private mutationSetSchoolList(news: SchoolItemModel[]): void {
    this.internalSchoolList = news
  }

  @Action
  public setSchoolList(schoolList: SchoolItemModel[]): void {
    this.context.commit('mutationSetSchoolList', schoolList)
  }

  @Mutation
  private mutationSetSchoolItem(schoolItem: SchoolItemModel): void {
    this.internalSchoolItem = schoolItem
  }

  @Action
  public setSchoolItem(schoolItem: SchoolItemModel): void {
    this.context.commit('mutationSetSchoolItem', schoolItem)
  }

  get schoolItem(): SchoolItemModel {
    return this.internalSchoolItem
  }

  get schoolList(): SchoolItemModel[] {
    return this.internalSchoolList
  }
}
