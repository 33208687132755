import { NewsLabelModel, NewsTypeModel } from '.'
import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class NewsModel extends BaseModel {
  id: string = ''
  slug: string = ''
  name: string = ''
  image: string = ''
  labels: Array<NewsLabelModel> = []
  views: number = 0
  likes: number = 0
  time: string = ''
  content: string = ''
  dislike: boolean = false
  like: boolean = false
  authorName: string = ''
  createdAt: string = ''
  updatedAt: string = ''
  autopostingStatus: string = ''
  autopostingTeaser: string = ''
  type: NewsTypeModel = new NewsTypeModel(null)

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
      if (Array.isArray(data.labels)) {
        this.labels = data.labels.map(i => new NewsLabelModel(i))
      }
      this.type = new NewsTypeModel(data.type)
    }
  }
}
