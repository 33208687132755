import { RequestService } from '~/services/RequestService'

export class FutureProfService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    professions: '/api/v1/yavagro/professions'
  }

  public getFutureProfList<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.professions}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve([
        {
          id: 'fp1',
          name: 'Архитектор Big Data',
          study: '53 программы обучения',
          backgroundColor: '#DBFEE2',
          iconPath: '/icons/futureIcons/futureIcon01.svg',
          href: '/professions/arkhitektor-big-data'
        },
        {
          id: 'fp2',
          name: 'Сити-фермер',
          vacancy: '',
          study: '60 программ обучения',
          backgroundColor: '#FDF1E5',
          iconPath: '/icons/futureIcons/futureIcon02.svg',
          href: '/professions/siti-fermer '
        },
        {
          id: 'fp3',
          name: 'Дизайнер виртуальных миров',
          vacancy: '',
          study: '24 программы обучения',
          backgroundColor: '#F4FCD7',
          iconPath: '/icons/futureIcons/futureIcon03.svg',
          href: '/professions/dizayner-virtualnykh-mirov'
        },
        {
          id: 'fp4',
          name: 'Биотехнолог',
          vacancy: '',
          study: '36 программ обучения',
          backgroundColor: '#EBF1FA',
          iconPath: '/icons/futureIcons/futureIcon04.svg',
          href: '/professions/biotekhnolog'
        },
        {
          id: 'fp5',
          name: 'Инженер по 3D печати продуктов питания ',
          vacancy: '',
          study: '22 программы обучения',
          backgroundColor: '#FFECEC',
          iconPath: '/icons/futureIcons/futureIcon05.svg',
          href: '/professions/inzhener-po-3d-pechati-produktov-pitaniya'
        },
        {
          id: 'fp6',
          name: 'Специалист по уменьшению экологического следа',
          vacancy: '',
          study: '51 программа обучения',
          backgroundColor: '#FFF5DB',
          iconPath: '/icons/futureIcons/futureIcon06.svg',
          href: '/professions/spetsialist-po-umensheniyu-ekologicheskogo-sleda'
        },
        {
          id: 'fp7',
          name: 'Оператор Дронов',
          vacancy: '',
          study: '18 программ обучения',
          backgroundColor: '#DCDFFF',
          iconPath: '/icons/futureIcons/futureIcon07.svg',
          href: '/professions/operator-dronov'
        }
      ])
    })
  }
}
