import { PostedService } from '.'
import { ApiService } from '~/shared/api'
import { getList } from '~/shared/utils/decorators'
import { RequestService } from '~/services/RequestService'
import type { ServiceHasList } from '~/shared/interfaces'

interface NewsService extends ServiceHasList {}

@getList
// eslint-disable-next-line no-redeclare
class NewsService extends ApiService {
  protected route: string = 'news'
  public postedService: PostedService

  constructor(requestService: RequestService) {
    super(requestService)
    this.postedService = new PostedService(requestService)
  }
}

export default NewsService
