import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class LandingLogoItem extends BaseModel {
  _index: string = ''
  item: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (this.item) {
        this.item = new ImageService().cropImage(this.item, {})
      }
    }
  }
}
