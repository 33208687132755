import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { FutureProfItemModel } from '~/model/Cards/FutureProfItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'futureProf' })
export class FutureProfModule extends VuexModule {
  private internalFutureProfList: Array<FutureProfItemModel> = []
  private internalFutureProfItem: FutureProfItemModel = new FutureProfItemModel()

  @Mutation
  private mutationSetFutureProfList(futureProf: FutureProfItemModel[]): void {
    this.internalFutureProfList = futureProf
  }

  @Action
  public setFutureProfList(futureProfList: FutureProfItemModel[]): void {
    this.context.commit('mutationSetFutureProfList', futureProfList)
  }

  get futureProfList(): FutureProfItemModel[] {
    return this.internalFutureProfList
  }

  @Mutation
  private mutationSetFutureProfItem(futureProfItem: FutureProfItemModel): void {
    this.internalFutureProfItem = futureProfItem
  }

  @Action
  public setFutureProfItem(futureProfItem: FutureProfItemModel): void {
    this.context.commit('mutationSetFutureProfItem', futureProfItem)
  }

  get futureProfItem(): FutureProfItemModel {
    return this.internalFutureProfItem
  }
}
