import { ApiService } from '~/shared/api'
import { restCreate, sendForm } from '~/shared/utils/decorators'
import { ServiceCanSendForm, ServiceRestCreate } from '~/shared/interfaces'

interface ApplicationForBankProductFormService extends ServiceCanSendForm, ServiceRestCreate {}

@sendForm
@restCreate
// eslint-disable-next-line no-redeclare
class ApplicationForBankProductFormService extends ApiService {
  protected route: string = 'financialproductorder/store'
}

export default ApplicationForBankProductFormService
