export class EventCardModel {
  id: string = ''
  name?: string = ''
  title?: string = ''
  description?: string = ''
  sponsor?: string = ''
  city?: string = ''
  address?: string = ''
  audience?: string = ''
  image?: string = ''
  type?: string = ''
  from?: string = ''
  to?: string = ''
  date?: number = 0
  format?: string = ''
  slug?: string = ''
  color?: string = ''
}
