import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ITopRegion, IVacanciesItem } from '~/model/VacanciesModel'

@Module({ namespaced: true, stateFactory: true, name: 'vacancies' })
export class VacanciesModule extends VuexModule {
  private vacanciesGroup: Array<IVacanciesItem> = []
  private vacanciesList: Array<IVacanciesItem> = []
  private recomendedVacanciesBlock: Array<IVacanciesItem> = []
  private professionVacanciesBlock: Array<IVacanciesItem> = []
  private topRegions: Array<ITopRegion> = []
  private topVacancies: Array<IVacanciesItem> = []
  private topInterships: Array<IVacanciesItem> = []

  @Mutation
  private mutationSetVacanciesGroup(vacanciesGroup: IVacanciesItem[]): void {
    this.vacanciesGroup = vacanciesGroup
  }

  @Action
  public setVacanciesGroup(vacanciesGroup: IVacanciesItem[]): void {
    this.context.commit('mutationSetVacanciesGroup', vacanciesGroup)
  }

  @Mutation
  private mutationSetVacanciesList(vacanciesList: IVacanciesItem[]): void {
    this.vacanciesList = vacanciesList
  }

  @Action
  public setVacanciesList(vacanciesList: IVacanciesItem[]): void {
    this.context.commit('mutationSetVacanciesList', vacanciesList)
  }

  @Mutation
  private mutationSetRecomendedVacanciesBlock(recomendedVacanciesBlock: IVacanciesItem[]): void {
    this.recomendedVacanciesBlock = recomendedVacanciesBlock
  }

  @Action
  public setRecomendedVacanciesBlock(recomendedVacanciesBlock: IVacanciesItem[]): void {
    this.context.commit('mutationSetRecomendedVacanciesBlock', recomendedVacanciesBlock)
  }

  @Mutation
  private mutationSetProfessionVacanciesBlock(professionVacanciesBlock: IVacanciesItem[]): void {
    this.professionVacanciesBlock = professionVacanciesBlock
  }

  @Action
  public setProfessionVacanciesBlock(professionVacanciesBlock: IVacanciesItem[]): void {
    this.context.commit('mutationSetProfessionVacanciesBlock', professionVacanciesBlock)
  }

  @Mutation
  private mutationSetTopRegions(topRegions: ITopRegion[]): void {
    this.topRegions = topRegions
  }

  @Action
  public setTopRegions(topRegions: ITopRegion[]): void {
    this.context.commit('mutationSetTopRegions', topRegions)
  }

  @Mutation
  private mutationSetTopVacancies(topVacancies: IVacanciesItem[]): void {
    this.topVacancies = topVacancies
  }

  @Action
  public setTopVacancies(topVacancies: IVacanciesItem[]): void {
    this.context.commit('mutationSetTopVacancies', topVacancies)
  }

  @Mutation
  private mutationSetTopInterships(topInterships: IVacanciesItem[]): void {
    this.topInterships = topInterships
  }

  @Action
  public setTopInterships(topInterships: IVacanciesItem[]): void {
    this.context.commit('mutationSetTopInterships', topInterships)
  }

  get VacanciesGroup(): IVacanciesItem[] {
    return this.vacanciesGroup
  }

  get VacanciesList(): IVacanciesItem[] {
    return this.vacanciesList
  }

  get TopRegions(): ITopRegion[] {
    return this.topRegions
  }

  get TopVacancies(): IVacanciesItem[] {
    return this.topVacancies
  }

  get TopInterships(): IVacanciesItem[] {
    return this.topInterships
  }

  get RecomendedVacancies(): IVacanciesItem[] {
    return this.recomendedVacanciesBlock
  }

  get ProfessionVacancies(): IVacanciesItem[] {
    return this.professionVacanciesBlock
  }
}
