
import { Component, Vue } from 'nuxt-property-decorator'
import SvoiButton from '@svoi-ui/components/button/SvoiButton.vue'
import Modal from '~/components/Modal/Modal.vue'
import type { ModalAuthParams } from '~/interfaces/User'

@Component({
  components: {
    Modal,
    SvoiButton
  }
})
export default class ModalAuth extends Vue {
  typeModal = 'modal'

  get currentTypeModal(): string {
    return this.typeModal
  }

  setTypeModal(): string {
    if (process.browser) {
      return window.innerWidth <= 768 ? (this.typeModal = 'curtain') : (this.typeModal = 'modal')
    }

    return 'modal'
  }

  get params(): ModalAuthParams {
    return this.$tstore.auth.getModalAuthParams
  }

  get isShow(): boolean {
    return this.params.show
  }

  set isShow(value) {
    this.$tstore.auth.setShowModalAuth({ show: false })
  }

  onClickLogin() {
    this.redirectTo('login')
  }

  onClickRegister() {
    this.redirectTo('register')
  }

  redirectTo(to: 'login' | 'register') {
    let url: string

    if (this.$tstore.auth.isLogged) {
      url = this.params.backUrl || this.$route.fullPath
      this.isShow = false
      this.$router.push(url)
    } else {
      window.location.href =
        to === 'login' ? this.getLoginUrl(this.params.backUrl) : this.getRegisterUrl(this.params.backUrl)
    }
  }

  getBackUrl(url?: string): string {
    return this.$config.hosts.externalHost + (url ?? this.$route.fullPath)
  }

  getLoginUrl(url?: string): string {
    return `${this.$config.hosts.authHostLoginUrl}&backurl=${this.getBackUrl(url)}`
  }

  getRegisterUrl(url?: string): string {
    return `${this.$config.hosts.regHostLoginUrl}&backurl=${this.getBackUrl(url)}`
  }

  mounted() {
    window.addEventListener('resize', this.setTypeModal)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.setTypeModal)
  }
}
