const errors = {
  request: 'Возникли ошибки при выполнении запроса.',
  get: 'Возникли ошибки при получении данных.',
  create: 'Возникли ошибки при сохранении данных.',
  update: 'Возникли ошибки при обновлении данных.',
  delete: 'Возникли ошибки при удалении данных.'
}

export const messages = {
  errors
}
