import { Context, Middleware } from '@nuxt/types'
import { IRegionLocation } from '~/model/RegionLocation'

const geoLocationMiddleware: Middleware = async ({ $services, $tstore }: Context) => {
  if ($tstore.regionLocation.RegionLocationList.length === 0) {
    const regions = await $services.geolocation.getRegions<IRegionLocation[]>()
    if (regions) {
      $tstore.regionLocation.setRegionLocationList(regions)
    }
  }
}

export default geoLocationMiddleware
