import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Vue } from 'nuxt-property-decorator'
import { UserModel } from '~/model/User'
import type { ModalAuthParams } from '~/interfaces/User'

@Module({ namespaced: true, stateFactory: true, name: 'auth' })
export default class extends VuexModule {
  private user: UserModel | null = null
  private modalAuthParams: ModalAuthParams = { show: false }

  @Mutation
  public mutationUser(user: UserModel): void {
    this.user = user
  }

  @Mutation
  public mutationModalAuthParams(params: ModalAuthParams): void {
    this.modalAuthParams = params
  }

  @Mutation
  public mutationUserSocialNetworkStatus(type: string): void {
    if (this.user) {
      const index = this.user.socialNetworks.findIndex(sn => sn.type === type)

      Vue.set(this.user.socialNetworks[index], 'isActivated', true)
    }
  }

  @Action
  public setUser(user: UserModel): void {
    this.context.commit('mutationUser', user)
  }

  @Action
  public setShowModalAuth(params: ModalAuthParams | boolean): void {
    if (typeof params === 'boolean') {
      params = { show: params }
    }
    this.context.commit('mutationModalAuthParams', params)
  }

  @Action
  public updateUserSocialNetworks(type: string): void {
    this.context.commit('mutationUserSocialNetworkStatus', type)
  }

  get isLogged(): boolean {
    return this.user !== null
  }

  get getModalAuthParams(): ModalAuthParams {
    return this.modalAuthParams
  }

  get authorizedUser(): UserModel | null {
    return this.user
  }
}
