import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { GrantCardModel } from '~/model'

@Module({ namespaced: true, stateFactory: true, name: 'grants' })
export class GrantsModule extends VuexModule {
  private internalGrants: Array<GrantCardModel> = []

  @Mutation
  private mutationSetGrants(grants: GrantCardModel[]): void {
    this.internalGrants = grants
  }

  @Action
  public setGrants(grants: GrantCardModel[]): void {
    this.context.commit('mutationSetGrants', grants)
  }

  get grants(): GrantCardModel[] {
    return this.internalGrants
  }
}
