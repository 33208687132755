/* eslint-disable @typescript-eslint/naming-convention */
export const FLOOR_LIST = [
  {
    id: 0,
    name: 'Мужской'
  },
  {
    id: 1,
    name: 'Женский'
  }
]
