/* eslint-disable camelcase */
export class WorkCardModel {
  id: string = ''
  name: string = ''
  number: number = 0
  description: string = ''
  image: string = ''
  href: string = ''
}

export interface WorkCardModelList {
  status_code: number
  message: string
  data: WorkCardModel[]
}
