
import { defineComponent, computed, useRoute, ref, useContext, onMounted, watch } from '@nuxtjs/composition-api'
import TopMenu from '~/components/Header/TopMenu.vue'
import BottomMenu from '~/components/Header/BottomMenu.vue'
import MobileSidebarMenu from '~/components/Header/MobileSidebarMenu.vue'
import TopDigitalProfileBanner from '~/components/Banners/TopDigitalProfileBanner.vue'
import GlobalSearch from '~/components/Misc/GlobalSearch.vue'
import { getMobileSidebarMenu } from '~/shared/const'
import { isUnknownObject } from '~/shared/utils/guards'
import type { MenuItem } from '~/interfaces/Header'

export default defineComponent({
  name: 'Header',
  components: {
    TopDigitalProfileBanner,
    MobileSidebarMenu,
    TopMenu,
    BottomMenu,
    GlobalSearch
  },
  emits: ['on-search', 'on-search-input'],
  setup() {
    const $route = useRoute()
    const context = useContext()

    const showMobileSidebar = ref(false)
    const sidebarMenu = ref<Array<MenuItem>>([])

    const getSidebarMenu = () => {
      const region = context.$services.geolocation.getCookieRegion()
      sidebarMenu.value = getMobileSidebarMenu(
        isUnknownObject(region) && typeof region.slug === 'string' ? region.slug : ''
      )
    }

    onMounted(() => getSidebarMenu())

    watch(() => context.$tstore.regionLocation.RegionLocationItem, getSidebarMenu, { immediate: true, deep: true })

    const isLogged = computed(() => {
      return context.$tstore.auth.isLogged
    })

    const isStudent = computed(() => {
      const authorizedUser = context.$tstore.auth.authorizedUser
      return authorizedUser?.roles.has('student') ?? false
    })

    const isShowTopBanner = computed(() => {
      const path = $route.value.path
      const account = /^\/account\/candidate\/.*/

      return !isLogged.value || (isLogged.value && isStudent.value)
        ? path === '/' || path === '/students' || path === '/students/ambassadors' || account.test(path)
        : false
    })

    return {
      sidebarMenu,
      showMobileSidebar,
      isShowTopBanner
    }
  }
})
