
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from '@nuxtjs/composition-api'
import { Positions, PositionsEnum, Themes, ThemesEnum } from '@svoi-ui/interfaces/tooltip'

export default defineComponent({
  name: 'SvoiTooltip',
  props: {
    text: {
      type: String,
      default: ''
    },
    theme: {
      type: String as PropType<Themes>,
      default: ThemesEnum.dark,
      validator: (value: string) => {
        return [ThemesEnum.dark.toString(), ThemesEnum.white.toString()].includes(value)
      }
    },
    position: {
      type: String as PropType<Positions>,
      default: PositionsEnum.bottomLeft,
      validator: (value: string) => {
        return [
          PositionsEnum.bottomLeft.toString(),
          PositionsEnum.bottomRight.toString(),
          PositionsEnum.topLeft.toString(),
          PositionsEnum.topRight.toString(),
          PositionsEnum.leftTop.toString(),
          PositionsEnum.leftBottom.toString(),
          PositionsEnum.rightTop.toString(),
          PositionsEnum.rightBottom.toString()
        ].includes(value)
      }
    },
    delayOpening: {
      type: Number as PropType<number>,
      default: 0
    }
  },
  setup(props) {
    const observer = ref<IntersectionObserver>()
    const contentRef = ref<HTMLDivElement>()

    const offset = ref<number>(0)

    // Автокорректировка расположения тултипа относительно слота активатора в случаях, когда тултип выходит за пределы страницы
    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
      const e = entries[0]

      if (e) {
        const isLeft = props.position.includes('left')

        offset.value = 0

        if (e.boundingClientRect.left < 0) {
          offset.value = e.boundingClientRect.left * (isLeft ? 1 : -1)

          return
        }

        const diffRight = e.boundingClientRect.right - e.intersectionRect.right

        if (diffRight > 0) {
          offset.value = diffRight * (isLeft ? 1 : -1)
        }
      }
    }

    const classes = computed(() => ({
      [`-theme-${props.theme}`]: true,
      [`-position-${props.position}`]: true
    }))

    const bindVariables = computed(() => ({
      '--tooltip-delay': `${props.delayOpening}ms`,
      '--tooltip-offset': `${offset.value}px`
    }))

    onMounted(() => {
      if (document && contentRef.value) {
        const options = {
          root: document.querySelector('body')
        }

        observer.value = new IntersectionObserver(intersectionCallback, options)

        observer.value.observe(contentRef.value)
      }
    })

    onUnmounted(() => {
      if (observer.value) {
        observer.value.disconnect()
      }
    })

    return { contentRef, classes, bindVariables }
  }
})
