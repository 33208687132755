import { AxiosResponse } from 'axios'
import {
  ApiResponseItem,
  ApiResponseList,
  ApiResponseTotalCount,
  ResponseData,
  ServicesPrefixes
} from '~/model/RequestModel'
import { RequestService } from '~/services/RequestService'

export class RequestRestService {
  private readonly apiPrefix = '/api/v1/yavagro'

  constructor(private readonly requestService: RequestService) {}

  public async getList<T>(service: ServicesPrefixes, params?: any): Promise<ResponseData<T>> {
    const res: AxiosResponse<ApiResponseList<T>> = await this.requestService.request<ApiResponseList<T>>({
      url: `${this.apiPrefix}/${service}`,
      params
    })

    const { current_page: currentPage, last_page: totalPages, total, data } = res.data.data

    return {
      currentPage,
      totalPages,
      totalItems: total,
      data
    }
  }

  public async getItem<T>(service: ServicesPrefixes, slug: string): Promise<T> {
    const res: AxiosResponse<ApiResponseItem<T>> = await this.requestService.request<ApiResponseItem<T>>({
      url: `${this.apiPrefix}/${service}/${slug}`
    })

    return res.data.data
  }

  public async getTotalItemsCount(service: ServicesPrefixes): Promise<number> {
    const res: AxiosResponse<ApiResponseTotalCount> = await this.requestService.request<ApiResponseTotalCount>({
      url: `${this.apiPrefix}/${service}/total-count`
    })

    return res.data.data.count
  }
}
