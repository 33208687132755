import { checkedRule, emailRule, minLengthRule, phoneRule, requiredRule } from '~/shared/utils/validation'
import { Form } from '~/shared/api'

export default class ApplicationForBankProductForm extends Form {
  productsData: any = null
  'product_name': string = ''
  'organization_name': string = ''
  inn: string = ''
  phone: string = ''
  email: string = ''
  'contact_name': string = ''
  comment: string = ''
  'is_privacy_policy_agreed': boolean = false
  'is_marketing_emails_agreed': boolean = false
  _invalid: boolean = true

  constructor() {
    super()

    this.ignoreKeys.push('productsData')
    this.ignoreKeys.push('_invalid')
  }

  protected beforeSerialize() {
    this.product_name = this.productsData.name
  }

  clear() {
    this.productsData = null
    this.organization_name = ''
    this.inn = ''
    this.phone = ''
    this.email = ''
    this.contact_name = ''
    this.comment = ''
    this.is_privacy_policy_agreed = false
    this.is_marketing_emails_agreed = false
    this._invalid = true
  }

  static rules() {
    return {
      productsData: requiredRule,
      organization_name: requiredRule,
      inn: { ...requiredRule, ...minLengthRule(12) },
      phone: { ...requiredRule, ...phoneRule },
      email: { ...emailRule, ...requiredRule },
      contact_name: requiredRule,
      is_privacy_policy_agreed: checkedRule
    }
  }
}
