import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class CourseModel extends BaseModel {
  public id: number = 0
  public name: string = ''
  public slug: string = ''
  public description: string = ''
  public link: string = ''
  public image: string = ''
  public background: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
