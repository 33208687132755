import Vue from 'vue'

import { UI_KIT_STORE_PREFIX } from '@rshb/rshb-vue-kit/src/const'
import auth from '@rshb/rshb-vue-kit/src/store/modules/auth'
import recruitment from '@rshb/rshb-vue-kit/src/store/modules/recruitment'
import dictionary from '@rshb/rshb-vue-kit/src/store/modules/dictionary'
import notification from '@rshb/rshb-vue-kit/src/store/modules/notification'

import uiKitComponents from '@rshb/rshb-vue-kit/src/components/ui-kit'
import * as plugins from '@rshb/rshb-vue-kit/src/components/plugins'

const { display } = plugins

const components = {
  ...uiKitComponents
}

for (const key in components) {
  const component = components[key]
  if (component && component.name) {
    Vue.component(component.name, component)
  }
}

Vue.use(components)
Vue.use(display)

export default ({ isClient, app }) => {
  const opts = {}
  if (isClient) {
    opts.preserveState = true
  }

  app.store.registerModule(
    UI_KIT_STORE_PREFIX,
    {
      namespaced: true,
      modules: {
        auth,
        recruitment,
        dictionary,
        notification
      }
    },
    opts
  )
}
