import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Page } from '~/model/PagesModel'
import { BreadcrumbModel } from '~/model/BreadCrumbs'

@Module({ namespaced: true, stateFactory: true, name: 'pageMetadata' })
export default class PageMetadataModule extends VuexModule {
  private seoMetadata: Page = new Page()
  private breadcrumbs: BreadcrumbModel[] = []

  @Mutation
  private mutationSetSeoMetadata(metadata: Page): void {
    this.seoMetadata = metadata
  }

  @Action
  public setSeoMetadata(metadata: Page): void {
    this.context.commit('mutationSetSeoMetadata', metadata)
  }

  @Mutation
  private mutationSetBreadcrumbs(breadcrumbs: BreadcrumbModel[]): void {
    this.breadcrumbs = breadcrumbs
  }

  @Action
  public setBreadcrumbs(breadcrumbs: BreadcrumbModel[]): void {
    this.context.commit('mutationSetBreadcrumbs', breadcrumbs)
  }

  get getBreadcrumbs(): BreadcrumbModel[] {
    return this.breadcrumbs
  }

  get getSeoMetadata(): Page {
    return this.seoMetadata
  }
}
