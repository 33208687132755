import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { LandingImagesItemBlockModel } from '~/model/Landings/index'
import { ImageService } from '~/services/Misc/ImageService'

export default class LandingImagesBlockModel extends BaseModel {
  name: 'images_block' = 'images_block'
  title: string = ''
  items: Array<LandingImagesItemBlockModel> = []
  'background_image': string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.items)) {
        this.items = data.items.map(i => new LandingImagesItemBlockModel(i))
      }

      if (this.background_image) {
        this.background_image = new ImageService().cropImage(this.background_image, {})
      }
    }
  }
}
