import { ApiService } from '~/shared/api'
import type { ServiceRestGetList } from '~/shared/interfaces'
import { restGetList } from '~/shared/utils/decorators/api-services'

interface MetadataService extends ServiceRestGetList {}

@restGetList
// eslint-disable-next-line no-redeclare
class MetadataService extends ApiService {
  protected route = 'pages/getbyurl'
}

export default MetadataService
