import { ApiService } from '~/shared/api'
import { restGetItem } from '~/shared/utils/decorators'
import type { ServiceRestGetItem } from '~/shared/interfaces'
import { VacancyModel } from '~/model/Vacancy'
import { isUnknownObject } from '~/shared/utils/guards'

interface VacancyService extends ServiceRestGetItem {}

@restGetItem
// eslint-disable-next-line no-redeclare
class VacancyService extends ApiService {
  protected route: string = 'vacancy'

  getItemModel(id: number | string): Promise<VacancyModel> {
    return this.getItem(id).then(({ data }) => {
      const raw = isUnknownObject(data) && isUnknownObject(data.data) ? data.data : null
      return new VacancyModel(raw)
    })
  }
}

export default VacancyService
