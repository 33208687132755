export function* loopGenerator<T>(from: Array<T>): Generator<T> {
  let current = 0
  const max = from.length - 1
  while (true) {
    yield from[current++]
    current = current > max ? 0 : current
  }
}

export function* backgroundColorGenerator(): Generator<string> {
  const colors = ['#dbfde2', '#ffecec', '#ebf1fa', '#f4fcd7', '#fdf1e5', '#dcdfff', '#fff5db', '#e9feff']
  yield* loopGenerator(colors)
}

export function* diagramColorGenerator(): Generator<string> {
  const colors = [
    '#6ebe71',
    '#ffe37e',
    '#688ed7',
    '#fc6b95',
    '#6bfcc8',
    '#6ebebe',
    '#ddff7e',
    '#8568d7',
    '#fc6bdc',
    '#fc6b6b'
  ]
  yield* loopGenerator(colors)
}
