import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class LandingAdditionalFieldModel extends BaseModel {
  _index: string = ''
  name: string = ''
  type: string = ''
  value: string = ''
  'is_required': boolean = false

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (data.is_required) {
        this.is_required = Boolean(Number(data.is_required))
      }
    }
  }
}
