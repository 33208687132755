import { RequestService } from '~/services/RequestService'

export class EventService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    events: '/api/v1/yavagro/getevents'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async getEventByPeriod(from, to) {
    const url = `${this.urls.events}`
    try {
      const res: any = await this.requestService.request<any>({
        method: 'POST',
        data: {
          from,
          to
        },
        url,
        auth: this.auth
      })
      return res.data?.data?.data ?? []
    } catch (e) {
      console.warn(e) // eslint-disable-line no-console
    }
  }
}
