import { RequestService } from '~/services/RequestService'

export class BookSortingService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    bookSorting: '/api/books/sorting'
  }

  public getBookSortingList<T>(): Promise<T> {
    // return await this.requestService.request({
    //   url: `${this.urls.professions}`,
    // });
    return new Promise<T>(<T>(resolve) => {
      <T>resolve([
        {
          id: '1',
          name: 'Сначало новые'
        },
        {
          id: '2',
          name: 'Сначало старые'
        },
        {
          id: '3',
          name: 'Сначало дороже'
        },
        {
          id: '4',
          name: 'Сначало дешевле'
        }
      ])
    })
  }
}
