import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { LandingAdditionalFieldModel } from '~/model/Landings/index'

export default class LandingFeedbackBlockModel extends BaseModel {
  name: 'feedback_block' = 'feedback_block'
  title: string = ''
  text: string = ''
  fields: Array<LandingAdditionalFieldModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.fields)) {
        this.fields = data.fields.map(i => new LandingAdditionalFieldModel(i))
      }
    }
  }
}
