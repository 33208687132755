import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { ProfessionalEducationDirectionItemModel } from '~/model/Cards/ProfessionalEducationDirectionItemModel'

@Module({ namespaced: true, stateFactory: true, name: 'professionalEducationDirection' })
export class ProfessionalEducationDirectionModule extends VuexModule {
  private professionalEducationDirectionList: Array<ProfessionalEducationDirectionItemModel> = []
  private professionalEducationDirectionItem: ProfessionalEducationDirectionItemModel =
    new ProfessionalEducationDirectionItemModel()

  @Mutation
  private mutationSetProfessionalEducationDirectionList(
    directionList: ProfessionalEducationDirectionItemModel[]
  ): void {
    this.professionalEducationDirectionList = directionList
  }

  @Mutation
  private mutationSetProfessionalEducationDirectionItem(
    professionalEducationDirectionItem: ProfessionalEducationDirectionItemModel
  ): void {
    this.professionalEducationDirectionItem = professionalEducationDirectionItem
  }

  @Action
  public setProfessionalEducationDirectionList(
    professionalEducationDirectionList: ProfessionalEducationDirectionItemModel[]
  ): void {
    this.context.commit('mutationSetProfessionalEducationDirectionList', professionalEducationDirectionList)
  }

  @Action
  public setProfessionalEducationDirectionItem(
    professionalEducationDirectionItem: ProfessionalEducationDirectionItemModel
  ): void {
    this.context.commit('mutationSetProfessionalEducationDirectionItem', professionalEducationDirectionItem)
  }

  get ProfessionalEducationDirectionList(): ProfessionalEducationDirectionItemModel[] {
    return this.professionalEducationDirectionList
  }

  get ProfessionalEducationDirectionItem(): ProfessionalEducationDirectionItemModel {
    return this.professionalEducationDirectionItem
  }
}
