/* eslint-disable camelcase */
export class ConferencesItemModel {
  id: number = -1
  name: string = ''
  slug: string = ''
  description: string = ''
  image: string = ''
  background: string = ''
  organizer: string = ''
  place: string = ''
  format: string = ''
  start_date: string = ''
  end_date: string = ''
  reg_date_start: null | string = null
  reg_date_end: null | string = null
  link: string = ''
  theme: any = []
  is_published: number = 0
  created_at: string = ''
  updated_at: string = ''
  deleted_at: null | string = null
  date: string = ''
  infoBlocks?: any[]
}
