import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class LandingAdvantagesItemBlock extends BaseModel {
  'bg_color': string = ''
  'item_number': string = ''
  'item_text': string = ''
  _index: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
