import { AxiosResponse } from 'axios'
import { RequestService } from '~/services/RequestService'

export class PartnerService {
  constructor(private requestService: RequestService) {}

  readonly urls = {
    partnerMain: '/api/v1/yavagro/partners?is_display_in_main=1'
  }

  readonly auth = {
    username: 'customer',
    password: 'customer'
  }

  public async getPartnerListMain<T>(): Promise<T> {
    const res: any = await this.requestService.request<AxiosResponse<T>>({
      url: this.urls.partnerMain
    })
    return res.data.data.data
  }
}
