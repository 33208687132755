import { Context, Middleware } from '@nuxt/types'
import { IRegionLocation } from '~/model/RegionLocation'

const geolocationUser: Middleware = async ({ route, $services, $tstore }: Context) => {
  const isShowGeolocation =
    route.name === 'looking-for-job-slug' ||
    route.name === 'vacancies-group' ||
    route.name === 'vacancies-group-region' ||
    route.name === 'vacancies-group-region-param1'

  if ($tstore.regionLocation.RegionLocationList.length === 0) {
    const regions = await $services.geolocation.getRegions<IRegionLocation[]>()
    if (regions) {
      $tstore.regionLocation.setRegionLocationList(regions)
    }
  }
  if (isShowGeolocation && !$services.geolocation.getCookieRegion()) {
    const region = await $services.geolocation.getUserRegion<IRegionLocation>()
    if (region) {
      $tstore.regionLocation.setRegionLocationItem(region)
    }
  } else {
    $tstore.regionLocation.setRegionLocationItem($services.geolocation.getCookieRegion())
  }
}

export default geolocationUser
